@use '../../../common/stylesheets/constants';

.subscriptions-purchase-list-warnings-warning {
  background-color: #fff;
  border: solid 1px #2c2c2c1a;
  margin-left: calc((100% - 90%) / 2);
  padding: 20px;
  text-align: center;
  width: 90%;
  z-index: 10;
  position: relative;

  @media(min-width: 768px) {
    margin-left: calc((100% - 75%) / 2);
    width: 75%;
  }

  @media(min-width: 1200px) {
    flex-flow: row;
  }

  &.absolute {
    position: absolute;
  }

  p {
    font-size: 18px;
    font-weight: 600;

    @media(min-width: 768px) {
      font-size: 24px;
    }
  }

  button {
    margin-top: 20px;
    
    @include constants.subscription-button;
  }
}
