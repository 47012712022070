.x-landingpage-x-row1-stamps {
  bottom: -100px;
  width: 100%;

  @media(min-width: 768px) {
    position: absolute;
  }

  .items {
    display: grid;
    place-content: space-evenly;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr;
    margin-top: 60px;

    @media(min-width: 768px) {
      margin-top: 0;
      grid-template-columns: repeat(4, calc((100% - 3 * 15px) / 4));
    }

    @media(min-width: 992px) {
      grid-column-gap: 30px;
      grid-template-columns: repeat(4, calc((100% - 3 * 30px) / 4));
    }

    .item {
      background-color: #fff;
      height: 200px;
      border: 2px solid #EFE4FD;
      display: flex;
      flex-flow: column;
      justify-content: center;
      row-gap: 20px;
      color: #4E00A1;

      .title {
        display: flex;
        flex-flow: row;
        column-gap: 5px;
        justify-content: center;
        align-items: baseline;

        .number {
          font-weight: bold;
          font-size: 32px;

          @media(min-width: 1200px) {
            font-size: 42px;

          }
        }

        .unit {
          font-weight: 500;
          font-size: 26px;

          @media(min-width: 1200px) {
            font-size: 40px;
          }
        }
      }

      p {
        padding: 0 20px;
        font: normal normal normal 16px/22px Open Sans;
        text-align: center;
      }
    }
  }
}

