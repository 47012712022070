.account-lots-lists-show-rename-button {
  font-size: 14px;

  .lots-lists-button-rename {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    outline: none;
    padding: 5px 10px;
    transition: all linear 0.2s;
    white-space: nowrap;

    &:hover {
      border: 1px solid #ccc;
    }
  }
}
