.subscriptions-list-params {
  display: flex;
  align-items: center;
  flex-flow: column;

  .split {
    flex: 1;
    display: none;
    
    @media(min-width: 768px) {
      display: block;
    }
  }

  @media(min-width: 768px) {
    flex-flow: row;
  }
}
