@use '../../../../../assets/stylesheets/artp_colors';

.total-access-lot {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  margin-bottom: 30px;
  margin-left: 5px;
  margin-right: 5px;
  width: 265px;

  .artdb-link {
    margin-right: 3px;
    padding-right: 7px;
  }
  
  .lot-title {
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lot-img {
    align-items: center;
    display: flex;
    height: 270px;
    justify-content: center;
    margin-bottom: 15px;
    width: 250px;

    .no-pict {
      align-items: center;
      border: solid 1px artp_colors.$gray-light;
      border-radius: 5px;
      box-shadow: 2px 2px 5px artp_colors.$gray-eee;
      color: artp_colors.$gray-light;
      display: flex;
      height: 175px;
      justify-content: center;
      width: 147px;
    }

    img {
      max-height: 230px;
      max-width: 230px;
    }
  }

  .lot-artist {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lot-sale {
    text-align: center;
  }

  .lot-auctioneer {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
