@use '../../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../../common/stylesheets/constants';

.subscriptions-purchase-list-theme-subscription {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 14px;
  line-height: 19px;
  height: 40px;
  justify-content: center;
  left: calc(10% / 2);
  position: absolute;
  top: -20px;
  width: 90%;

  &.count-4 {
    left: calc(6% / 2);
    width: 94%;
  }

  &.lvl-#{stylesheets-constants.$theme-basics} {
    background-color: constants.$color-basics;
  }

  &.lvl-#{stylesheets-constants.$theme-premium} {
    background-color: constants.$color-premium;
  }

  &.lvl-#{stylesheets-constants.$theme-professional} {
    background-color: constants.$color-professional;
  }

  &.lvl-#{stylesheets-constants.$theme-basics-store} {
    background-color: constants.$color-basics-store;
  }

  &.lvl-#{stylesheets-constants.$theme-store} {
    background-color: constants.$color-store;
  }
}
