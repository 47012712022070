.contact {
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 30px;

  .faq-actions {
    margin-top: 60px;
    text-align: center;
    width: 100%;

    a {
      font-size: 16px;
      line-height: 28px;
      padding: 10px 15px;
    }
  }

  .back-to-top-container {
    bottom: 25px;
    float: right;
    margin-bottom: 10px;
    position: sticky;
    transform: translateX(10px);

    @media(min-width: 1300px) {
      transform: translateX(60px);
    }
  }

  h1.highlighted-title-content {
    background-image: linear-gradient(180deg, #337ab7, #337ab7);
  }

  .contact-coordonate {
    margin-top: 50px;
  }
}

