.subscriptions-list-faq {
  margin-top: 60px;

  h2 {
    color: #1d1d1f;
    font-family: Open Sans, "Sans-Serif", serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 39px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: normal;
    
    @media(min-width: 768px) {
      flex-flow: row;
    }

    div {
      &:first-of-type {
        flex: 1;
      }
    }

    .all {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .section {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 120px;
    min-height: 55px;
    border-bottom: 1px solid #CAC6BA;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 205px;
      height: 75px;
    }

    &:last-of-type {
      border: 0;
    }


    .title {
      padding: 10px 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      color: #1D1D1F;
    }

    .lnk {
      text-align: right;
      @media(min-width: 768px) {
        text-align: right;
      }

      a.btn-default {
        border: solid 1px #CAC6BA;
        color: #5E636E;

        @media(max-width: 767px) {
          padding: 10px;
        }

        &.btn-animated {
          &::after {
            background-color: #CAC6BA;
          }

          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }
}
