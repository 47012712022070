@use "sass:color";
@use '../../../assets/stylesheets/artp_colors';

.indexes-artist {
  .back-to-top {
    bottom: 15px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    position: sticky;
    text-align: right;
    transform: translateX(-15px);
  }

  svg:not(:root) {
    overflow: visible;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 20px;
    padding: 15px 15px 5px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  .chart-spinner {
    font-size: 75px;
    margin-top: 150px;
  }

  .chart {
    height: 348px;
    width: 100%;
  }

  .nav-tabs li {
    cursor: pointer;

    a {
      font-size: 1.75rem;
      transition: background-color 0.2s;
    }
  }

  .nav-pills {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    place-content: center;

    li {
      cursor: pointer;

      a {
        border: 1px solid #707070;
        border-radius: 6px;
        color: #666;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;

        &:hover {
          background-color: color.adjust(#fff, $lightness: -20%);
        }
      }

      &.active {
        a {
          background: #36c 0 0 no-repeat padding-box;
          color: #fff;
        }
      }
    }
  }

  .tab-content {
    border: solid 1px #ddd;
    margin-top: 5px;
    padding: 2.4rem;

    a .fa-wrench {
      background-color: rgba(#ddd, 0);
      border-radius: 50%;
      color: rgb(51, 102, 204);
      padding: 0.35rem;
      transform: rotateZ(-90deg);
      transition: transform 0.2s, background-color 0.2s;

      &:hover {
        background-color: rgba(#ddd, 0.5);
      }

      &:hover,
      &.active {
        transform: rotateZ(0deg);
      }
    }
  }

  @media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 14px !important;
    }
  }
}
