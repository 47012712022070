.artists-artist-filters-drawer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(min-width: 768px) {
    max-width: 630px;
  }

  .close {
    z-index: 1;
    right: 0;
  }


  h2 {
    font-size: 10px;
    line-height: 22px;
    font-weight: 600;
    padding: 0 30px;

    &.second {
      margin-top: 30px;
    }

    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 30px;

      &.second {
        margin-top: 35px;
      }
    }
  }

  .search-item {
    display: block;
    margin-top: 20px;
    padding: 0 30px;

    @media (min-width: 768px) {
      margin-top: 30px;
    }

    .search-item-label {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: left;
      width: 100%;

      @media (min-width: 992px) {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 20px;
      }
    }

    .search-item-control {
      .react-select__control,
      .form-control {
        height: 40px;

        @media (min-width: 992px) {
          height: 50px;
        }
      }

      &.target {
        display: grid;
        grid-template-columns: 1fr;

        @media (min-width: 500px) {
          grid-template-columns: 1fr 1fr;
        }


        .artp-checkbox-container {
          margin-bottom: 15px;

          @media (min-width: 500px) {
            margin-bottom: 0;
          }

          &.disabled {
            opacity: 0.35;

            .check-box {
              cursor: not-allowed;
              opacity: 0.25;
            }

            .artp-input-label {
              cursor: not-allowed;
              font-style: italic;
            }
          }

          .check-box {
            height: 30px;
            width: 30px;
          }

          .check-box-checked-square {
            &::before {
              background-color: var(--color-active-or-hover);
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }

    .search-dimension {
      grid-template-areas: 'cell1 cell2 cell3'
              'cell4 cell5 cell5';
      grid-template-columns: 1fr 1fr 1fr;

      .cell-5 {
        width: 100%;
      }

      // Hacking z-index to prevent it to be over the react date-picker
      .input-group > input {
        z-index: 1;
      }
    }
  }

  .bottom {
    display: flex;
    flex-flow: column;


    @media (min-width: 768px) {
      margin-top: 20px;
    }

    .results-statuts {
      display: flex;
      flex-flow: column;
      height: 65px;
      justify-content: center;

      div {
        background-color: #f1f1f1;
        font-size: 12px;
        font-weight: 300;
        padding: 15px 30px;
        text-align: center;
      }
    }

    .actions {
      display: flex;
      justify-content: space-evenly;
      margin-top: 15px;
      padding-bottom: 20px;

      .artp-btn-artist {
        width: 40%;
        color: #000;
      }
    }
  }
}
