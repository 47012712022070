.auctioneers-sale-header {
  $cover-size: 250px;
  height: 340px;
  margin-bottom: 30px;

  .spinner {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    margin-top: 110px;
    width: 100%;
  }

  .content {
    display: flex;
    flex-flow: row;
    font-size: 18px;

    .cover {
      width: calc(#{$cover-size} + 20px);

      img {
        max-height: $cover-size;
        max-width: $cover-size;
      }
    }

    .infos {
      display: flex;
      flex: 1;
      flex-flow: column;
      overflow: hidden;
      white-space: nowrap;
      width: 40%;

      div {
        font-size: 16px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.filler {
          flex: 1;
        }
      }

      .contact {
        flex: 1;
        text-align: right;
      }
    }
  }
}
