body main.x-landingpage {
  margin-top: 0;

  .artp-btn {
    padding: 30px 50px;
    font-size: 20px;
    white-space: normal;

    @media(min-width: 768px) {
      white-space: nowrap;
    }
  }
}

