@use '../../../../assets/stylesheets/constants';

.howto-panel {
  background-image: url("#{constants.$image-source}landing/faq-bg.jpg");
  margin: 50px 0;
  height: 190px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-shadow: 0 3px 6px #000000b4;
  padding: 5px;
  background-size: cover;
  background-position: center;

  @media(min-width: constants.$main-viewport-desktop-width) {
    padding: 15px;
  }

  @media(min-width: 1140px) {
    background-image: url("#{constants.$image-source}landing/faq-bg-1920.jpg");
  }

  h2 {
    margin: 0;
    text-align: center;
    font-size: 20px;
    line-height: 30px;

    @media(min-width: 768px) {
      font-size: 24px;
      line-height: 35px;
    }
  }

  .actions {
    display: flex;
    flex-flow: row;
    column-gap: 15px;

    a {
      margin-top: 24px;
      color: #fff;

      &.btn {
        border: solid 1px #fff;
        border-radius: 4px;
        padding: 10px 20px;
      }
    }
  }
}
