@use '../../../../../../assets/stylesheets/artp_colors';

.artists-artist-marketplace .artists-artist-marketplace-stores {
  //background-color: #e53140;
  background-color: var(--common-gray);
  //color: #fff;
  height: var(--store-height);
  margin-top: 30px;
  overflow: hidden;

  .container {
    display: flex;
    flex-flow: row;

    @media(min-width: 768px) {
      padding-left: 0 !important;
    }

    .stores-left {
      align-self: center;
      display: none;
      flex-basis: var(--block-width);
      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 115px 0 15px;

      @media (min-width: 992px) {
        display: block;
      }

      .overlap {
        transition: opacity linear 0.25s;

        &.overlapped {
          opacity: 0;
        }

        p {
          font-size: 15px;
          font-weight: 600;
        }

        a {
          //color: #fff;
          color: #000;
        }
      }
    }

    .stores-navigation {
      --btn-flex-basis: 40px;

      display: flex;
      flex-flow: row;
      width: 100%;

      .btn-previous {
        margin-right: 15px;
      }

      .btn-previous,
      .btn-next {
        align-self: center;
        flex-basis: var(--btn-flex-basis);
        flex-grow: 0;
        flex-shrink: 0;
        height: 40px;
      }

      .stores {
        align-items: center;
        column-gap: 30px;
        display: flex;
        flex: 1;
        flex-flow: row;
        height: 120px;
        margin: 0 15px 0 0;
        overflow: auto;
        scroll-behavior: smooth;
        width: 100%;

        @media (min-width: 768px) {
          overflow: hidden;
          width: calc(var(--container-width) - (2 * var(--btn-flex-basis)) - var(--block-width) - 60px);
        }

        a {
          color: #000;
          text-decoration: none;
          transition: all linear 0.15s;

          &:hover {
            .store {
              text-decoration: underline;
            }
          }

          .store {
            align-items: center;
            background-color: #fff;
            display: flex;
            height: 65px;
            justify-content: center;
            min-width: 140px;
            padding: 0 5px;
            text-align: center;
          }
        }
      }
    }
  }
}
