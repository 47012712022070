.homepage-reports {
  color: #fff;

  .thumbnails {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 15px;
    row-gap: 15px;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .bg {
    background: #1a171b;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &.gmac-2023 {
      opacity: 0.25;
    }
  }

  .main {
    position: relative;
    height: 325px;
    width: 100%;
    margin-bottom: 15px;
    background-position: center right;
    background-repeat: no-repeat;

    @media(min-width: 768px) {
      background-position: top right;
      height: 550px;
    }

    .content {
      padding: 20px;
      max-width: 585px;
      position: absolute;
      bottom: 0;

      .desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }

      .legend {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 5px;
      }

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 5px;
      }
    }
  }
}
