.classified-details {
  margin-top: 30px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  .artist {
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 15px;

    .button-follow {
      .btn {
        font-size: 12px;
      }
    }

    .artist-dates {
      font-size: 14px;
      line-height: 19px;
    }
  }

  h1.title {
    font-style: italic;
    font-size: 18px;
    line-height: 25px;
    margin: 0;
    margin-bottom: 15px;

    .dates {
      font-style: normal;
      font-size: 14px;
      margin-left: 5px;
    }
  }

  .idclassified {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }

  .classified-details-block {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 10px;

    &.not-strong {
      font-weight: normal;
    }
  }

  .classified-label {
    font-size: 14px;
    line-height: 22px;
  }

  .classified-comment {
    font-size: 14px !important;
    font-weight: normal !important;
    margin-bottom: 10px;
    max-height: 85px;
    overflow: hidden;
    transition: max-height 250ms ease-out;

    span {
      font-size: 14px !important;
    }

    &.classified-comment-visible {
      max-height: 1000px;
      transition: max-height 250ms ease-in;
    }
  }

  .read-more {
    cursor: pointer;
    font-weight: normal;
    text-decoration: underline;

    .fa {
      margin-left: 5px;
    }
  }
}
