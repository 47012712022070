@use 'constants';

.subscriptions-list-cards {
  margin-top: 30px;
  min-height: 750px;

  .cards-container {
    width: 100%;
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 30px;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    @media(min-width: 992px) {
      grid-template-columns: calc(34% - 20px / 3) calc(33% - 20px / 3) calc(33% - 20px / 3);

      &.essential {
        grid-template-columns: calc(25% - 30px / 4) calc(25% - 30px / 4) calc(25% - 30px / 4) calc(25% - 30px / 4);
      }
    }

    .active-subscription {
      position: absolute;
      top: -15px;
      display: flex;
      column-gap: 20px;
      width: 100%;
      justify-content: center;

      .users,
      .expiration {
        z-index: 1;
        border-radius: 15px;
        border: solid 1px;
        padding: 5px 10px;
        background-color: #fff;
        font-weight: 600;
      }

      &.premium {
        .users,
        .expiration {
          border-color: constants.$premium;
          color: constants.$premium;
        }
      }

      &.basic {
        .users,
        .expiration {
          border-color: constants.$basic;
          color: constants.$basic;
        }

        &.store {
          .users,
          .expiration {
            border-color: constants.$basicsstore;
            color: constants.$basicsstore;
          }
        }        
      }

      &.professional {
        .users,
        .expiration {
          border-color: constants.$professional;
          color: constants.$professional;
        }

        &.store {
          .users,
          .expiration {
            border-color: constants.$marketplace-professional;
            color: constants.$marketplace-professional;
          }
        }
      }
    }
  }
}
