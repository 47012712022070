.artp-Header-Menu-Folder {
  cursor: pointer;
  margin-right: 20px;
  height: 100%;
  position: relative;

  .separator {
    border-right: solid 1px #ccc;
    width: 1px;
    position: absolute;
    height: 25px;
    right: 0;
    top: 10px;
  }

  &.link {
    color: inherit;
    text-decoration: none;
  }

  &.active {
    background: #d9d3c3;
    color: #000;
  }

  .folder-title {
    align-items: center;
    column-gap: 3px;
    display: flex;
    padding: 10px 10px 10px 0;
    height: 100%;
    white-space: nowrap;
  }

  .folder-content {
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    color: #000;
    left: -5px;
    list-style: none;
    max-height: 0;
    min-width: 100px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 71px;
    transition: max-height 250ms ease-out;
    white-space: nowrap;

    @media (max-width: 1350px) {
      top: 40px;
    }

    @media (max-width: 992px) {
      top: 40px;
    }
  }

  &.selected {
    .folder-content {
      max-height: 500px;
      transition: max-height 250ms ease-in;
    }
  }

  &:last-of-type {
    .folder-content {
      @media (max-width: 992px) {
        left: unset;
        right: -5px;
      }
    }
  }
}
