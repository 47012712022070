.homepage-ails-focus-on {
  //height: 280px;
  justify-content: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  
  @media(min-width: 768px) {
    height: 176px;
  }

  @media(min-width: 992px) {
    height: 231px;
  }

  @media(min-width: 1200px) {
    height: 280px;
  }

  img {
    max-width: 100%;
  }
  
}
