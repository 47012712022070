.artists-artist-indexes-drawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: #DBCEED !important;

  @media(min-width: 768px) {
    max-width: 630px;
  }
  
  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }

  .args {
    font-size: 15px;
    line-height: 23px;
  }
  
  .links {
    margin-top: 30px;
    display: flex;
    flex-flow: row;
    column-gap: 15px;

    .link-default {
      background-color: #fff;

      &:hover {
        background-color: #0BA9DB !important;
      }
    }
  }
}

    
