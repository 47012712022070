.artp-Header-Messages {
  align-items: center;
  display: flex;

  .logo-messages {
    height: 12px;
    margin-right: 5px;
    margin-top: -2px;
  }
}
