@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../stylesheets/constants';

.subscriptions-purchase-common-users-count {
  background-color: #fff;
  border-radius: 50%;
  color: transparent;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px 5px 5px 26px;
  position: relative;
  text-align: right;
  width: 45px;

  .fa {
    font-size: 18px;
    left: 5px;
    position: absolute;
    top: 6px;
  }

  &.lvl-0 {
    border: solid 1px #646464;
    color: #646464;
  }

  &.lvl-#{stylesheets-constants.$theme-basics} {
    border: solid 1px constants.$color-basics;
    color: constants.$color-basics;
  }

  &.lvl-#{stylesheets-constants.$theme-premium} {
    border: solid 1px constants.$color-premium;
    color: constants.$color-premium;
  }

  &.lvl-#{stylesheets-constants.$theme-professional} {
    border: solid 1px constants.$color-professional;
    color: constants.$color-professional;
  }

  &.lvl-#{stylesheets-constants.$theme-basics-store} {
    border: solid 1px constants.$color-basics-store;
    color: constants.$color-basics-store;
  }

  &.lvl-#{stylesheets-constants.$theme-store} {
    border: solid 1px constants.$color-store;
    color: constants.$color-store;
  }
}
