.subscriptions-purchase {
  background-color: #fbfbfb;
  display: flex;
  flex-flow: column;

  &.empty {
    background-color: #fff;
  }

  @media(max-width: 767px) {
    .container {
      .container {
        max-width: 450px;
        width: 100%;
      }
    }
  }

  @media(min-width: 768px) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .artp-modal {
    margin-left: 10px;
    margin-right: 10px;

    @media(min-width: 768px) {
      max-width: 90%;
    }

    @media(min-width: 992px) {
      max-width: 900px;
    }

    @media(min-width: 1200px) {
      max-width: 1100px;
    }
  }
}
