@use '../../../../../assets/stylesheets/constants';

.ails-info-header {
  padding: 30px 0;
  text-align: center;
  background-image: url("#{constants.$image-source}ail/ail-background-1920.png");
  color: #fff;
  background-size: cover;
  background-position: center;

  @media(min-width: 1200px) {
    background-image: url("#{constants.$image-source}ail/ail-background-2560.png");
  }

  .container {
    display: flex;
    flex-flow: column;
    align-items: center;

    h1 {
      max-width: 850px;
      font-size: 30px;
      line-height: 40px;
      font-weight: bold;
      margin-bottom: 35px;

      @media(min-width: 768px) {
        line-height: 68px;
        font-size: 50px;
      }
    }

    p {
      max-width: 800px;
      text-align: center;
      font-size: 16px;
      line-height: 25px;

      @media(min-width: 768px) {
        line-height: 34px;
        font-size: 24px;
      }
    }
  }
}
