.artp-Header-Menu-Folder-Item {
  color: #000;
  display: block;
  padding: 10px 30px 10px 15px;
  text-decoration: none;

  &:focus {
    color: inherit;
    outline: none;
    text-decoration: auto;
  }
  
  &:hover {
    background-color: #dfded7;
    color: inherit;
    text-decoration: none;
  }
}
