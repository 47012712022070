@use '../../../../assets/stylesheets/artp_colors';

.myartprice {  
  .data-container {
    @media(min-width: 768px) {
      justify-content: center;
    }
  }
  
  .table-responsive {
    border: 0;
  }

  .table {
    border: solid 1px #ccc;

    thead {
      th {
        border-bottom: 0;
        color: artp_colors.$gray-darker;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;

        &.sort-item {
          cursor: pointer;
          height: 65px;
          transition: all linear 0.2s;

          &:hover {
            background-color: artp_colors.$gray-light;
          }

          .sortings {
            color: artp_colors.$gray;
            display: inline-block;
            font-size: 16px;
            width: 20px;

            &.selected {
              color: artp_colors.$gray-darker;
            }
          }

          .link {
            margin-top: 5px;
          }
        }
      }
    }

    tbody {
      tr {
        &.total-count {
          background-color: artp_colors.$gray-light;
          font-size: 11px;
          font-style: italic;
          margin: 0 -10px;
          padding: 5px 20px;

          td {
            a {
              color: #92bce0;
              font-style: italic;

              &.artist {
                vertical-align: middle;
              }
            }
          }
        }

        td {
          text-align: center;

          &.artist {
            text-align: left;
          }
        }
      }
    }
  }

  .artist-filter {
    border-bottom: 1px solid artp_colors.$gray-eee;
    height: 100%;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .header {
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 1px;
      padding-left: 5px;

      .fa {
        color: #999;
        margin-left: 5px;
        transition: all 0.25s ease;
      }
    }

    .filters {
      border: 0;

      .filter {
        padding: 5px;

        .artp-checkbox-container {
          padding-left: 2px;
          padding-right: 0;

          .artp-input-label {
            font-size: 14px;
            font-weight: normal;
            padding-left: 4px;
          }

          .artp-checkbox {
            height: 20px;
            width: 20px;

            .svg-checkbox {
              stroke-width: 1.9px;
            }
          }
        }
      }
    }
  }

  #filter {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    color: #000;
    display: inline-block;
    font-size: 16px;
    height: 34px;
    justify-self: end;
    margin: 10px 0 10px 20px;
    min-width: inherit;
    padding: 5px 10px;
    position: relative;
    text-align: left;
    vertical-align: center;
  }

  .search-artist {
    font-size: 0.8em;
    margin-top: 15px;
    min-width: 200px;
  }
}
