.search-dates {
  display: flex;
  
  .cell:not(.first) {
    margin-left: 10px;

    .react-datepicker {
      transform: translateX(-35px);
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    input {
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      color: #555;
      display: block;
      font-size: 12px;
      height: 30px;
      line-height: 1.5;
      padding: 5px 10px;
      transition: border-color ease-in-out 1s, box-shadow ease-in-out 1s;
      width: 100%;
    }
  }

  .react-datepicker {
    font-size: 12px;

    .react-datepicker__month {
      margin: 10px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 10px;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      margin: 3px 5px;
    }
  }
}
