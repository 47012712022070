@use '../../../../../assets/stylesheets/constants';

.account-signin-confirmation-free-demo {
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 335px;
  color: #fff;
  background-image: url("#{constants.$image-source}account/registration-favorites.png");
  overflow: hidden;
  margin-top: 60px;
  position: relative;
  justify-content: center;

  @media(min-width: 768px) {
    height: 260px;
  }

  .content {
    padding: 15px;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media(min-width: 768px) {
      max-width: 585px;
      position: absolute;
      bottom: 0;
      justify-content: left;
      padding: 0 0 30px 30px;
      display: block;
    }

    .l1 {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 34px;
      font-weight: bold;
    }

    .l2 {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
      text-align: center;

      @media(min-width: 768px) {
        text-align: left;
      }
    }
  }
}
