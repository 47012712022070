@use '../../../../assets/stylesheets/constants';

.faq-mobile {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  width: 100%;

  .groups {
    border: solid 1px #ccc;
    margin-bottom: 15px;

    .group {
      border-top: solid 1px #ccc;
      cursor: pointer;
      transition: background-color 0.25s linear;
      width: 100%;

      div:first-child {
        color: #404040;
        padding: 25px;
        position: relative;

        .fa {
          color: #999;
          font-size: 30px;
          position: absolute;
          right: 20px;
          top: 20px;
        }

        &.active {
          background-color: #337ab7;
          color: #fff;

          .fa {
            transform: rotateZ(90deg);
            transition: transform 0.2s, background-color 0.2s;
          }
        }
      }

      &:first-child {
        border-top: 0;
      }
    }
  }


  &.marketplace-how-to-bid-faq-mobile-full {
    margin-top: 15px;

    .groups {
      margin-top: 15px;
    }
  }
}
