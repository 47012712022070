.ails-landingpage-lots-lot {
  --image-size: 270px;

  @media(min-width: 992px) {
    --image-size: 215px;
  }

  @media(min-width: 1200px) {
    --image-size: 270px;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .lot {
    background-color: #fff;
    border: solid 1px #7C0F3D;
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-template-areas: 'repro'
      'legend';

    .encart {
      color: #fff;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      background-color: #7C0F3D;
      padding: 2px 5px;
      text-align: center;
      font-size: 11px;
      width: 130px;
    }

    @media(min-width: 540px) {
      grid-template-areas: 'legend repro';
      grid-template-columns: 1fr var(--image-size);
    }

    .legend {
      grid-area: legend;
      display: flex;
      flex-flow: column;
      padding: 15px;
      align-self: center;


      div {
        &.estimate {
          font-weight: 600;

          .title {
            color: #707070;
            padding-right: 10px;
            font-size: 12px;
            font-weight: 300;
          }
        }

        &.auctioneer {
          font-weight: 600;
          margin-bottom: 5px;
          margin-top: 15px;
        }

        &.dt {
          display: flex;
          margin-top: 15px;

          .block {
            position: absolute;
            bottom: 0;
            right: 0;
            color: #fff;
            padding: 10px 20px;
            display: flex;
            flex-flow: column;
            background-color: var(--color-ail);
            align-items: baseline;
            column-gap: 5px;

            @media (min-width: 540px) {
              position: relative;
            }

            div {
              &.part1 {
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                line-height: 30px;
              }

              &.part2 {
                font-size: 10px;
              }
            }
          }
        }

        &.location {
          margin-bottom: 20px;
        }

        &.artist {
          margin-bottom: 5px;
          font-weight: 600;
        }

        &.title {
          font-style: italic;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 65px);
          margin-bottom: 20px;
        }

        &.technique {
          margin-bottom: 5px;
        }
      }
    }

    .repro-container {
      grid-area: repro;
      align-self: center;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 30px;

      .repro {
        min-height: var(--image-size);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
}
