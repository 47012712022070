.x-landingpage-x-row6-stamps {
  .items {
    display: grid;
    place-content: space-evenly;
    margin: 30px 0;
    grid-row-gap: 30px;
    
    @media(min-width: 768px) {
      grid-template-columns: 260px 260px 260px;
      margin: 0;
    }

    .item {
      display: flex;
      flex-flow: column;
      justify-content: center;
      row-gap: 10px;


      .number {
        color: #4E00A1;
        display: flex;
        flex-flow: row;
        column-gap: 5px;
        justify-content: center;
        font: normal normal bold 36px/32px Open Sans;
      }


      p {
        padding: 0 20px;
        font: normal normal normal 20px/32px Open Sans;
        text-align: center;
      }
    }
  }
}

