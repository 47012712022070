.common-share {
  position: relative;

  .share-bg {
    background: rgba(217, 217, 217, 0.4);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1;
  }

  &.flat {
    display: flex;
    flex-flow: row;
    width: 100%;
    justify-content: space-evenly;
  }

  .buttons {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    max-height: 0;
    overflow: hidden;
    position: absolute;
    top: 40px;
    transition: max-height ease-in-out 0.125s;
    z-index: 2;

    &.visible {
      max-height: 500px;
    }

    .buttons-list {
      display: flex;
      flex-flow: column;
      padding: 5px;
      row-gap: 5px;

      .share-button {
        height: 30px;
        display: flex;
        align-items: center;
        flex-flow: column;

        button {
          justify-content: center;
          display: flex;
          margin-left: 0 !important;
          margin-top: 0 !important;
        }
      }
    }
  }


}
