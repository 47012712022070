.auctioneers-common-cover {
  $cover-size: 210px;

  display: flex;
  height: $cover-size;
  justify-content: center;
  margin-right: 5px;
  width: $cover-size;

  img {
    max-height: calc(#{$cover-size} - 20px);
    max-width: calc(#{$cover-size} - 20px);
  }

  &.empty {
    a {
      text-decoration: none;
    }

    .card {
      align-items: center;
      border: solid 1px #ccc;
      border-radius: 3px;
      box-shadow: 2px 2px 5px #666;
      color: #ccc;
      display: flex;
      font-size: 50px;
      height: 190px;
      justify-content: center;
      width: 130px;
    }
  }
}
