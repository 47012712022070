.x-landingpage-x-row4 {
  background: transparent linear-gradient(180deg, #EBEBF7 0%, #E6E2FF 100%, #B798D8 100%, #B798D8 100%) 0 0 no-repeat padding-box;

  .container {
    .r {
      
      display: flex;
      column-gap: 110px;
      
      &:first-of-type {
        padding-top: 60px;
      }
      
      &:last-of-type {
        padding-bottom: 15px
      }

      .c2 {
        display: none;
        width: 350px;
        align-content: center;

        @media (min-width: 768px) {
          display: block;
        }
        
        img {
          width: 100%;
        }
      }

      .c1 {
        flex: 1;
        text-align: center;

        @media (min-width: 768px) {
          text-align: left;
        }
        
        h2 {
          font-weight: bold;
          font-size: 42px;
          margin-bottom: 50px;
        }

        p {
          font-size: 20px;
          line-height: 32px;
          margin-bottom: 30px;

          a {
            font-size: 26px;
            font-weight: 600;
            color: #4E00A1;
          }
        }
      }
    }
  }
}
