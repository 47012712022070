@use '../../../../assets/stylesheets/artp_colors';

.common-dropdown {
  display: inline-block;
  justify-self: start;
  margin-right: 10px;
  z-index: 10;

  &.right {
    justify-self: end;
    margin-left: 10px;
    margin-right: 0;

    .dropdown {
      left: auto;
      right: 0;
    }
  }

  @media(min-width: 768px) {
    position: relative;
  }

  .dropdown {
    background-color: artp_colors.$white;
    box-shadow: none;
    color: artp_colors.$gray-dark;
    font-size: 14px;
    left: 0;
    margin-top: 5px;
    padding: 10px;
    position: absolute;
    transform: scaleY(0);
    transform-origin: 50% 0;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    z-index: 10;

    &.visible {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transform: scaleY(1);

      @media(max-width: 767px) {
        background-color: artp_colors.$white;
        width: 100%;
      }
    }
  }

  .title {
    font-size: 18px;

    @media(max-width: 992px) {
      font-size: 14px;
    }

    .fa-caret-down {
      margin-left: 5px;
      transition: transform 0.2s ease;
    }

    a,
    button {
      background: none;
      border: 0;
      color: artp_colors.$gray-dark;
      text-decoration: none;
      transition: color ease-out 100ms 0ms;

      &:hover {
        color: artp_colors.$gray;
      }
    }

    &.open {
      .fa-caret-down {
        transform: rotate(-180deg);
      }
    }

    .title-text {
      padding: 0 1px 0 3px;
    }
  }
}
