.legals-modale {
  max-height: 90vh;
  max-width: 90%;
  min-height: 300px;
  min-width: 300px;

  p {
    text-align: justify;
  }

  .artp-modal-content {
    width: 100%;
  }

  .artp-modal-header {
    h2 {
      font-size: 20px;
      margin-top: 0;
      line-height: 27px;

      @media(min-width: 768px) {
        margin-top: 5px;
        font-size: 24px;
      }
    }
  }

  .cgvtitle {
    font-size: 16px;
    font-weight: bold;
    margin-top: 25px;
  }

  .cgvarticle {
    font-weight: bold;
    margin-top: 10px;
  }

  .cgvaddress {
    margin-left: 20px;
  }
}


