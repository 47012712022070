.subscription-needed {
  background-color: rgba(235, 235, 235, 0.5);
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 5;
  
  .sn-banner {
    $light-grey: #ddd;
    $pad-before-opaque-gradient: 14%;
    align-self: center;
    background: linear-gradient(to left, rgba($light-grey, 0), rgba($light-grey, 0.8) $pad-before-opaque-gradient, rgba($light-grey, 0.8) calc(100% - #{$pad-before-opaque-gradient}), rgba($light-grey, 0) 100%);
    display: flex;
    padding: 2.5rem $pad-before-opaque-gradient;
    width: 100%;
    
    .centered-container {
      margin-left: auto;
      margin-right: auto;
      
      p {
        margin-bottom: 0;
      }
    }
  }
}
