.artp-Header-Logo {
  grid-area: logo;
  margin-right: 30px;

  @media (max-width: 768px) {
    margin-right: 0;
  }

  > img {
    height: 40px;

    @media (max-width: 1350px) {
      padding-top: 2px;
      height: 31px;
    }

    @media (max-width: 600px) {
      height: 28px;
    }
  }
}
