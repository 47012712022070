.ranking {
  .spinning {
    margin: 30px 0;
  }

  .ranking-body {
    border: solid 1px #ddd;
    padding: 2.4rem;

    .ranks {
      display: flex;
      flex-flow: row;
      height: 130px;

      .rank-labels {
        color: #000;
        font-size: 14px;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 10px 0;

        div {
          align-items: center;
          display: flex;
          font-weight: bold;
          height: 30px;
          margin-bottom: 5px;
        }
      }

      .rank-values {
        display: flex;
        flex: 1;
        flex-flow: row;
        height: 135px;
        overflow-x: auto;

        .rank {
          flex: 1;
          max-width: 100px;
          padding: 10px 15px;

          &:nth-of-type(even) {
            background-color: #f1f1f1;
          }

          div {
            align-items: center;
            display: flex;
            height: 30px;
            justify-content: center;

            &.year {
              font-weight: bold;
              margin-bottom: 5px;
            }

            &.value {
              margin-bottom: 5px;
            }

            &.evolution {
              font-weight: bold;

              img {
                height: 22px;
                width: 22px;
              }
            }
          }
        }
      }
    }

    .switch {
      margin-top: 15px;
      text-align: left;

      .artp-input-label {
        margin-left: 5px;
        padding: 2.4rem;
      }
    }
  }
}
