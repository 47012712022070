@use "sass:color";

.artp-Header-MyAccount-promotion-notifier {
  background-color: #BF187C;
  font-size: 14px;
  line-height: 2.1rem;
  padding: 10px 15px;
  position: relative;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s linear;

  &::after {
    bottom: 100%;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
    top:-20px;
    border: 10px solid transparent;
    border-bottom-color: #BF187C;
    left: calc(50% - 10px);

    &:hover {
      background-color: color.adjust(#BF187C, $lightness: -15%);
    }
  }

  &:hover {
    background-color: color.adjust(#BF187C, $lightness: -15%);
  }
}
