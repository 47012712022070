.indexes-common-filters {
  display: flex;
  flex-flow: column;

  .filters {
    column-gap: 15px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  &.with-openner {
    .filters {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.15s ease-out;

      &.open {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
      }
    }

    .display-selector {
      padding-top: 15px;
      text-align: right;

      .fa {
        transition: transform linear 0.5s;

        &.open {
          transform: rotate(360deg);
        }
      }
    }
  }

  .filter {
    margin-top: 15px;
    width: 150px;
  }
}
