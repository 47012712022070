@use '../../../../../../assets/stylesheets/constants';
@use '../constants' as constants2;

.subscriptions-list-cards-skeleton {
  background-color: #F4F3F1;
  border-radius: 20px;
  overflow: hidden;
  border: #D9D3C3 solid 1px;
  min-height: 857px;
  height: 100%;
  width: 100%;
  
  .spin {
    color: #999;
  }
}
