.artists-artist {
  .lots {
    .lot {
      &.ail {
        background-color: #fff;
        border: 1px solid var(--color-ail);
        display: flex;
        flex-flow: column;
        padding-top: 15px;
        position: relative;

        @media(max-width: 768px) {
          padding-top: 25px;
        }

        .data {
          display: grid;
          flex: 1;
          grid-template-areas: 'info img';

          @media(max-width: 768px) {
            grid-template-areas: 'img'
              'info';
          }

          .info {
            grid-area: info;
            padding-top: 140px;
            width: 200px;

            @media(max-width: 768px) {
              padding-top: 10px;
            }

            p {
              text-align: left;

              &.title {
                font-size: 14px;
                font-style: italic;
              }

              &.details {
                margin-top: 15px;

                @media(max-width: 768px) {
                  margin-top: 30px;
                }
              }

              &.auctioneer {
                font-size: 14px;
                font-weight: 600;
                text-decoration: underline;
              }
            }
          }

          .img {
            align-self: center;
            //background-color: rgba(241, 241, 239, 0.6);
            display: flex;
            flex: 1;
            grid-area: img;
            justify-content: center;
            padding: 5px;

            img {
              filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
              max-width: 300px;

              @media(max-width: 768px) {
                max-height: 220px;
                max-width: 100%;
              }
            }
          }
        }
        
        .block-title {
          background: var(--color-ail);
          color: #fff;
          font-size: 9px;
          font-weight: 600;
          left: 0;
          line-height: 12px;
          padding: 5px 0;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 0;
          width: 155px;
        }

        .tag {
          flex-flow: column;
          left: 15px;
          top: 50px;

          .country {
            border: solid 1px var(--color-ail);
            font-size: 14px;
            text-align: center;
          }

          @media(max-width: 768px) {
            bottom: -1px;
            left: inherit;
            right: -1px;
            top: inherit;

            .block-date-ail {
              align-self: flex-end;
            }
          }
        }
      }
    }
  }
}
