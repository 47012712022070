.total-access-list {
  position: relative;

  .export-button {
    position: absolute;
    right: 0;
    transform: translateY(-100%);
  }
  
  .col {
    display: flex;
    flex-direction: column;
    
    .data {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    
    .spinner {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }

  .total-access-common-list {
    display: flex;
    flex: 1 0 100%;
    padding: 20px 10px;

    &:nth-child(odd) {
      background: #fafafa;
    }
    
    .artdb-link {
      float: right;
      font-size: 14px;
      margin-right: 3px;
      padding-right: 7px;
    }

    .image-container {
      height: 150px;
      margin-right: 30px;
      width: 150px;

      .picto-img,
      .lot-img {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .lot-img > a {
        display: contents;
      }
    }
    
    .info-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .title {
        font-size: 1.5em;
        margin: 10px 0;
      }

      .info-rows {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .info-row {
          display: flex;
          margin: 5px 0;

          &.bold {
            font-weight: bold;
          }

          > div {
            flex: 1;
          }
        }
      }
    }
  }
}
