@use '../../../../../../assets/stylesheets/artp_colors';

.auctioneers-future-sales-sale-progress-bar {
  display: flex;
  flex-flow: row;
  overflow: hidden;
  padding: 3px 6px 3px 0;

  @media(max-width: 768px) {
    border-left: solid 1px artp_colors.$success-border;
    padding: 0;
  }
}
