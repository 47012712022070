.auctioneers-subscription {
  font-size: 16px;

  h1 {
    font-size: 26px;
    margin-bottom: 20px !important;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    line-height: 28px;

    &.form {
      padding-bottom: 25px;
      padding-top: 25px;
      text-align: center;
    }
  }

  ul {
    li {
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
}
