.myartprice-lots-searchbar {
  .filters-container {
    display: flex;
    flex-flow: row;
    width: 100%;
    column-gap: 15px;

    .filler {
      flex: 1;
    }

    .common-dropdown {
      background: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 5px 10px;
      margin-right: 0;
      text-align: left;
      text-decoration: none;
      transition: background ease-out 100ms 0ms;

       .artp-input-group {
        align-items: center;
        display: flex;
        padding: 7px;

        .artp-input-label {
          align-self: center;
          color: #999;
          font-size: 14px;
          padding: 0 10px 0 0;
          text-align: right;
          width: 150px;
        }
      }

      .title {
        button {
          font-size: 14px !important;
          color: #000;
          text-decoration: none;
        }
      }
    }
  }
}

