.homepage-classifieds {
  .data {
    color: #1D1D1F;

    a {
      color: #000;
      text-decoration: none;

      &:hover .l1-1 {
        text-decoration: underline;
      }
    }

    .classified {
      display: grid;
      height: 100%;
      grid-template-areas: 'img'
        'title'
        'artist'
        'category'
        'price';

      @media(min-width: 768px) {
        grid-template-areas: 'img img'
         'title category'
          'artist price';
      }

      .l0 {
        grid-area: img;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        justify-content: center;

        div {
          height: 370px;
          width: 100%;
          max-width: 370px;
          border-radius: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          @media(min-width: 768px) {
            height: 230px;
          }

          @media(min-width: 992px) {
            height: 303px;
          }

          @media(min-width: 1200px) {
            height: 370px;
          }
        }
      }

      .l1-1 {
        grid-area: title;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        flex: 1;
        padding-right: 5px;
        text-align: center;

        @media(min-width: 768px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }

      .l1-2 {
        grid-area: category;
        align-self: center;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        margin-top: 10px;

        @media(min-width: 768px) {
          text-align: right;
          margin-top: 0;
        }
      }

      .l2-1 {
        grid-area: artist;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        flex: 1;
        text-align: center;
        margin-top: 10px;

        @media(min-width: 768px) {
          text-align: left;
          margin-top: 0;
        }
      }

      .l2-2 {
        grid-area: price;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        margin-top: 10px;

        @media(min-width: 768px) {
          text-align: right;
          margin-top: 0;
        }

      }
    }
  }
}
