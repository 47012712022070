.button-follow {
  display: inline-block;

  .btn-default {
    border: unset;
    outline: none;

    .fa {
      color: #ccc;

      @keyframes scale {
        5% {
          transform: scale(1.3);
        }

        20% {
          transform: scale(1);
        }

        25% {
          transform: scale(1.3);
        }

        80% {
          transform: scale(1);
        }
      }

      &.fa-heart-o,
      &.fa-heart {
        color: #000;
      }
    }
  }

  .btn-default:hover { background: none; }
  .btn-default:hover > .fa-heart { animation: scale 2s infinite; }
  .btn-default:hover > .fa-heart-o { animation: scale 2s infinite; }
}

