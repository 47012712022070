@use '../../../../../assets/stylesheets/constants';

.subscriptions-list-promotion {
  row-gap: 15px;
  display: flex;
  flex-flow: column;
  cursor: pointer;

  &.with-banner {
    row-gap: 0;
    height: 50px;
    
    @media(min-width: 768px) {
      height: 250px;
    }
  }

  .banner-data {
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('#{constants.$image-source}subscription/bg-bdd.jpg');
    
    @media(min-width: 2200px) {
      background-size: cover;
      background-image: url('#{constants.$image-source}subscription/bg-bdd-2545.jpg');
    }
  }

  .banner-store {
    background: #1f1e23 url('#{constants.$image-source}subscription/marketplace.jpg') center center no-repeat;
  }

  .banner-auctioneer {
    background: #1f1e23 url('#{constants.$image-source}subscription/auctioneer.png') center center no-repeat;
  }

  .banner-auctioneer,
  .banner-data,
  .banner-store {
    display: none;

    @media(min-width: 768px) {
      display: block;
      height: 200px
    }
  }

  .img {
    text-align: center;
  }

  .applied {
    background-color: #4D09A5;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.subscriptions-list-promotion-description {
  background-color: #F1F1EF;
  padding: 30px 0;
  margin: 60px 0;

  h2 {
    font-size: 1.65em;
    letter-spacing: 2px;
    margin-bottom: 30px;
    padding-bottom: 4px;
    padding-top: 20px;
  }
}
