@use '../../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-header-store-informations-contact {
  text-align: center;

  .btn-default {
    background-color: #fff;
    border: solid 1px #E53140;
    color: #E53140;
    padding: 15px;
    border-radius: 5px;

    .fa {
      margin-right: 10px;
    }
  }
}
