.marketplace-classified-link-to-artist {
  padding-top: 30px;

  a {
    font-weight: bold;
  }

  @media(min-width: 768px) {
    border: 0;
    padding-top: 0;

    a {
      font-weight: normal;
    }
  }
}
