@use '../../../../../../../assets/stylesheets/constants';

ul.subscriptions-list-cards-modules-basics {
  li {
    background-image: url('#{constants.$image-source}subscription/check-basic-2.svg');

    ul {
      li {
        background-image: url('#{constants.$image-source}subscription/li-basics.png');
      }
    }
  }
}

ul.subscriptions-list-cards-modules-basicsstore {
  li {
    background-image: url('#{constants.$image-source}subscription/check-artprice-store.svg');


    .input-hidden:checked + .artp-flipswitch {
      background-color: #f2e1f4 !important;
      border: solid 1px #f2e1f4 !important;
    }
    
    ul {
      li {
        background-image: url('#{constants.$image-source}subscription/li-pro.svg');
      }
    }
  }
}
