.auctioneers-sale-lots-list {
  margin-top: 30px;

  .spinner {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 30px;
    width: 100%;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 60px;
  }
}
