.myartprice-flipswitches {
  .artp-flipswitch-container {
    .artp-flipswitch {
      height: 16px;
      min-width: 32px;
      width: 32px;

      &::after {
        border-radius: 100%;
        height: 14px;
        top: 1px;
        width: 16px;
      }
    }

    .artp-input-label {
      padding-left: 10px;
    }

    label {
      font-size: 14px;
    }
  }
}
