@use '../../../../assets/stylesheets/artp_colors';

.homepage-search {
  padding-bottom: 18px;
  transition: opacity var(--universal-search-timing) cubic-bezier(0.4, 0, 0.2, 1);

  &.co {
    top: 120px;

    @media (min-width: 768px) {
      top: 150px;
    }
  }

  &.opacity-0 {
    opacity: 0;
  }

  @media(min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 265px;
    width: calc(var(--container-width) - 80px);
  }

  .homepage-search-container {
    background-color: #fff;
    margin: auto;
    position: relative;
    z-index: 20;

    @media(min-width: 768px) {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
    }

    .search-universal-input {
      .comment {
        padding: 0 30px 15px;
      }
    }
  }
}
