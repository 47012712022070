.mapchart-tooltip {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
  color: #2c2c2c;
  font-size: 0;
  line-height: 0;
  overflow: visible;
  padding: 0;
  pointer-events: none;
  position: absolute;
  width: auto;
  z-index: 9999;

  .mapchart-tooltip-content {
    font-size: 14px;
    line-height: 16px;
    overflow: hidden;
    padding: 8px 10px;
  }

  .mapchart-tooltip-arrow {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: -1;

    > span {
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 9px 9px 0;
      bottom: -7px;
      display: block;
      height: 0;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      width: 0;
    }
  }
}
