@use '../../../../../../assets/stylesheets/constants';

.marketplace-stores-common-section-title {
  height: 240px;
  position: relative;
  text-align: center;
  z-index: 2;

  h1 {
    color: #fff;
    left: 0;
    position: absolute;
    top: 80px;
    width: 100%;
  }

  .left {
    background-position: left;
    background-repeat: no-repeat;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .right {
    background-position: right;
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &.stats {
    background: linear-gradient(to left, #05328d 0%, #0d5b9b 50%, #1ca4b2 100%);
    background-position: center;
    background-repeat: no-repeat;

    .right {
      background-image: url(#{constants.$image-source}store-stats-right.png);
    }

    .left {
      background-image: url(#{constants.$image-source}store-stats-left.png);
    }
  }

  &.edit {
    background: linear-gradient(to left, #003092 0%, #3f33b3 40%, #5e32c3 100%);
    background-position: center;
    background-repeat: no-repeat;

    .left {
      background-image: url(#{constants.$image-source}store-edit-left.png);
    }

    .right {
      background-image: url(#{constants.$image-source}store-edit-right.png);
    }
  }
}
