.classified-details-info-fineart {
  .main-info {
    .classified-details-block {
      @media(min-width: 768px) {
        font-weight: normal;
        margin-bottom: 0;
      }

      .classified-label {
        display: block;


        @media(min-width: 768px) {
          display: none;
        }
      }
    }
  }
}
