.artists-artist-lots-no-access-drawer-future {
  .details {
    margin-top: 30px;
    display: flex;
    flex-flow: row;
    column-gap: 15px;
    
    .args {
      flex: 1;
    }
  }
}
