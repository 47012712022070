.auctioneers-common-header-list {
  h1 {
    margin-bottom: 15px !important;
  }

  .col-xs-12 {
    padding-bottom: 15px;
  }

  .count-cards {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    @media(max-width: 768px) {
      flex-wrap: wrap;
    }

    .count-card {
      box-shadow: 2px 2px 5px #ccc;
      width: 32%;

      @media(max-width: 768px) {
        width: 49%;
      }

      &.missing-sale {
        box-shadow: none;
        font-size: 22px;
        padding-top: 25px;
        text-align: center;
      }

      &.link-plus {
        align-items: center;
        display: flex;
        font-size: 22px;
        justify-content: center;
        text-align: center;

        @media(max-width: 768px) {
          padding: 15px 0;
          width: 100%;
        }
        
        .fa {
          font-size: 32px;
        }
      }

      .card-title {
        font-size: 24px;
        padding-left: 15px;
        padding-top: 15px;

        i {
          color: #428bca;
          margin-left: 5px;
        }
      }

      .card-count {
        font-size: 26px;
        letter-spacing: 2px;
        padding-bottom: 15px;
        padding-left: 15px;
      }
    }
  }
}
