.auctioneer-agreement-contact-modale {
  font-size: 16px;

  .contact {
    border-left: solid 3px #ccc;
    padding-left: 15px;
  }

  .bottom {
    text-align: right;
  }
}
