@use "sass:color";
@use '../../constants';

.subscriptions-list-cards-subscription-button {
  width: fit-content;
  align-self: center;
  font-weight: 500;  
}

.card {
  &.essential {
    .subscriptions-list-cards-subscription-button {
      border: solid 1px constants.$essential;
      background-color: #fff;
      color: #000;
      
      &:hover {
        background-color: #e6e6e6;
      }
    }
  }

  &.subscription.professional {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$professional;
      color: #000;

      &:hover {
        background-color: color.adjust(constants.$professional, $lightness: -15%);
      }
    }
  }

  &.store.professional {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$marketplace-professional;
      color: #fff;

      &:hover {
        background-color: color.adjust(constants.$marketplace-professional, $lightness: -15%);
      }
    }
  }

  &.basics {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$basic;
      color: #000;

      &:hover {
        background-color: color.adjust(constants.$basic, $lightness: -15%);
      }
    }
  }

  &.premium {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$premium;
      color: #fff;

      &:hover {
        background-color: color.adjust(constants.$premium, $lightness: -15%);
      }
    }
  }

  &.basicsstore {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$basicsstore;
      color: #000;

      &:hover {
        background-color: color.adjust(constants.$basicsstore, $lightness: -15%);
      }
    }
  }

  &.artpricestore {
    .subscriptions-list-cards-subscription-button {
      background-color: constants.$store;
      color: #000;
    }
  }
}
