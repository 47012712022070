@use '../../../../../assets/stylesheets/constants';

.marketplace-stores-list {
  background-color: #f1f1f1;

  .header {
    background-color: #E53140;
    margin-bottom: 50px;
    overflow-x: auto;
    padding-bottom: 35px;

    .header-container {
      padding-bottom: 30px;
      text-align: center;

      h1 {
        color: #fff;
        font-size: 36px;
        line-height: 49px;
        font-weight: 600;
        margin: 30px 0;
      }

      p {
        color: #fff;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
        padding: 0 140px;

        @media(max-width: 992px) {
          padding: 0 20px;
        }
      }

      a {
        font-size: 18px;
        text-decoration: underline;
        color: #fff;

        &:hover {
          color: #ccc;
        }
      }
    }

    @media(max-width: 992px) {
      margin-bottom: 75px;
      padding-bottom: 55px;
    }
  }

  .list-header {
    margin-bottom: 50px;
    text-align: center;

    h2 {
      font-size: 32px;
      line-height: 49px;
      font-weight: 600;
    }

    p {
      color: #999;
    }
  }

  .store-list {
    --gap: 50px;
    column-gap: var(--gap);
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    padding-bottom: 60px;
    row-gap: var(--gap);

    @media (min-width: 768px) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .back-to-top-container {
    bottom: 25px;
    float: right;
    margin-bottom: 10px;
    position: sticky;
    transform: translateX(10px);

    @media(min-width: 1300px) {
      transform: translateX(60px);
    }
  }
}
