.search-years {
  display: flex;
  
  .cell:not(.first) {
    margin-left: 10px;
  }
  
  /* hide arrows */
  /* stylelint-disable property-no-vendor-prefix */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable property-no-vendor-prefix */
}
