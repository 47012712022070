.account-messages {
  .content {
    display: flex;
    flex-flow: row;
    margin-top: 15px;

    .left-menu {
      margin-right: 5px;
      width: 190px;
    }

    .body {
      flex: 1;
      padding-bottom: 15px;
    }
  }
}
