@use '../../../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-form-images-image-cropper {
  align-items: center;
  background: rgba(221, 221, 221, 0.8);
  border-radius: 3px;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;

  @mixin button-mixin {
    background-color: transparent;
    border: solid 1px transparent;
    color: artp_colors.$link-blue;

    &:hover {
      border-color: artp_colors.$primary-border-hover;
      border-radius: 3px;
      color: artp_colors.$link-blue-hover;
    }
  }

  .image-cropper {
    align-items: center;
    background-color: artp_colors.$white;
    border: solid 1px artp_colors.$gray;
    border-radius: 3px;
    box-shadow: 2px 2px 5px artp_colors.$gray-darker;
    display: flex;
    height: 80%;
    justify-items: center;
    max-height: 570px;
    max-width: 550px;
    width: 80%;
    z-index: 1000;

    .cropper-header {
      background-color: artp_colors.$gray-light;
      color: artp_colors.$black;
      padding: 5px;

      .cropper-title {
        font-size: 16px;
      }

      .cropper-actions {
        button {
          @include button-mixin;
        }
      }
    }

    .cropper-container {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;
      width: 100%;
    }

    .cropper-controls {
      padding: 5px 0;

      button {
        @include button-mixin;
      }
    }
  }
}
