.search-quicklist {
  .filter {
    padding: 5px;
  }
  
  .search-quicklist-items {
    max-height: 600px;
    overflow-y: auto;
    
    .search-quicklist-item {
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      flex-flow: row;
      justify-items: center;
      padding: 2px 5px 2px 10px;

      .item-label {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 10;
      }

      .item-check {
        margin-right: 5px;
        width: 15px;
      }

      &:hover {
        background-color: #c9c9c9;
      }

      &.selected {
        font-weight: bold;
      }
    }
  }
}
