.account-signin-confirmation-report {
  border-radius: 20px;
  color: #fff;
  width: 100%;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  height: 360px;
  background-position: center right;
  background-repeat: no-repeat;

  .bg {
    background: #1a171b;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .content {
    max-width: 585px;
    padding: 15px;
    z-index: 1;
    position: absolute;
    
    @media(min-width: 768px) {
      bottom: 0;
      padding: 0 0 30px 30px;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 34px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }
}

