@use "sass:color";
@use '../../../../../assets/stylesheets/artp_colors';

.report-page {
  .reports-report-table {
    width: calc(100vw - 30px);
    overflow-x: auto;
    margin: 30px 0;
    
    @media(min-width: 768px) {
      width: 100%;
    }

    table {
      font-size: 14px;

      a {
        color: #000;
        text-decoration: underline;
        transition: color linear 200ms;

        &:hover {
          color: color.adjust(artp_colors.$primary, $lightness: -15%) !important;
        }
      }

      thead {
        tr {
          background-color: #000;
          color: #fff;

          td {
            white-space: normal;
            padding: 5px;

            &.sortable {
              cursor: pointer;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: #eee;
          }

          td {
            padding: 5px;
          }
        }
      }

      tfoot {
        tr {
          td {
            padding: 5px;
          }
        }
      }
    }
  }
}
