@use "sass:color";
@use '../../../../../../app/assets/stylesheets/constants';

.artists-artist-landingpage {
  .content {
    display: grid;
    grid-template-columns: 300px 480px ;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    &.two-records {
      grid-template-columns: 480px 480px 300px;
    }

    &.with-consignment {
      grid-template-columns: 500px 480px;
    }

    h2 {
      margin-bottom: 30px;
    }

    .sales {
      box-shadow: 0 3px 6px #00000029;
      background-image: url(#{constants.$image-source}landing/consignment-artist.png);
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-flow: column;


      .actions {
        padding: 15px;
        text-align: center;

        .artp-btn {
          background-color: #7C0E3D;
          color: #fff;

          &:hover {
            
            background-color: color.adjust(#7C0E3D, $lightness: 10%); 
            border-color: color.adjust(#7C0E3D, $lightness: 10%);
          }
        }
      }

      .logo {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
      }
    }

    .consignment {
      width: 100%;
      background-image: url(#{constants.$image-source}landing/consignment-artist.png);
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 30px;
      display: flex;
      flex-flow: column;

      .title {
        background-color: #7C0F3D;
        text-align: center;
        font-weight: 400;
        color: #fff;
        font-size: 14px;
        padding: 10px 15px;
        position: absolute;
      }

      .info {
        padding: 45px 30px 0;
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 26px;
        text-align: center;
      }

      .actions {
        margin-top: 0;
        padding: 15px;
        display: grid;
        text-align: center;
        grid-row-gap: 15px;
        grid-column-gap: 15px;
        grid-template-columns: 1fr 1fr;
        
        .btn-container {
          .artp-btn {
            background-color: #7C0F3D;
            color: #fff;

            &:hover {
              background-color: color.adjust(#7C0F3D, $lightness: 10%);
              border-color: color.adjust(#7C0F3D, $lightness: 10%);
            }
          }
        }

        .logo {
          display: flex;
          align-items: center;
          flex: 1;
          justify-content: center;
        }
      }
    }


    .record {
      cursor: pointer;
      width: 100%;
      position: relative;
      border: 1px solid #7C0E3D;

      .encart {
        position: absolute;
        top: 0;
        left: 0;
        background-color: #7C0F3D;
        color: #fff;
        font-weight: 400;
        padding: 10px 15px;
        font-size: 14px;
      }

      .data {
        padding: 60px 15px 15px;
        display: grid;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-columns: 2fr 1fr;


        .left {
          padding-top: 30px;

          .price {
            font-weight: bold;
            font-size: 30px;
          }

          .artists {
            font-size: 14px;
          }

          .title {
            font-style: italic;
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
        
          img {
            max-width: 200px;
            max-height: 300px;
          }
        }
      }
    }
  }
}
