@use '../../../../assets/stylesheets/_artp_colors';

.store-tile {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  margin: 20px 5px;
  position: relative;
  text-align: center;
  width: 30%;

  @media (max-width: 992px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    transition: 0.3s;
  }

  .header {
    padding: 15px 0;
    text-align: center;

    h2 {
      font-size: 20px;
      font-weight: normal;
      margin: 0;
    }
  }

  .body {
    height: 210px;

    a {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      img {
        border-radius: 3px;
        max-height: 100%;
        max-width: 100%;

        &.no_picture_img {
          box-shadow: none;
          max-height: 70%;
          max-width: 70%;
        }
      }
    }
  }

  .footer {
    font-size: 16px;
    padding-top: 15px;
    text-align: center;
  }

  .button-follow {
    display: none;

    @media(max-width: 720px) {
      display: inline;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    transition: 0.2s;

    .button-follow {
      display: inline;
      position: absolute;
      right: 5px;
      top: 5px;

      button {
        background: none;
        border: 0;
        outline: none;
      }
    }
  }
}
