@use "sass:color";

.common-carousel {
  .slick-list {
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    z-index: 2;

    &::before {
      color: #999;
      transition: all linear 0.2s;

      &:hover {
        color: color.adjust(#999, $lightness: -70%, $space: hsl);
      }
    }
  }

  .slick-next {
    right: -15px;
  }

  .slick-prev {
    left: -15px;
  }
}
