@use "sass:color";
@use '../../../../../assets/stylesheets/artp_colors';

.reports-report-summary {
  .items {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    padding: 0 0 30px 0 !important;
    margin-top: 30px;
    font-size: 14px;

    a {
      color: #000;

      &:hover {
        color: color.adjust(artp_colors.$primary, $lightness: -15%) !important;
      }
    }

    .sections {
      display: grid;
      grid-row-gap: 15px;

      .section {
        padding: 0 20px;
        text-align: center;
        font-weight: 600;
      }
    }
  }
}
