.subscriptions-list-cards-subscription-refund-infos {
  flex: 1;
  margin-bottom: 20px;

  .strike {
    text-decoration: line-through;
  }

  p {
    text-align: center;
    padding: 0 15px;
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
    margin-bottom: 0;
    color: #4D09A5;

    &.monthly,
    &.upgrade,
    &.multiuser {
      color: #000;
    }
    
    &.annotate {
      cursor: pointer;
    }
  }
}
