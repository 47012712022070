@use '../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-artists {
  display: flex;
  flex-flow: row;

  .artists {
    flex: 1;
    position: relative;

    .fa {
      color: artp_colors.$link-blue;
      cursor: pointer;
      padding-left: 5px;

      &:hover {
        color: artp_colors.$link-blue-hover;
      }
    }

    .adding-artist {
      left: calc(25% - 25px);
      position: absolute;

      .fa {
        color: artp_colors.$black;
      }
    }

    ol.processing {
      color: artp_colors.$gray;

      .fa {
        display: none;
      }
    }
  }

  .form {
    flex: 1;
  }
}
