@use '../../../../../../assets/stylesheets/constants';

.artists-artist-marketplace {
  .shadow-left {
    border: 1px solid #1b1b1b !important;
    box-shadow: 0 0 11px 4px #000 !important;
  }


  .artists-artist-marketplace-classifieds {
    padding: 20px 0;

    .complement {
      @media(min-width: 992px) {
        .btn-sell {
          display: none;
        }
      }
    }

    .classifieds {
      column-gap: 30px;
      display: flex;
      flex-flow: row;
      margin-top: 10px;

      a {
        .classified {
          background-color: var(--common-gray);
          border: solid 1px var(--common-gray);
          display: flex;
          flex-flow: column;
          height: 400px;
          padding: 15px;
          width: 270px;
          z-index: 1;

          .data {
            min-height: 90px;

            p {
              color: #000;
              font-size: 12px;
              margin-bottom: 0;
              text-align: center;

              &.title {
                font-size: 14px;
                font-style: italic;
                letter-spacing: 0.04em;
                line-height: 19px;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              &.storename {
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.04em;
                line-height: 19px;
                margin: 8px 0 5px;

                a {
                  color: #000;
                }
              }
            }
          }

          .img-container {
            display: flex;
            flex: 1;
            height: 300px;
            justify-content: center;
            margin-bottom: 15px;
            width: 240px;

            img {
              align-self: center;
              max-height: 260px;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
