@use '../../../../../assets/stylesheets/artp_colors';
@use '../../../../../assets/stylesheets/constants';

.search-common-global-search-tabs {
  $size-collapse: 600px;

  margin-bottom: 25px;
  position: relative;
  width: 100%;


  @media(min-width: 768px) {
    margin-bottom: 45px;
  }

  .container {
    padding-left: 5px;
    padding-right: 5px;

    @media(min-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .tabs {
    column-gap: 5px;
    row-gap: 5px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;

    @media(min-width: 768px) {
      row-gap: 15px;
      column-gap: 10px;
    }

    .tab {
      cursor: pointer;
      text-transform: capitalize;
      font-size: 12px;
      border: 1px solid #999;
      border-radius: 10px;
      padding: 5px;
      color: #999;
      position: relative;
      transition: all ease-in-out 150ms;

      @media(min-width: constants.$main-viewport-desktop-width) {
        font-size: 14px;
        padding: 5px 10px;
      }

      @media(min-width: 768px) {
        font-size: 16px;
      }

      &:hover {
        color: #fff;
        background-color: #c01b88;
        border: 1px solid #c01b88;
      }

      &.active {
        border-color: #c01b88;
        color: #c01b88;
        cursor: inherit;

        &:hover {
          color: #c01b88;
          background-color: #fff;
        }
      }

      &.disabled {
        color: artp_colors.$gray-dark;
        cursor: not-allowed;
        font-style: italic;
      }

      div {
        text-align: center;

        &.badge-count {
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }
}
