@use '../../../../assets/stylesheets/artp_colors';

.back-to-top-button {
  background: artp_colors.$primary;
  border-radius: 50%;
  bottom: 10px;
  box-shadow: 2px 2px 10px rgba(50, 50, 50, 0.17);
  cursor: pointer;
  display: table;
  height: 3em;
  overflow: hidden;
  right: 10px;
  text-align: center;
  transition: 200ms;
  width: 3em;

  @media (max-width: 768px) {
    height: 2.5em;
    width: 2.5em;
  }

  i {
    color: artp_colors.$white;
    display: table-cell;
    font-size: 1.5em;
    transition: 200ms;
    vertical-align: middle;

    &.to-bottom {
      transform: rotate(180deg);
    }
  }

  &:active {
    box-shadow: none;
  }
}
