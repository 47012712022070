.indexes-common-categories {
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding: 4px;
  }

  .react-select__control {
    min-height: 34px;
  }
}
