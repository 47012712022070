$my-message: #5bc0de;
$message-border: #e4eef8;
$message-background: #f6f9fc;
$transition: 200ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;

.messages-visible {
  background-color: $message-background;
  border: solid 1px $message-border;
  border-radius: 5px;
  box-shadow: 0 0 30px $message-border;
}

.classified-conversation-box {
  border-top: 1px solid $message-border;
  min-height: 100px;
  overflow: hidden;
  padding-right: 0;
  padding-top: 20px;
}

.actions-conversation {
  margin-bottom: 20px;

  &.customer-service-message {
    background-color: #f1f1f1;
  }

  &.first,
  &.messages-visible {
    border-top: 0;
  }

  &.ng-enter {
    margin-left: 100%;
    transition: 200ms;
  }

  &.ng-enter-active {
    margin-left: 0;
  }

  &.ng-leave {
    margin-left: 0;
    transition: 200ms;
  }

  &.ng-leave-active {
    margin-left: 100%;
  }

  &.ng-move {
    transition: 200ms;
  }

  &.ng-move-active {
    background: #fff;
  }

  .my-message {
    border: solid 1px $my-message;
    color: $my-message;
  }

  .button-send {
    padding: 5px 25px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1000000000;
  }

  .toolbar {
    flex: 1;
    position: absolute;
    right: 15px;
    text-align: right;
    top: 15px;
    white-space: nowrap;
    width: 100px;

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: $transition;
    }

    &.ng-hide {
      line-height: 0;
      padding: 0 10px;
      width: 0;
    }

    @media (max-width: 768px) {
      top: 110px;
    }
  }

  .grid {
    display: flex;
    flex-flow: row nowrap;
  }

  .img {
    flex: 2;
    min-height: 100px;
    padding: 0 15px;
  }

  .data {
    flex: 12;
  }

  .message-content {
    flex: 1;
    padding: 15px;
  }

  .conversation {

    background: #fff;
    border: 1px solid $message-border;
    border-radius: 5px;

    margin-left: 50px;
    margin-top: 20px;
    opacity: 1;

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: $transition;
    }

    &.ng-hide {
      line-height: 0;
      opacity: 0;
      padding: 0 10px;
    }

    .message-content {
      border-top: 1px solid $message-border;

      &.first {
        border-top: 0;
      }
    }
  }

  .reply {
    background-color: $message-border;
    padding: 15px;

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: $transition;
    }

    &.ng-hide {
      line-height: 0;
      opacity: 0;
      padding: 0 10px;
    }
  }

  .reply-box {
    background-color: $message-border;
    height: auto;
    opacity: 1;
    position: relative;

    .ta-root {
      background-color: #fff;
      border: 0;
      border-top: 1px solid $message-border;
    }

    &.ng-hide-add,
    &.ng-hide-remove {
      transition: $transition;
    }

    &.ng-hide {
      height: 0;
      line-height: 0;
      padding: 0 10px;
    }
  }
}
