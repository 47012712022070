.lot-follow {
  display: inline-block;

  .btn-default {
    color: #c00 !important;
    outline: none;
    
    .fa {
      @keyframes scale {
        5% {
          transform: scale(1.3);
        }

        20% {
          transform: scale(1);
        }

        25% {
          transform: scale(1.3);
        }

        80% {
          transform: scale(1);
        }
      }
    }
  }

  .btn-default:hover > .fa-heart { animation: scale 2s infinite; }
  .btn-default:hover > .fa-heart-o { animation: scale 2s infinite; }
}

