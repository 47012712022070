@use '../../../../../assets/stylesheets/artp_colors';

.myartprice-home-common-classified {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  margin: 20px 10px;
  position: relative;
  text-align: center;
  width: 30%;

  @media (max-width: 992px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  .button-follow {
    display: none;

    button {
      background: none;
      border: 0;
    }

    @media(max-width: 720px) {
      display: inline;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    transition: 0.2s;

    .classified-title-hs {
      display: inline;
      font-size: 1.6rem;
      font-style: italic;
      font-weight: bold;
      overflow: hidden;
      position: absolute;
      text-overflow: ellipsis;
      top: 10px;
      width: 100%;
    }

    &:hover {
      box-shadow: 2px 2px 5px artp_colors.$gray-eee;
      transition: 0.2s;

      .button-follow {
        display: inline;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }

  div {
    margin-bottom: 7px;
  }

  .classified-title-hs a {
    color: #000;
    font-size: 1.6rem;
    font-style: italic;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .prices {
    font-size: 1.4rem;
  }

  .classified-img {
    align-items: center;
    display: flex;
    height: 270px;
    justify-content: center;
    width: 100%;

    img {
      margin: auto;
      max-height: 210px;
      max-width: 210px;
      text-align: center;
    }
  }

  .classified-artist {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .classified-sale {
    text-align: center;
  }

  .classified-auctioneer {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
