@use 'constants';

.marketplace-stores-top {
  margin-bottom: 30px;
  margin-top: 30px;

  h2 {
    border-bottom: 1px solid;
    font-size: 1.65em;
    letter-spacing: 2px;
    margin-bottom: 30px;
    padding-bottom: 10px;

    .badge {
      margin-right: 5px;
      padding: 3px 7px;
    }

    a {
      color: #666;
      text-decoration: none;
    }
  }

  .list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(#{constants.$height-img-container} + #{constants.$margin-top-link} + #{constants.$height-link} - 5px);
    justify-content: center;
    overflow: hidden;

    .view-container {
      padding: 0;
    }
  }
}
