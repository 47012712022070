@use '../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-form {
  h1 {
    margin-bottom: 15px;
  }

  .spinner {
    margin-top: 50px;
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr repeat(4, calc(100vw / 4 - 5px)) 1fr;
    margin-top: 20px;

    @media(min-width: 768px) {
      grid-template-columns: 1fr repeat(4, 187px) 1fr;
    }

    @media(min-width: 992px) {
      grid-template-columns: 1fr repeat(4, 242px) 1fr;
    }

    @media(min-width: 1200px) {
      grid-template-columns: 1fr repeat(4, 292px) 1fr;
    }


    .tab {
      border-bottom: solid 1px artp_colors.$gray;
      color: #555;
      cursor: pointer;
      font-size: 14px;
      height: 40px;
      padding: 10px 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 425px) {
        font-size: 80%;
      }

      &.active {
        background-color: artp_colors.$white;
        border: solid 1px artp_colors.$gray;
        border-bottom: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
    }
  }

  .content {
    margin-bottom: 20px;
    padding: 30px 15px;
  }
}
