.marketplace-classified-same-to-sell {
  margin: 30px 0;

  @media(min-width: 768px) {
    margin-top: 15px;
  }

  .label {
    color: #646464;
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    white-space: normal;
  }

  .link {
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
  }
}
