.myartprice-settings-artists {
  .filters-container {
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;

    .search-artist {
      flex-grow: 1;
    }

    .delete {
      height: 25px;
      margin-top: 5px;
    }
  }
}
