@use "sass:color";
@use '../../../../../assets/stylesheets/constants';

.ails-info-auctioneers {
  color: #fff;
  margin-top: 70px;
  background-size: cover;
  background-position: center;
  background-image: url("#{constants.$image-source}ail/ail-background-720.png");

  @media(min-width: 992px) {
    background-image: url("#{constants.$image-source}ail/ail-mdv-1920.png");
  }

  @media(min-width: 1200px) {
    background-image: url("#{constants.$image-source}ail/ail-mdv-2560.png");
  }

  .action {
    text-align: center;
    margin: 70px 0;

    .btn-default {
      background-color: #0BA9DB;
      border-color: #0BA9DB;
      color: #fff;
      padding: 15px 50px;
      font-size: 18px;
      transition: all linear 0.1s;

      &:hover {
        background-color: color.adjust(#0BA9DB, $lightness: -10%);
        border-color: color.adjust(#0BA9DB, $lightness: -10%);
      }
    }
  }

  .blocks {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: none;
    color: #fff;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 30px;
      grid-template-rows: 1fr;
    }

    .block {
      background: rgba(11, 169, 219, 0.49) 0 0 no-repeat padding-box;
      display: flex;
      flex-direction: column;

      h3 {
        text-align: center;
        align-content: center;
        margin: 20px 0;
        padding: 0 40px;
        line-height: 40px;
      }

      .options {
        background-color: transparent;
        font-size: 18px;
        align-content: center;
        text-align: center;
        padding: 15px;
        flex: 1;

        @media(min-width: 768px) {
          border-top: solid 1px #fff;
        }
      }
    }
  }
}
