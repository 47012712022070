.myartprice-common {
  display: flex;
  flex-flow: row;

  .spinner {
    margin: 30px 0;
    text-align: center;
  }
  
  .no-results {
    color: #1aad19;
    width: 100%;
  }

  .data-container {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
  }

  .col-container {
    flex-basis: 0;
    flex-grow: 1;
    width: 100%;

    @media (max-width: 992px) {
      border-left: 0;
    }
  }
}
