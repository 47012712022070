.scroll {
  position: relative;

  .shadow-left-container {
    left: 13px;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 20px;
    z-index: 10;

    @media(min-width: 768px) {
      left: calc((100vw - var(--container-width)) / 2 - 10px);
    }

    @media(min-width: 992px) {
      left: calc((100vw - var(--container-width)) / 2 + var(--block-width) - 10px);
    }

    .shadow-left {
      border: 1px solid #fff;
      box-shadow: 0 0 11px 0 #000;
      height: 100%;
      width: 0;
    }
  }

  .body {
    margin-left: 15px;
    overflow: auto;
    scroll-behavior: smooth;
    width: calc(100vw - 30px);

    @media (min-width: 768px) {
      margin-left: calc((100vw - var(--container-width)) / 2);
      overflow: hidden;
      width: calc((100vw - var(--container-width)) / 2 + var(--container-width) - 15px);
    }

    @media (min-width: 992px) {
      margin-left: calc((100vw - var(--container-width)) / 2 + var(--block-width));
      width: calc((100vw - var(--container-width)) / 2 + var(--container-width) - var(--block-width) - 15px);
    }
  }

  .footer {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin-top: 15px;
    row-gap: 10px;

    .navigation {
      margin-right: 20px;
    }
    
    .artp-btn {
      min-height: 50px;   
      white-space: normal;

      &:not(&:first-of-type) {
        margin-left: 15px;
      }
    }

    .complement {
      align-self: center;
      flex: 1;
      margin-top: 15px;
      text-align: center;

      @media(min-width: 768px) {
        margin-top: 0;
        text-align: left;
      }
    }

    .btn-previous,
    .btn-next {
      width: 50px;
    }

    .btn-previous {
      margin-right: 5px;
    }

  }

  &.no-menu {
    .body {
      width: calc(100vw - 15px);

      @media (min-width: 768px) {
        margin-left: calc((100vw - var(--container-width)) / 2);
        overflow: hidden;
        padding: 0 15px;
        width: calc((100vw - var(--container-width)) / 2 + var(--container-width) - 15px);
      }
    }

    .shadow-left-container {
      left: calc((100vw - var(--container-width)) / 2 - 10px);
    }
  }
}
