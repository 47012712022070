.artists-artist-indexes {
  position: relative;

  .graphs {
    .ca {
      background-color: #fff;
      padding: 60px 15px 15px;
      position: relative;

      h3 {
        font-size: 20px;
        letter-spacing: -0.02em;
        line-height: 24px;
      }

      p.sub-title {
        color: #1d1d1d;
        font-size: 9px;
        margin-bottom: 15px;
      }

      .lock {
        left: 50%;
        position: absolute;
        top: 50%;

        &.graph-lock {
          left: inherit;
          margin-left: 0;
          right: 10px;

          @media(min-width: 768px) {
            right: 25px;
          }

          @media(min-width: 992px) {
            right: 5px;
          }

          @media(min-width: 1200px) {
            right: 31px;
          }

          @media(min-width: 1400px) {
            right: 37px;
          }

          @media(min-width: 1600px) {
            right: 46px;
          }

          @media(min-width: 2000px) {
            right: 66px;
          }
        }
      }
    }
  }

  a {
    color: #000;
  }

  .header {
    border-bottom: solid 3px var(--color-active-or-hover);
    column-gap: 3px;
    display: flex;
    flex-flow: row;
    font-size: 14px;
    font-weight: 300;

    div {
      background-color: var(--common-gray);
      display: flex;
      flex: 1;
      min-height: 50px;
      transition: background-color linear 0.125s;

      a {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 5px;
        width: 100%;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: var(--color-active-or-hover);
      }

      .fa {
        color: #999;
        margin-left: 5px;
      }

      &:first-of-type {
        a {
          background-color: var(--color-active-or-hover);
        }
      }

      &:last-of-type {
        flex: none;
        width: 40px;
      }
    }
  }
}

.actions {
  margin-top: 30px;
  text-align: center;

  @media(min-width: 768px) {
    text-align: left;
  }
}
