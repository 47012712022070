.subscriptions-list-header {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;

  h2 {
    font-size: 22px;
    font-weight: normal;
  }

  h1 {
    font-size: 28px;
    line-height: 38px;
    font-weight: normal;
    
    @media(min-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  p {
    max-width: 992px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;

    @media(min-width: 768px) {
      font-size: 18px;
      line-height: 32px;
    }
  }
}
