.account-lots-lists-add-to-list-button-clear {
  @media (max-width: 767px) {
    span {
      display: none;
    }
  }

  .title {
    padding-left: 5px;
  }
}
