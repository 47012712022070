.account-confirm-account {
  font-size: 16px;

  .process {
    display: flex;
    flex-flow: column;
    padding: 15px 20%;
    position: relative;

    .timeline {
      border-right: dashed 2px #000;
      height: 190px;
      left: initial;
      position: absolute;
      right: calc(20% + 30px);
      top: 55px;
      width: 5px;
      z-index: 2;
    }

    @media(max-width: 991px) {
      padding: 15px 10%;

      .timeline {
        right: calc(10% + 30px);
      }
    }

    @media(max-width: 768px) {
      padding: 15px;

      .timeline {
        height: 250px;
        left: 45px;
        right: initial;
        top: 55px;
        z-index: 2;
      }
    }

    .group {
      display: flex;
      flex-flow: row;
      margin-top: 30px;

      .group-label {
        font-weight: bold;
        padding-top: 10px;
        width: 250px;
      }

      .group-input {
        flex: 1;

        .artp-account-email-status {
          display: flex;
          flex-flow: column;
          margin: 10px 0;
          row-gap: 10px;

          .in-progress {
            font-size: 14px;
            font-style: italic;

            .fa {
              margin-right: 5px;
            }
          }
        }
      }

      .group-step {
        margin-right: 15px;
        width: 50px;
        z-index: 5;
      }

      @media(max-width: 768px) {
        flex-wrap: wrap;
        margin-bottom: 30px;

        .group-input {
          flex: 1;
          padding-left: 65px;
        }

        .group-label {
          flex: none;
          width: calc(100% - 80px);
        }
      }
    }
  }
}
