.layout-header-search-bar-container-bg {
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  top: var(--header-top-height);
  width: 100%;
  position: fixed;
  z-index: 47;
  transition: background-color var(--universal-search-timing);

  @media(min-width: 768px) {
    top: var(--header-height, 131px);
    z-index: 46;
  }
}

.layout-header-search-bar-container {
  max-height: 100%;
  left: 50%;
  overflow-y: auto;
  position: relative;
  transform: translateX(-50%);
  top: 0;
  z-index: 45;

  @media (min-width: 768px) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: calc(100vw - 50px);
  }

  @media (min-width: 992px) {
    width: calc(100vw - 175px);
  }

  .layout-header-search-bar-container-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity var(--universal-search-timing) cubic-bezier(0.4, 0, 0.2, 1);
    padding: 0 5px 15px 5px;

    .close-svg {
      width: 35px;
    }

    &.opacity-0 {
      opacity: 0;
    }
  }

  .search-universal {
    .search-universal-input {
      .input {
        padding-top: 35px;

        .magnifying-glass {
          top: 23px;
          
          @media(min-width: 768px) {
          top: 26px;
          }
        }
      }
    }
  }
}
