@use '../../../../assets/stylesheets/artp_colors';

#drawer-root {
  position: relative;
  z-index: 4025;
}

.common-drawer-container {
  $transition-time: 0.4s;
  
  opacity: 1;
  transition: opacity calc(#{$transition-time} + 16ms) ease-in-out;
  visibility: visible;

  &.background-passthrough {
    visibility: hidden;
  }

  &.background-hidden {
    opacity: 0;
  }

  .close {
    padding: 15px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .common-drawer {
    background: #92bce0;
    bottom: 0;
    height: 100%;
    position: fixed;
    top: 0;
    transform: translateX(0);
    transition: transform $transition-time ease-in-out;

    &.right {
      box-shadow: -2px 2px 10px rgba(50, 50, 50, 0.17);;
      right: 0;

      &.drawer-hidden {
        transform: translateX(100%);
      }
    }

    &.left {
      box-shadow: 2px 2px 10px rgba(50, 50, 50, 0.17);;
      left: 0;

      &.drawer-hidden {
        transform: translateX(-100%);
      }
    }
  }
}

.drawer-black-bg {
  background-color: rgba(0, 0, 0, 0.65);
  display: inline-grid;
  height: 100%;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;

  .artp-drawer {
    background-color: #fff;
    border-radius: 0.15rem;
    height: auto;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    &.left {
      justify-self: left;
      left: 0;
    }

    &.right {
      justify-self: right;
      right: 0;
    }

    .drawer-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
      position: relative;

      @media (max-width: 700px) {
        width: 100%;
      }

      @media (min-width: 768px) {
        width: 700px;
      }

      @media (min-width: 1024px) {
        width: 600px;
      }

      .drawer-title {
        background-color: #333;
        color: #fff;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 15px;
        min-height: 50px;
        padding: 5px 15px;
        width: 100%;

        .fa {
          float: right;
          margin-top: 5px;

          &.fa-times {
            cursor: pointer;
          }
        }
      }

      .filters {
        border: 0 !important;

        .filter {
          .artp-checkbox-container {
            padding-left: 2px;
            padding-right: 0;

            .artp-input-label {
              font-size: 14px;
              font-weight: normal;
              padding-left: 4px;
            }

            .artp-checkbox {
              height: 20px;
              width: 20px;

              .svg-checkbox {
                stroke-width: 1.9px;
              }
            }
          }
        }
      }

      section {
        border-bottom: solid 1px artp_colors.$gray;
        margin-bottom: 15px;
        padding: 0 15px 15px;

        @media (max-width: 768px) {
          border-bottom: 0;
          border-top: solid 1px artp_colors.$gray;
          padding-bottom: 5px;
          padding-top: 5px;
        }

        .artp-input-group {
          display: flex;
          flex-flow: row;
          margin: 7px 0;
          width: 100%;

          @media (max-width: 768px) {
            flex-flow: column;
            margin-bottom: 5px;
          }

          .artp-input-label {
            font-size: 14px;
            font-weight: 400;
            padding: 5px 0.6em 0.3em;
            text-align: right;
            width: 35%;

            @media (max-width: 768px) {
              text-align: left;
              width: 100%;
            }
          }

          .search-bar-input {
            display: flex;
            flex: 1;
            flex-flow: row;
            flex-wrap: wrap;
            width: 65%;

            @media (max-width: 768px) {
              width: 100%;
            }

            .cell {
              padding-left: 3px;

              &.flex {
                flex: 1;
              }

              &.first {
                padding-left: 0;
              }
            }

            .react-select__value-container {
              div {
                width: auto !important;
              }
            }

            .react-select__control {
              width: 100%;
            }
          }
        }

        &.results {
          background-color: artp_colors.$gray-lighter;
          margin-bottom: 0;
          padding: 10px 15px;
          text-align: center;

          .fa {
            color: artp_colors.$gray-dark;
          }
        }

        &.apply {
          align-items: center;
          bottom: 0;
          display: flex;
          justify-items: center;
          margin-bottom: 0;
          padding: 0;
          width: 100%;

          .submit-button {
            background-color: #337ab7;
            border: 0;
            color: #fff;
            cursor: pointer;
            font-size: 20px;
            font-weight: normal;
            height: auto;
            padding: 15px 0;
            text-align: center;
            transition: background-color ease-out 200ms 0ms, width cubic-bezier(0.25, 0.25, 0.75, 0.75) 200ms;
            white-space: nowrap;
            width: 100%;
            z-index: 110;

            &:hover {
              background-color: #2d6da3;
            }
          }
        }
      }
    }
  }
}

@keyframes appears-from-left {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes appears-from-right {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}
