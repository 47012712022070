@use '../../../../../assets/stylesheets/artp_colors';

.marketplace-reporting-documents {
  button {
    background: none;
    font-weight: normal;
    border: 0;
    color: artp_colors.$link-blue;
    font-size: 14px;
    transition: all ease-in-out 250ms;
    margin-left: 15px;

    &:hover {
      color: artp_colors.$link-blue-hover
    }
  }
}
