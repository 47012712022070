@use "sass:color";
@use '../../../../assets/stylesheets/constants';

.estimates-info {
  .container {
    max-width: 810px;
  }


  .header {
    width: 100%;
    height: 200px;
    background: url('#{constants.$image-source}/estimate/estimate-background.png') no-repeat;
    background-size: cover;
    margin-bottom: 40px;
  }

  h1 {
    font-size: 32px;
    line-height: 43px;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 10px;
  }

  .prices {
    margin-top: 40px;
    background-color: #FAFAFA;

    @media(min-width: 768px) {
      padding: 30px;
    }

    table {
      width: 100%;

      thead {
        font-size: 24px;
        line-height: 33px;
        font-weight: 600;

        tr {
          th {
            font-size: 14px;
            text-align: center;
            padding: 10px;

            @media(min-width: 768px) {
              font-size: inherit;
            }
          }
        }
      }

      tr {
        td {
          padding: 10px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;

          .artp-btn {
            padding: 5px 10px;
            font-size: 14px;
          }
          
          @media(min-width: 767px) {
            font-size: 16px;
            
            .artp-btn {
              padding: inherit;
              font-size: inherit;
            }  
          }
        }
      }
    }
  }

  .faq {
    margin-top: 60px;

    .faq-title {
      font-size: 32px;
      line-height: 43px;
    }

    .faq-sections {
      font-size: 16px;
      line-height: 22px;

      .faq-content {
        max-height: 0;
        overflow: hidden;
        border-bottom: solid 1px #CAC6BA;
        transition: all linear 0.125s;

        &:last-of-type {
          border-bottom: none;
        }

        p {
          text-align: left;
          font-size: 16px;
          line-height: 22px;
        }

        li {
          margin-bottom: 10px;
        }

        &.visible {
          max-height: 350px;
          padding: 30px 0;
        }
      }

      .faq-headline {
        display: flex;
        align-items: center;
        margin: 15px 0;

        button {
          font-size: 16px;
          line-height: 22px;
          border: 1px solid #CAC6BA;
          margin-top: 0;
          background-color: #fff;
          color: color.adjust(#CAC6BA, $lightness: -15%);
          transition: all linear 0.125s;

          &:hover {
            color: color.adjust(#CAC6BA, $lightness: -30%);
            background-color: #f1f1f1;
            border-color: color.adjust(#CAC6BA, $lightness: -15%);
          }
        }

        div {
          flex: 1;
        }
      }

      .block {
        padding: 15px 0;
        border-bottom: solid 1px #ccc;
      }
    }

    .faq-headline {
      color: #000;
      text-decoration: none;
    }

  }
}
