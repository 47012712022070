@use '../../../../../../../assets/stylesheets/artp_colors';

.marketplace-stores-list-search-bar-advanced-search-button {
  background-color: #fff;
  border: solid 1px artp_colors.$primary-border;
  border-radius: 5px;
  color: artp_colors.$primary;
  padding-left: 15px;
  padding-right: 15px;

  &:hover {
    border: solid 1px artp_colors.$primary-border-hover;
    color: artp_colors.$primary-hover;
  }

  .fa {
    margin-left: 15px;
  }

  @media(max-width: 992px) {
    align-self: flex-start;
    padding-bottom: 7px;
    padding-top: 7px;
  }
}
