.marketplace-classified-follow {
  .marketplace-classified-follow-see-all {
    color: #868686;
    cursor: pointer;
  }

  .marketplace-classified-follow-see-all {
    margin: 15px 0;
  }
}
