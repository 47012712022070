@use "sass:color";

.artp-ui-pagination {
  $default-hover-darken-percentage: 20%;
  $default-background-color: #fff;
  $default-border-color: #f1f1f1;
  $default-color: #000;
  $disable-color: #f1f1f1;
  $default-active-color: #fff;
  $default-active-background-color: #007bff;

  display: flex;

  .artp-ui-pagination-container {
    display: flex;
    flex-flow: row;
    user-select: none;

    div {
      border: solid 1px $default-border-color;
      border-right: 0;
      cursor: pointer;
      min-width: 40px;
      padding: 5px;
      text-align: center;
      transition: all 0.3s;

      &.artp-ui-separator {
        background-color: $default-background-color;
        color: $default-color;
        cursor: default;

        &:hover {
          background-color: $default-background-color;
          border-color: $default-border-color;
          color: $default-color;
        }
      }

      &.next {
        border-right: solid 1px $default-border-color;
      }

      &:hover {
        background-color: color.adjust($default-active-background-color, $lightness: -$default-hover-darken-percentage);
        border-color: color.adjust($default-active-background-color, $lightness: -$default-hover-darken-percentage);
        color: $default-active-color;
      }

      &.artp-ui-pagination-active-page {
        background-color: $default-active-background-color;
        border-color: $default-active-background-color;
        color: $default-active-color;

        &:hover {
          background-color: $default-active-background-color;
        }
      }

      &.disable {
        color: $disable-color;

        &:hover {
          background-color: $default-background-color;
          border-color: $default-border-color;
          cursor: default;
        }
      }
    }

    &.loading {
      .artp-ui-pagination-cell,
      .artp-ui-pagination-active-page,
      .artp-ui-pagination-separator {
        background-color: $default-background-color !important;
        border-color: $disable-color;
        color: $disable-color !important;
        cursor: default !important;
      }
    }
  }
}
