.subscriptions-list-common-period {
  display: flex;
  flex-flow: row;
  border-radius: 50px;
  overflow: hidden;
  background-color: #F1F1EF;
  font-size: 18px;

  button {
    white-space: nowrap;
    width: 90px;
    outline: 0;
    background-color: transparent;
    border: solid 2px #F1F1EF;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: normal;

    &:focus {
      outline: none;
    }

    &.active {
      color: #000;
      background-color: #fff;
      border: solid 1px #4D09A5;
      border-radius: 50px;
      font-weight: 600;
    }

    &.disabled {
      color: #ccc;
      font-style: italic;
    }
  }
}
