@use '../stylesheets/constants';

.subscription-purchase-common-one-day-refound-info {
  background-color: #fff;
  box-shadow: 2px 2px 5px #555;
  padding: 15px 30px 15px 15px;
  position: absolute;
  width: 325px;
  z-index: 1;

  .details {
    color: constants.$main-gray;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    text-align: left;
    width: 100%;

    .close {
      height: 50px;
      padding: 5px;
      position: absolute;
      right: -30px;
      text-align: center;
      top: -15px;
      width: 50px;
      z-index: 2;
    }
  }
}
