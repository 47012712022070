.event-details {
  .event-banner {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.8rem;

    @media (min-width: 768px) {
      flex-direction: row;
    }
    
    .img-container {
      a {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 100vw;
        width: 56.8rem;
        
        img {
          height: auto;
          min-width: 100%;
          width: auto;
        }
      }
    }
    
    img {
      flex: 1;
      max-height: 20rem;
      max-width: 100%;
    }

    .go-back {
      padding: 1rem 0 1.5rem;
      
      i {
        font-size: 4rem;
      }
    }
  }
  
  h1 > a {
    text-decoration: none !important;
  }

  .event-informations {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    > div {
      display: flex;
      flex: 0.4;
      flex-direction: column;
      justify-content: space-evenly;
      
      @media (max-width: 768px) {
        flex: 1;
      }
    }
  }

  .event-wordpress {
    padding-bottom: 5rem;
    
    .event-content {
      font-size: 1.6rem;
      text-align: justify;
      width: 100%;
      
      img {
        display: block;
        margin: auto;
        max-width: 100%;
      }

      * {
        font-family: sans-serif !important;
      }
    }
  }
}
