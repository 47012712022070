.account-lots-lists-add-to-list-button-no-access {
  display: flex;
  justify-content: center;

  button {
    align-items: center;
    display: flex;

    .add-button-svg-icon {
      height: 18px;
      padding-right: 5px;
    }
  }

  @media (max-width: 991px) {
    justify-content: space-between;
  }
}
