.homepage-events {
  .events {
    overflow: hidden;
    justify-items: center;

    .event {
      --event-width: 352px;
      --event-height: 352px;

      border: solid 1px #1a171b;
      border-radius: 15px;
      max-width: 352px;
      height: var(--event-width);
      width: 100%;
      position: relative;
      overflow: hidden;

      @media(min-width: 768px) {
        max-width: inherit;
        height: var(--event-height);
        width: var(--event-width);
      }

      @media(min-width: 992px) {
        --event-width: 462px;
        --event-height: 360px;
      }

      @media(min-width: 1200px) {
        --event-width: 562px;
      }

      .abs-div {
        height: 100%;
        position: absolute;
        width: 100%;
      }

      .event-data {
        bottom: 0;
        color: #fff;
        height: 100%;
        display: flex;
        flex-flow: column;
        padding: 15px;

        @media(min-width: 992px) {
          padding: 20px;
        }

        .dt {
          font-size: 14px;
          font-weight: 300;
        }

        h3 {
          font-size: 24px;
          font-weight: 700;
          margin: 5px 0 15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @media(min-width: 768px) {
            margin: 5px 0 25px;
          }

          @media(min-width: 992px) {
            margin: 5px 0 25px;
          }

          @media(min-width: 1200px) {
            margin: 5px 0 25px;
          }
        }

        .desc {
          flex: 1;
          font-size: 15px;
          font-weight: 300;
          max-height: 220px;
          overflow-y: auto;
          margin-bottom: 15px;

          @media(min-width: 768px) {
            font-size: 14px;
          }

          @media(min-width: 992px) {
            font-size: 16px;
          }
        }
      }

      .bg {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .filter {
        background: #1a171b;
        opacity: 0.6;
      }
    }
  }
}
