.payment-common-available-payments {
  padding: 10px 15px;
  border: solid 1px #E4E2DC;

  h4 {
    margin-bottom: 20px;
  }

  .cards {
    margin: 15px 0;
    display: flex;
    flex-flow: row;
    column-gap: 5px;

    justify-content: space-between;
  }
}
