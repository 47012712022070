.search-sort {
  flex-direction: column;
  width: 200px;

  .freshness-title {
    color: #444;
    font-size: 1.2em;
  }

  .radios-list {
    align-items: center;
    display: flex;
    width: 100%;

    .option {
      color: #666;
      font-weight: unset !important;
    }
  }
}
