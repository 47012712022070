.myartprice-checkboxes {
  .artp-checkbox-container {
    .artp-input-label {
      font-size: 14px;
      font-weight: normal;
    }

    .disabled {
      color: #ccc;
    }
  }
}
