.account-lots-lists-sort {
  .title {
    font-size: 14px;

    button {
      background: none;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #000;
      padding: 5px 10px;
      transition: background ease-out 100ms 0ms;
      white-space: nowrap;

      &:hover {
        background-color: #f1f1f1;
        color: #000;
        font-weight: normal;
      }
    }
  }

  .dropdown {
    .option {
      align-items: center;
      display: flex;
      flex-flow: row;
      white-space: nowrap;
    }
  }
}
