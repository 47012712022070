@use '../../../../../../assets/stylesheets/constants';

.ails-info-testimonials-carousel {
  .pages {
    margin-top: 15px;
    display: flex;
    column-gap: 5px;

    .page {
      padding: 5px;
      cursor: pointer;
    }

    .filler {
      flex: 1;
    }
  }

  .testimonials {
    overflow: hidden;
    scroll-behavior: smooth;
    column-gap: 30px;
    display: grid;
    width: calc(100vw - 30px);
    grid-template-columns: repeat(7, 360px);

    @media(min-width: 768px) {
      width: calc(100vw - (100vw - var(--container-width)));
      justify-content: left;
    }

    .testimonial {
      border: 1px solid #D5D5D5;
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .icon {
        background-image: url("#{constants.$image-source}ail/ail-guillemets-bleu.svg");
        background-repeat: no-repeat;
        background-position: top left;
        background-size: 40px;
        margin-bottom: 15px;
        height: 40px;
      }

      .testimonial-info {
        flex: 1;
        font-size: 16px;
        font-style: italic;
      }

      .testimonial-contact {
        font-weight: bold;
        margin: 15px 0;
        height: 30px;
      }
    }
  }
}
