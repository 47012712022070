.breadcrumb {
  background-color: #fff;
  font-size: 12px;

  nav {
    ol {
      list-style-type: none;
      padding-left: 0;
      display: flex;

      li {
        &:after {
          display: inline-block;
          color: #000;
          content: '>';
          font-size: 80%;
          font-weight: bold;
          padding: 0 3px;
        }

        &:last-of-type {
          &:after {
            content: ''
          }

          a {
            color: #999;
          }
        }
      }
    }
  }
}
