@use '../../../../../../assets/stylesheets/constants';


@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.search-universal-skeletons-small {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr;
  justify-items: center;
  grid-row-gap: 15px;
  margin-bottom: 40px;

  @media (min-width: constants.$main-viewport-desktop-width) {
    padding: 0 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }

  .skeleton-block {
    display: grid;
    grid-column-gap: 15px;
    grid-template-columns: 50px 1fr;
    padding: 0 15px;
    width: 100%;

    @media (min-width: constants.$main-viewport-desktop-width) {
      grid-template-columns: 1fr;
      padding: 0;
      height: 245px;
      width: 170px;
    }

    div {
      animation: skeleton-loading 1s linear infinite alternate;
      border-radius: 10px;
      width: 100%;
    }

    .picto {
      height: 50px;

      @media (min-width: constants.$main-viewport-desktop-width) {
        height: 170px;
      }
    }

    .txt {
      height: 50px;

      @media (min-width: constants.$main-viewport-desktop-width) {
        margin-top: 25px;
        height: 25px;
      }
    }
  }
}
