@use '../../../../../../../assets/stylesheets/constants';

.artists-artist-lots-no-access-drawer-free-demo {
  width: 100%;
  margin-top: 60px;
  display: flex;
  flex-flow: column;
  height: 145px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  color: #fff;
  background-image: url("#{constants.$image-source}hp/free-test.jpg");
  background-size: cover;
  text-shadow: 0 3px 6px #000000b4;

  .l1 {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
  }

  .l2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 15px;
  }

  .l3 {
    .btn {
      border: none;
      padding: 10px 20px;
      background-color: #4D09A5;
      color: #fff;
      font-weight: 400;

      &:hover {
        text-shadow: none;
      }
    }
  }
}
