@use "sass:color";
@use '../../../../../../assets/stylesheets/artp_colors';

.buttons-navigation {
  .next,
  .prev {
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    padding: 5px 10px;
    text-decoration: none;
    transition: background-color 0.25s linear;


    &:hover {
      background-color: color.adjust(#fff, $lightness: -20%);
    }

    &:disabled {
      background-color: transparent;
      color: rgba(artp_colors.$gray-dark, 0.85);
      pointer-events: none;
    }
  }
}
