.ails-landingpage-sales-sale {
  margin-bottom: var(--ails-landingpage-sale-spacing);
  display: grid;
  grid-template-areas: 'sale-container'
    'lots' 
    'nav';
  grid-template-columns: 1fr;
  column-gap: 30px;
  row-gap: 5px;
  
  &:last-of-type {
    margin-bottom: 0;
  }

  @media(min-width: 768px) {
    grid-template-columns: 280px 1fr;
    grid-template-areas: 'sale-container lots'
      'sale-container nav';
  }

  .sale-container {
    grid-area: sale-container;

    a {
      color: #000;
      text-decoration: none;
      display: contents;

      .sale {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 3px 6px #00000029;
        display: flex;
        flex-flow: column;
        position: relative;
        padding: 15px;
        width: 100%;
        height: 455px;

        @media(min-width: 768px) {
          padding: 0;
        }

        .legend {
          display: flex;
          padding: 15px;
          flex-flow: row;
          margin-top: 20px;

          .block-date {
            bottom: 0;
            position: absolute;
            right: 0;
            background-color: #7C0F3D;
            color: #fff;
            display: flex;
            flex-flow: row;
            padding: 5px 0;
            justify-content: center;
            width: 75px;

            .block {
              display: flex;
              flex-flow: column;

              .day {
                font-size: 30px;
                font-weight: 600;
                text-align: center;
                line-height: 30px;
              }

              .other {
                font-size: 10px;
                text-align: center;
              }
            }

            &.two-part {
              width: 100px;

              .block {
                display: flex;
                flex-flow: column;
                position: relative;

                .separator {
                  border-left: solid 1px #fff;
                  height: 40px;
                  width: 1px;
                  background-color: var(--color-ail);
                  position: absolute;
                  top: 9px;
                  left: 0;
                }

                .day {
                  font-size: 24px;
                  line-height: 28px;
                }

                .other {
                  padding: 0 15px;
                }
              }
            }
          }

          .info-ail {
            flex: 1;
            width: 100%;

            .link {
              margin-top: 35px;
              text-transform: uppercase;
              font-size: 14px;
              cursor: pointer;
              font-weight: 600;

              &:hover {
                text-decoration: underline;
              }
            }

            .sale-title {
              font-size: 16px;
              font-weight: 500;
            }

            .location {
              margin-bottom: 20px;
              font-weight: 300;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .repro {
          display: flex;
          justify-content: center;
          height: 280px;
          width: 280px;
          padding-top: 25px;

          img {
            max-height: 230px;
            max-width: 230px;
          }
        }

        .encart {
          position: absolute;          
          color: #fff;
          font-size: 11px;
          text-transform: uppercase;
          background-color: #7C0F3D;
          width: 150px;
          text-align: center;
          padding: 2px 5px;
          left: 0;
          top: 0;
          
          @media (min-width: 768px) {
            top: 275px;              
          }
        }
      }
    }
  }

  .lots {
    grid-area: lots;
    overflow: hidden;
    scroll-behavior: smooth;
    grid-column-gap: var(--ails-landingpage-sale-spacing);
    column-gap: 30px;
    display: none;
    width: calc(100vw - 30px);

    @media(min-width: 768px) {
      display: grid;
      height: 420px;
      width: calc(100vw - (100vw - var(--container-width)) - 310px);
      justify-content: left;
    }
  }

  .nav {
    grid-area: nav;

    a {
      font-size: 16px;
      font-weight: 600;
      color: #1d1d1f;
    }

    div {
      justify-content: space-between;
      display: flex;
      column-gap: 5px;

      .btn-previous,
      .btn-next {
        border: 1px solid #333;
        color: #333;
        width: 40px;
        font-size: 18px;

        &.disabled {
          border: 1px solid rgba(0, 0, 0, 0.15);
          color: rgba(0, 0, 0, 0.15);
        }
      }

      .btn-previous {
        padding: 6px 14px 4px 11px;
      }

      .btn-next {
        padding: 6px 12px 4px 14px;
      }
    }
  }
}
