.account-messages-list-message {
  border-top: solid 1px #f1f1f1;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;

  &:last-of-type {
    border-bottom: solid 1px #f1f1f1;
  }

  &:nth-child(2n) {
    background-color: #f1f1f1;
  }

  &.readed {
    font-weight: normal;
  }

  &:hover {
    background-color: #ccc;
  }

  .date {
    padding-left: 15px;
    width: 150px;
  }

  .subject {
    flex: 1;
  }
}
