.subscriptions-list-warnings-warning {
  background-color: #fff;
  border: solid 1px #2c2c2c1a;
  margin-left: calc((100% - 90%) / 2);
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  min-width: 250px;
  font-weight: 500;
  position: absolute;
  top:  550px;
  z-index: 2;

  @media(min-width: 768px) {
    $width: 700px;
    width: $width;
    margin-left: calc((100% - #{$width}) / 2);
  }

  @media(min-width: 992px) {
    $width: 800px;
    width: $width;
    margin-left: calc((100% - #{$width}) / 2);
  }

  @media(min-width: 1200px) {
    $width: 900px;
    width: $width;
    margin-left: calc((100% - #{$width}) / 2);
  }

  .actions {
    text-align: center;

    button {
      background-color: #4D09A5;
      color: #fff;
      border: 1px solid #4D09A5;
      font-size: 16px;
      line-height: 31px;
      outline-style: none;
      padding: 0 30px;
      transition: background-color ease-out 200ms;
      white-space: nowrap;
      margin-bottom: 0;
      margin-top: 30px;
      height: 50px !important;
      border-radius: 25px;
      min-width: 250px;
      font-weight: 500;
      font-size: 18px;
    }
  }

  &.absolute {
    position: absolute;
    top: 350px;
  }

  h1 {
    font-size: 27px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    margin: 15px 0;

    @media(min-width: 768px) {
      font-size: 18px;
    }
  }
}
