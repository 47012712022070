.auctioneers-sales {
  .header {
    margin-bottom: 15px;
  }

  .data {
    .no-data {
      font-size: 26px;
      margin-top: 30px;
      text-align: center;
      width: 100%;
    }

    .spinner {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-top: 30px;
      width: 100%;
    }
  }

  &.past {
    .data {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
