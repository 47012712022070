.artp-Header-Baseline {
  cursor: auto !important;
  display: flex;
  flex: 1;
  justify-content: flex-start !important;
  margin-left: 30px;

  @media (max-width: 900px) {
    font-size: 12px;
  }

  @media (max-width: 768px) {
    display: none !important;
  }
}
