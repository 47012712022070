.x-landingpage-x-row1 {
  background: transparent linear-gradient(180deg, #EBEBF7 0%, #E6E2FF 100%, #B798D8 100%, #B798D8 100%) 0 0 no-repeat padding-box;

  .container {
    .r {
      position: relative;
      grid-column-gap: 30px;
      display: grid;
      grid-template-columns: 1fr;
      padding: 50px 0;
      
      @media(min-width: 768px) {
        padding: 50px 0 155px;
      }
      
      @media(min-width: 992px) {
        grid-template-columns: 1fr 370px;
      }

      .c1 {
        flex: 1;
        row-gap: 30px;
        display: flex;
        flex-direction: column;
        margin-bottom: 60px;
        text-align: center;
        
        @media(min-width: 992px) {
          margin-bottom: 0;
          text-align: left;
        }

        .c11 {
          font-size: 50px;
          font-weight: bold;
          color: #4E00A1;
        }

        .c12 {
          p {
            font-size: 20px;
            color: #191919;
            font-weight: 600;
          }
        }
      }

      .c2 {
        img {
          width: 100%;
        }
      }
    }
  }
}
