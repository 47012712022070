.common-accordion {
  .accordion-item {
    .title {
      h3 {
        position: relative;
        
        .right {
          position: absolute;
          right: 0;
        }
        
        .left {
          margin-right: 10px;
        }
      }
    }
  }
}
