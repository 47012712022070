@use '../../../../../assets/stylesheets/artp_colors';

.events-filters-bar {

  background-color: #f1f1f1;

  margin: 2rem 0 0;
  padding: 1.5rem 0;

  width: 100%;
  
  .flex-container {
    display: flex;
    justify-content: space-between;
    
    span {
      align-items: center;
      align-self: center;
      display: inline-flex;
      
      &.other-events-list-link {
        padding: 6px 12px;
      }
    }


    .country-filter,
    .dates-filter {
      display: inline-block !important;
      
      .dropdown-toggle {
        align-self: center;
        background-color: unset;
        border: 0;
        color: artp_colors.$gray-darker;
        font-size: 1.8rem;
        outline-width: 0;
        transition: color 0.1s ease-out;
        
        span {
          padding-right: 5px;
        }
        
        i.fa-caret-down {
          transform: rotate(0deg);
          transition: transform 0.2s;
          
          &.is-open {
            transform: rotate(180deg);
          }
        }
  
        &:hover {
          color: artp_colors.$gray-dark;
        }
      }
  
      .dropdown-menu {
        outline-width: 0;
        
        &.dropdown-menu-right {
          left: unset !important;
          right: auto;
        }
  
        .dropdown-header {
          margin: 0;
          outline-width: 0;
        }
  
        .dropdown-divider {
          border-top: 1px solid #e9ecef;
          height: 0;
          margin: 0.5rem 0;
          overflow: hidden;
        }
  
        .dropdown-item {
          background-color: transparent;
          border: 0;
          clear: both;
          color: artp_colors.$gray-darker;
          display: block;
          font-weight: 400;
          outline-width: 0;
          padding: 0.25rem 1.5rem;
          text-align: inherit;
          white-space: nowrap;
          width: 100%;
  
          &:hover {
            background-color: #f8f9fa;
            text-decoration: none;
          }
  
          &:disabled {
            background-color: transparent;
            color: rgba(artp_colors.$gray-dark, 0.85);
            pointer-events: none;
          }
        }
      }
    }


    .dates-filter {      
      .dates-dropdown-content {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 1.8rem;
        
        .flex-container {
          align-items: center;
        }
        
        i {
          padding: 2rem 1rem 0;
        }
        
        .validation-button {
          flex-grow: 1;
          padding-top: 1rem;
          text-align: right;
        }
        
        .form-group {
          margin-bottom: 0;
          
          label {
            font-weight: 400;
          }
        }
        
        i,
        label,
        input {
          color: artp_colors.$gray-darker;
        }
      }
    }


    .other-events-list-link {
      background-color: unset;
      border: 0;
      font-size: 1.8rem;
      outline-width: 0;
      
      a {
        color: artp_colors.$gray-darker;
        text-decoration: none;
        transition: color 0.1s ease-out;
        
        &:hover {
          color: artp_colors.$gray-dark;
        }
      }
    }
  
    .reset-btn {
      align-self: center;
      color: artp_colors.$gray-darker;
      cursor: pointer;
      margin: 0 5px;
      transition: color 0.1s ease-out;
      vertical-align: sub;
  
      &.disabled {
        background-color: transparent;
        color: artp_colors.$gray-dark;
        pointer-events: none;
      }
  
      &:hover {
        color: artp_colors.$gray;
      }
    }
  }
}

.events-selected-params {
  margin-bottom: 1.5rem;
  margin-top: 0.75rem;
}

.pagination-filter.artp-pagination {
  ul {
    margin: 0;
    vertical-align: middle;

    li.page {
      a {
        cursor: pointer;
        width: 30px;
      }
    }
  }
}
