.artp-modal.subscriptions-list-identification {
  border-radius: 20px;
  background-color: #fff;
  width: 90%;
  max-width: 700px;

  .user-submit-error-block {
    font-weight: 300 !important;
    font-size: 14px !important;
    padding-top: 10px !important;
    margin-left: 20px !important;
  }

  .artp-modal-content {
    padding: 0 !important;
    width: 100%;
  }
  
  .subscriptions-list-identification-content {
    padding: 20px 20px 70px;
    width: 100%;
    display: grid;
    grid-template-areas: 'top'
      'left';
    row-gap: 10px;
    column-gap: 30px;
    position: relative;
    
    @media(min-width: 768px) {
      padding: 50px 70px;
    }
    
    &.two-cols {
      grid-template-columns: 1fr 290px;
      grid-template-areas: 'top top'
      'left right';
    }
    
    h1 {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 0;

      @media(min-width: 768px) {
        font-size: 22px;
        line-height: 35px;
      }
    }

    .close {
      position: absolute;
      right: 5px;
      top: 5px;
      font-size: 20px;
      transition: all linear 0.150s;
      display: none;

      @media(min-width: 768px) {
        display: block;
      }
    }

    .top {
      grid-area: top;
    }
    
    .left {
      flex: 1;
      grid-area: left;
    }

    .right {
      grid-area: right;
      display: none;
      width: 290px;

      @media(min-width: 768px) {
        display: block;
      }

      .payment-info {
        background-color: #fff;
        border: solid 1px #BBBAB3;
        padding: 30px;
        font-size: 12px;

        .cards {
          flex-wrap: wrap;
          margin: 15px 0;
          display: flex;
          row-gap: 5px;
          column-gap: 5px;
        }
      }
    }
  }
  
  &.common-stylesheets-forms {
    h3 {
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}
