@use '../../../../../assets/stylesheets/artp_colors';

.exposition-block {
  a {
    cursor: pointer;
    height: 100%;
    text-decoration: none !important;
    width: 100%;

    .card {
      background-color: rgba(artp_colors.$gray-dark, 0);
      display: inline-block;
      height: 100%;
      overflow: hidden;
      padding: 0.8rem;
      transition: background-color 0.2s;
      width: 100%;

      .image-container {
        align-items: center;
        display: flex;
        flex-flow: column;
        height: 200px;
        justify-content: center;
      }

      .justify-content-center {
        display: flex;
        justify-content: center;
        max-height: 200px;
        max-width: 200px;

        > img {
          object-fit: contain;
        }
      }

      .card-title small {
        display: block;
        font-size: 55%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .card-title,
      .card-subtitle {
        text-align: center;

        small {
          color: artp_colors.$gray-darker;
        }
      }

      &:hover {
        background-color: rgba(artp_colors.$gray-dark, 0.15);
      }
    }

    @media (min-width: 576px) and (max-width: 768px) {
      width: 50%;
    }
  }
}
