.account-signin-confirmation {
  .container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 16px;
    
    .block {
      box-shadow: 0 3px 6px #00000029;
      opacity: 1;
      width: 630px;
      max-width: 95%;
      padding: 30px 50px;
      margin-top: 60px;

      h1 {
        font-weight: 600;
        font-size: 26px;
        text-align: center;
      }

      .content {
        text-align: center;
        margin-top: 30px;
        
        img {
          height: 200px;
        }
        
        i {
          font-size: 50px;
          padding: 30px 35px;
          border: dashed 1px #4D09A5;
          border-radius: 137px;
          color: #4D09A5;

          &.fa-check {
            &:before {
              content: '✓';
            }
          }
        }
        
        p {
          font-size: 16px;
          margin-top: 30px;
        }
      }
    }      
    
    .infos {
      width: 630px;
      max-width: 95%;
      margin-top: 15px;
      text-align: center;
      font-size: 14px;
    }
      
      .back {
        margin-top: 60px;
    }
  }
}
