.lot-record {
  position: relative;
  border: solid 1px #7C0E3D;
  margin-top: -50px;

  .card-header {
    background-color: #7C0E3D;
    color: #fff;
    height: 40px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description {
    padding: 0 15px 15px;
  }
}
