@use 'sass:math';

$background-color: #678;
$checked-color: #7ca728;
$checkbox-size: 20px;
$duration: 0.15s;
$font-color: #efefef;
$unchecked-color: #000;

.artp-checkbox-container {
  align-items: center;
  display: flex;
  flex-flow: row;

  @keyframes dothatopundetermined {
    0% {
      height: 0;
    }

    100% {
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.7);
    }
  }

  @keyframes dothatsquarecheck {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes dothatbottomcheck {
    0% {
      height: 0;
    }

    100% {
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) / 2);
    }
  }

  @keyframes dothatopcheck {
    0% {
      height: 0;
    }

    50% {
      height: 0;
    }

    100% {
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 1.2);
    }
  }

  input[type=checkbox] {
    display: none;
  }

  label {
    cursor: pointer;
    margin-bottom: 0; // anti bootstrap
  }

  &.light {
    label {
      font-weight: 300;
    }
  }

  .check-box {
    background-color: transparent;
    border: 1px solid $unchecked-color;
    border-radius: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.25);
    box-sizing: border-box;
    display: inline-block;
    height: var(--artp-checkbox-size, #{$checkbox-size});
    margin-right: 10px;
    position: relative;
    transition: border-color ease-in-out math.div($duration, 2);
    width: var(--artp-checkbox-size, #{$checkbox-size});

    &::before,
    &::after {
      background-color: $checked-color;
      border-radius: 5px;
      box-sizing: border-box;
      content: '';
      display: inline-block;
      height: 0;
      position: absolute;
      transform-origin: left top;
      transition: opacity ease-in-out 0.5s;
      width: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.2);
    }

    &::before {
      left: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.41);
      top: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.72);
      transform: rotate(-135deg);
    }

    &::after {
      left: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.05);
      top: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.37);
      transform: rotate(-45deg);
    }
  }

  &.square {
    .check-box {
      border-radius: 0;
    }
  }

  .check-box-undetermined {
    &::before,
    &::after {
      background-color: #ccc;
    }

    &::before {
      animation: dothatopundetermined $duration ease-in-out 0s forwards;
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.9);
      left: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.15 - 1px);
      top: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.55);
      transform: rotate(-90deg);
    }

    &::after {
      display: none;
    }
  }


  .check-box-checked-square {
    &::before {
      animation: dothatsquarecheck $duration ease-in-out 0s forwards;
      background-color: #000;
      border-radius: 0;
      height: calc(#{$checkbox-size} - 6px);
      left: 2px;
      top: 2px;
      transform: none;
      width: calc(#{$checkbox-size} - 6px);
    }
  }

  .check-box-checked {
    &::after {
      animation: dothatbottomcheck math.div($duration, 2) ease-in-out 0s forwards;
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 0.5);
    }

    &::before {
      animation: dothatopcheck $duration ease-in-out 0s forwards;
      height: calc(var(--artp-checkbox-size, #{$checkbox-size}) * 1.2);
    }
  }
}
