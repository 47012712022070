.ails-landingpage-lots {
    margin-bottom: var(--margin-bottom-block);
  
  .lots-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;

    @media(min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
