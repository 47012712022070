.marketplace-exposition-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;

  .event-thumbnail-container {
    height: 200px;
  }

  img.event-thumbnail {
    max-height: 100%;
  }
}
