.artp-Header-MyAccount-Content {
  display: flex;
  flex-direction: column;
  text-align: start;

  .nowrap {
    white-space: nowrap;
  }

  .customer-info {
    align-items: baseline;
    display: flex;
    justify-content: center;
    padding: 10px;
    white-space: nowrap;

    .silhouette-icon {
      height: 12px;
      margin-right: 8px;
    }
  }

  .subscription-info {
    font-size: 13px;
    line-height: 2rem;
    margin-top: 10px;
  }

  .link-list-item.flag-list-item:hover {
    background-color: #fff;
  }

  .flag-list-item {
    align-items: center;
    cursor: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;

    > a.selected {
      opacity: 0.333;
      pointer-events: none;
    }
  }
}
