@use "sass:color";
@use '../../../../../assets/stylesheets/constants';

.ails-info-contact {
  text-align: center;
  margin: 70px 0;

  .btn-default {
    background-color: #0BA9DB;
    border-color: #0BA9DB;
    color: #fff;
    padding: 15px 50px;
    font-size: 18px;
    transition: all linear 0.1s;

    &:hover {
      background-color: color.adjust(#0BA9DB, $lightness: -10%);
      border-color: color.adjust(#0BA9DB, $lightness: -10%);
    }
  }
}
