.marketplace-stores-list-search-bar {
  left: 50%;
  margin-right: auto;
  position: absolute;
  transform: translateX(-50%);
  width: 800px;

  .Search-Body {
    padding-bottom: 0;
  }

  .marketplace-stores-list-search-bar-container {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    flex-flow: row;
    gap: 30px;
    margin-bottom: 5px;
    padding: 15px;

    .marketplace-stores-list-search-bar-advanced-search-button {
      border-color: #E53140;
      color: #E53140;
    }
    
    .artp-search-bar {
      button {
        background-color: #E53140;
      }      
    }
    
    form {
      flex: 1;
    }

    @media(max-width: 992px) {
      flex-flow: column;
      gap: 15px;
    }
  }

  @media(max-width: 992px) {
    width: 95%;
  }
}
