$header-top-height: 37px;

.artp-Header-container {
  --header-top-height: 37px;

  box-shadow: 0 -6px 10px 3px #ccc;
  display: flex;
  flex-direction: column;
  height: var(--header-height, 131px);
  left: 0;
  margin-bottom: 1px;
  right: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 47;

  &.mobile-header {
    max-width: 100vw;

    &.height-100 {
      height: 100vh;
    }
  }

  .flex-filler {
    flex: 1;
  }

  .artp-Header-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media print {
    position: relative;
  }

}
