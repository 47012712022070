@use '../../../../../../../assets/stylesheets/constants';

ul.subscriptions-list-cards-modules-store {
  li {
    background-image: url('#{constants.$image-source}subscription/check-store.svg');

    ul {
      li {
        background-image: url('#{constants.$image-source}subscription/li-pro.svg');
      }
    }
  }
}
