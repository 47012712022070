@use '../../../../assets/stylesheets/artp_colors';

.artdbweb-lot-Indicator-Table {
  .positive {
    color: artp_colors.$alert-success;
  }
  
  .negative {
    color: artp_colors.$alert-danger;
  }
}
