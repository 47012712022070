.account-lots-lists-add-to-list-action-buttons,
.account-lots-lists-add-to-list-button {
  display: flex;
  justify-content: center;

  button {
    align-items: center;
    display: flex;

    .add-button-svg-icon {
      height: 18px;
    }
  }

  .account-lots-lists-create-button-modale {
    width: 400px;
  }
}

.account-lots-lists-add-to-list-action-buttons {
  @media (max-width: 991px) {
    justify-content: space-between;
  }
}
