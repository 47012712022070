.search-helpers-advanced-button {
  background: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
  outline: none;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  transition: background ease-out 100ms 0ms;

  &:hover {
    background-color: #eee;
  }

  .search-helpers-advanced-button-label {
    margin-left: 10px;
  }

  &.blue-btn {
    background: #c01b88;
    border: 1px solid #c01b88;
    color: #fff;

    &:hover {
      background: #286090;
      border: 1px solid #204d74;
    }
  }
}
