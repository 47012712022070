@use '../../../../assets/stylesheets/artp_colors';

.CookieConsentManagement {
  button {
    border-radius: 0;
    margin-bottom: 10px;
    padding: 15px;
  }
  
  .row {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
    padding: 10px;

    .col {
      text-align: justify;

      h3 {
        align-items: center;
        display: flex;
        flex-flow: row;
        
        .flex-filler {
          flex: 1 1 auto;
        }
        
        .opener {
          cursor: pointer;
          font-size: 12px;
          margin-left: auto;
          margin-right: 15px;
          margin-top: 8px;
          
          .fa {
            margin-left: 5px;
          }
        }
      }
      
      .details {
        transition: all linear 0.2s;
      }
    }

    &:not(:first-of-type):not(:last-of-type) {
      border-top: 1px solid #eee;
    }
  }
}
