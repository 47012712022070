.homepage-marketplace {
  --content-width: calc(100vw - 110px);
  position: relative;
  height: 635px;
  margin-top: 65px;

  @media (min-width: 768px) {
    --content-width: calc((var(--container-width) / 5) + var(--container-width) * 0.3);
    height: 655px;
  }

  @media (min-width: 992px) {
    --content-width: calc((var(--container-width) / 5) + var(--container-width) * 0.2);
    height: 675px;
  }

  .container {
    position: relative;
    height: 100%;
  }

  .left-bg {
    position: absolute;
    left: 0;
    height: 100%;
    width: calc((100vw - var(--container-width)) / 2 + var(--content-width));
    background-color: #e53140;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    @media(min-width: 768px) {
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  }

  .content-data {
    position: absolute;
    width: 100%;
    bottom: 100px;

    @media (min-width: 768px) {
      bottom: 105px;
    }

    @media (min-width: 992px) {
      bottom: 130px;
    }

    @media (min-width: 1200px) {
      bottom: 115px;
    }

    #carousel {
      --marg: 7%;
      --small-card-width: 300px;
      --small-card-height: 200px;
      --card-width: 86%;
      --card-height: 300px;
      --gap: 20px;
      --translateXSelected: 7%;
      --translateXPrev: calc((var(--small-card-width) + var(--gap) + (var(--card-width) / 2) - 50vw) * -1);
      --translateXNext: calc(var(--small-card-width) + var(--translateXPrev) + (var(--gap) * 2) + var(--card-width));

      position: relative;
      height: var(--card-height);
      overflow: hidden;
      
      @media(min-width: 768px) {
        --marg: calc((100vw - var(--container-width)) / 2);
        --small-card-width: 400px;
        --small-card-height: 240px;
        --card-width: 600px;
        --card-height: 360px;
        --gap: 20px;
        --translateXSelected: calc(var(--marg) + (var(--container-width) / 2) - (var(--card-width) / 2));
      }

      div {
        position: absolute;
        transition: transform 0.5s, left 0.5s, z-index 0s, height 0.5s, width 0.5s;
        opacity: 1;

        .over {
          cursor: pointer;
          height: 100%;
          width: 100%;
          position: absolute;
          z-index: 1000;
          top: 0;
          left: 0;
        }

        &.card {
          left: 0;
          background-repeat: no-repeat;
          background-size: cover;
          width: var(--card-width);
          padding: 25px 15px 15px;
          display: flex;
          flex-flow: column;
          align-items: center;
          height: var(--card-height);
          border-radius: 10px;
          z-index: 2;

          p {
            transition: font-size 0.5s, margin-top 0.5s;
            font-size: 16px;
            font-weight: 300;
            color: #fff;
            text-align: center;
            margin: 30px 0 15px;
            padding: 0 15px;

            @media(min-width: 768px) {
              font-size: 20px;
            }
          }

          h2 {
            transition: font-size 0.5s, font-weight 0.5s;
            color: #fff;
            font-weight: 500;
            font-size: 32px;
            line-height: 50px;
          }

          .marketplace-action {
            transition: bottom 0.5s;
            position: relative;
            text-align: center;
            width: 100%;
          }
        }

        &.prev,
        &.next,
        &.prevLeftSecond,
        &.nextRightSecond {
          &.card {
            width: var(--small-card-width);
            height: var(--small-card-height);
            z-index: 1;

            h2 {
              font-weight: 500;
              font-size: 30px;
            }

            p {
              font-size: 12px;
              margin-top: 10px;
              padding: 0 10px;
            }
          }
        }

        &.prev {
          transform: translateY(50px) translateX(var(--translateXPrev));
        }

        &.selected {
          transform: translateY(0) translateX(var(--translateXSelected));
        }

        &.next {
          transform: translateY(50px) translateX(var(--translateXNext));
        }

        &.prevLeftSecond {
          transform: translateX(calc(var(--small-card-width) * -2));
        }

        &.nextRightSecond {
          transform: translateX(calc(100vw + var(--small-card-width) * 2));
        }
      }
    }
  }

  .all {
    position: absolute;
    left: calc(var(--content-width) - 250px);
    bottom: 35px;
  }

  .content-buttons {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 5px;
    right: 10px;
    top: 165px;

    @media(min-width: 768px) {
      right: 75px;
      top: 135px;
    }

    @media(min-width: 992px) {
      right: 185px;
      top: 125px;
    }

    @media(min-width: 1200px) {
      top: 140px;
      right: 285px;
    }

    .artp-btn {
      border-radius: 25px;
      z-index: 1;
      padding: 0;
      width: 50px;
      height: 50px;
    }
  }

  .content-header {
    color: #fff;
    height: 100%;
    position: absolute;
    width: calc(var(--content-width) - 15px);

    h2 {
      font-style: normal;
      font-weight: 300;
      font-size: 32px;
      margin-top: 30px;

      @media(min-width: 992px) {
        margin-top: 30px;
      }

      @media(min-width: 1200px) {
        margin-top: 40px;
      }
    }

    p {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 25px;

      @media(min-width: 768px) {
        margin-top: 25px;
        max-width: 450px;
      }

      @media(min-width: 992px) {
        line-height: 30px;
        margin-top: 10px;
        max-width: 450px;
      }

      @media(min-width: 1200px) {
        margin-top: 25px;
      }

    }
  }
}
