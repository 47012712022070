.auctioneers-past-sales-sale {
  $cover-size: 200px;

  display: flex;
  flex-flow: row;
  margin-bottom: 40px;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }

  .infos {
    display: flex;
    flex: 1;
    flex-flow: column;
    overflow: hidden;
    white-space: nowrap;

    div {
      font-size: 14px;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.filler {
        flex: 1;
      }

      &.no-result,
      &.no-fineart,
      &.stats {
        font-style: italic;
        margin-bottom: 10px;
      }
    }
  }
}
