@use '../../../../../../assets/stylesheets/constants';

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.search-universal-skeletons-large {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  flex-flow: column;

  @media(min-width: 768px) {
    flex-flow: row;
  }
  
  .skeleton-block {
    display: flex;
    column-gap: 15px;
    flex: 1;
    justify-content: center;
    padding: 15px;

    div.animate {
      animation: skeleton-loading 1s linear infinite alternate;
      border-radius: 10px;
    }

    .picto {
      height: 100px;
      width: 90%;

      @media(min-width: constants.$main-viewport-desktop-width) {
        height: 165px;
        width: 100%;
      }

      @media(min-width: 1200px) {
        height: 200px;
        width: 235px;
      }
    }

    .text {
      flex: 1;
      display: none;
      
      @media(min-width: 992px) {
        display: block;
      }

      .sub-text {
        margin-bottom: 25px;
        height: 20px;

        &.sub-text-70 {
          width: 70%;
        }

        &.sub-text-30 {
          width: 30%;
        }

        &.sub-text-50 {
          width: 50%;
        }
      }
    }
  }
}
