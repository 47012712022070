.Inquiries {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  .Inquiries-text {
    font-size: 12px;
  }
  
  .Inquiries-value {
    color: var(--color1);
    fill: var(--color1);
    font-size: 16px;
    font-weight: bold;
  }
  
  .Inquiries-delay {
    align-items: center;
    display: flex;
    margin-left: 60px;

    @media(max-width: 425px) {
      margin-left: 10px;
    }
    
    .Inquiries-delay-picto {
      height: 60px;
      width: 60px;
    }
  }
}
