@use "sass:color";
@use '../../../../../../../assets/stylesheets/artp_colors';

.account-confirm-identity-field-component-document-selector {
  margin-bottom: 15px;
  position: relative;

  .delete {
    background-color: artp_colors.$danger-bg;
    border: solid 1px artp_colors.$danger-border;
    color: artp_colors.$white;
    cursor: pointer;
    font-size: 20px;
    height: 30px;
    position: absolute;
    right: -1px;
    text-align: center;
    top: -1px;
    transition: all linear 0.2s;
    width: 30px;
    z-index: 1;

    &:hover {
      background-color: artp_colors.$danger-hover;
      border: solid 1px artp_colors.$danger-border-hover;
      color: color.adjust(artp_colors.$white, $lightness: -40%);
    }
  }
}
