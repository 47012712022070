@use '../../../../assets/stylesheets/artp_colors';

.account-lots-lists-create-button-modale .lots-lists-button,
button.lots-lists-button {
  background: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  transition: background ease-out 100ms 0ms;
  white-space: nowrap;

  img {
    margin-right: 5px;
  }

  &:hover {
    background-color: #f1f1f1;
    font-weight: normal;
  }

  &[disabled] {
    border: 1px solid #ccc;
    color: #999;

    &:hover {
      cursor: not-allowed;
      font-weight: normal;
    }
  }

  &.primary:not(.standalone) {
    background-color: artp_colors.$primary-bg;
    border-color: artp_colors.$primary-border;
    color: artp_colors.$white;

    &:hover {
      background-color: artp_colors.$primary-hover;
      border-color: artp_colors.$primary-border-hover;
    }
  }

  &.standalone {
    align-items: center;
    display: flex;

    .add-button-svg-icon {
      height: 18px;
    }
  }
}
