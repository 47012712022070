@use '../../../../../assets/stylesheets/artp_colors';
@use '../../../../../assets/stylesheets/constants';

.search-universal-input {
  background-color: #fff;

  @media(min-width: 768px) {
    padding-bottom: 15px;
  }

  @media (min-width: 992px) {
    padding: 15px 0;
  }

  .input {
    padding: 15px;
    position: relative;

    .icon {
      position: absolute;
      right: 25px;
      bottom: 20px;
      font-size: 16px;
      cursor: pointer;

      @media (min-width: 768px) {
        font-size: 20px;
        bottom: 25px;
      }
    }

    .magnifying-glass {
      cursor: pointer;
      position: absolute;
      left: 12px;
      top: 13px;
      font-size: 23px;
      color: #c01b88;
      height: 25px;
      width: 25px;

      @media (min-width: 768px) {
        left: 14px;
        top: 16px;
        height: 35px;
        width: 35px;
      }
    }

    input {
      border: solid 1px #c9c9c9;
      border-radius: 10px;
      color: #c01b88;
      font-weight: 300;
      font-size: 16px; // for safari on iOS
      height: 45px;
      line-height: 49px;
      outline: none;
      padding: 0 15px 0 40px;
      text-overflow: ellipsis;
      width: 100%;

      @media (min-width: 768px) {
        padding: 0 15px 0 60px;
        font-size: 30px;
        height: 67px;
      }

      @media (min-width: 992px) {
        font-size: 32px;
      }

      &::placeholder {
        color: #c01b88;
        font-weight: 300;
        opacity: 1;
      }
    }
  }

  .comment {
    font-weight: 400;
    font-size: 12px;
    color: #555;
    padding: 0 15px 15px;
  }
}
