@use '../../../../assets/stylesheets/artp_colors';

.search-params {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: 5px 0;
  padding-left: 10px;

  .selection-group {
    display: flex;
    flex-flow: row;
  }

  .clear-all {
    cursor: pointer;
    margin-right: 10px;
  }

  .search-param {
    background-color: artp_colors.$gray-light;
    border-radius: 0.25em;
    color: artp_colors.$gray-darker;
    cursor: pointer;
    display: inline;
    font-size: 75%;
    margin-bottom: 5px;
    margin-right: 10px;
    padding: 0.2em 0.6em 0.3em;
    text-align: center;
    white-space: nowrap;

    .fa {
      color: artp_colors.$gray-darker;
      display: inline-block;
      margin-left: 3px;
    }

    &.hovered {
      background-color: artp_colors.$gray;
    }
  }
}
