.accordion {
  &.chevron {
    width: 15px;
    transition: transform 250ms ease-out;
    
    &.down {
      transform: rotate(0);
    }

    &.up {
      transform: rotate(180deg);
    }

    &.right {
      transform: rotate(270deg);
    }

    &.left {
      transform: rotate(90deg);
    }
  }
}
