.ails-landingpage-header-contact {
  flex: 1;
  
  p {
    font-size: 16px;
    
     &.name {
       display: block;
       
       @media(min-width: 768px) {
         display: none;
       }
     }
  }
    
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 10px;
  }
}
