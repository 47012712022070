.flip-container {
  position: relative;

  &:hover {
    .card {
      &.rotate {
        transform: rotateY(180deg);
      }
    }
  }

  .card {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
    transform-style: preserve-3d;

    & > div {
      &:first-child {
        position: absolute;
        height: 100%;
        width: 100%;
        background: lightgreen;
        backface-visibility: hidden;
      }

      &:last-child {
        position: absolute;
        height: 100%;
        width: 100%;
        backface-visibility: hidden;
        transform: rotateY(-180deg);
      }
    }
  }
}
