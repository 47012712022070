@use '../../../../../assets/stylesheets/artp_colors';

.store-Stats {
  --color1: #04328c;
  --color1-rgb: 4, 50, 140; // to use with opacity: rgba(var(--color1-rgb), 0.1)
  --color2: #1da4b2;
  --color2-rgb: 29, 164, 178;

  padding-bottom: 50px;
  position: relative;

  .calc {
    background-color: #000;
    height: 100%;
    left: 0;
    opacity: 0.75;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .flex-container-space-between {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .icon-btn {
    background-color: transparent;
    color: var(--color1);
    cursor: pointer;
    font-size: 2rem;
    height: 38px;
    padding: 0.5rem;
    transition: filter 0.2s, background-color 0.2s, color 0.2s;
    width: 38px;

    &.active {
      background-color: var(--color1);
      color: #fff;
    }

    &:not(.active):hover {
      filter: grayscale(75%) brightness(75%);
    }

    &:active {
      filter: grayscale(50%) brightness(50%);
    }
  }

  .error {
    background-color: artp_colors.$alert-warning-bg;
    border-radius: 5px;
    color: artp_colors.$alert-warning;
    margin-right: -25px;
    margin-left: -25px;
    padding: 10px;

    > p {
      margin: 14px 0;
    }
  }
}
