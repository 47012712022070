.artp-flipswitch-container {
  align-items: center;
  display: inline-flex;
  padding: 5px;
}

.artp-flipswitch-container .artp-flipswitch {
  background-color: rgb(231, 154, 170);
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  margin: 0;
  position: relative;
  /*text-indent: -9999px;*/
  transition: all 0.2s;
  vertical-align: middle;
  width: 35px;
}

.artp-flipswitch-container .artp-flipswitch::after {
  background-color: #fff;
  border-radius: 9px;
  content: '';
  height: 14px;
  left: 2px;
  position: absolute;
  top: 2px;
  transition: all 0.2s;
  width: 15px;
}

.artp-flipswitch-container .artp-flipswitch:not(.disabled):hover {
  filter: brightness(110%);
}

.artp-flipswitch-container .artp-flipswitch:active::after {
  width: 15px;
}

.artp-flipswitch-container .artp-flipswitch.undetermined {
  background-color: #888;
}

.artp-flipswitch-container .artp-flipswitch.undetermined::after {
  left: 50%;
  transform: translateX(-50%);
}

.artp-flipswitch-container .artp-flipswitch.disabled {
  cursor: not-allowed;
  filter: grayscale(100%);
}

.artp-flipswitch-container .artp-input-label {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  margin: initial;
  margin-left: 5px;
}

.artp-flipswitch-container .artp-input-label.disabled {
  cursor: not-allowed;
  filter: brightness(115%);
}

.artp-flipswitch-container .input-hidden:checked + .artp-flipswitch,
.artp-flipswitch-container .input-hidden[value='true'] + .artp-flipswitch {
  background-color: rgb(142, 206, 165);
}

.artp-flipswitch-container .input-hidden:checked + .artp-flipswitch::after,
.artp-flipswitch-container .input-hidden[value='true'] + .artp-flipswitch::after {
  left: calc(100% - 5%);
  transform: translateX(-100%);
}

artp-flipswitch-container.light .artp-input-label {
  font-weight: 100;
  margin-top: 5px;
}
