.marketplace-reporting {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  p.legend {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .form-group {
    display: flex;
    align-items: center;

    &.top {
      align-items: flex-start;
    }

    label {
      text-align: right;
      font-size: 16px;

      &.required:after {
        content: ' *';
      }
    }
  }

  .validate {
    margin: 40px 0;
    text-align: center;

    button {
      font-size: 18px;
    }
  }

  .checkboxes {
    font-size: 16px;
    margin: 30px 0;
    display: grid;
    grid-template-columns: 15px 1fr;
    row-gap: 15px;

    div {
      input {
        margin-top: 6px;
      }

      &.label {
        color: #000;
        font-size: 16px;
        font-weight: normal;
        white-space: normal;
        text-align: left;
        line-height: 1.3em;
        cursor: pointer;
      }
    }
  }

  &.step-2 {
    h1 {
      margin-bottom: 60px;
    }

    p {
      font-size: 16px;
    }
  }
}
