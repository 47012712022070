.account-messages-list {
  .header {
    border-bottom: solid 1px #f1f1f1;
    display: flex;
    flex-flow: row;
    height: 55px;
    justify-content: space-between;
    margin-bottom: 0;
    padding-left: 15px;

    .title {
      font-size: 24px;
      margin-right: 20px;
      padding-top: 7px;
    }

    .pagination {
      margin: 10px 0;
    }
  }
}
