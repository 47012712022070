@use "sass:color";
@use '../../../../assets/stylesheets/artp_colors';

.sales-add-to-calendar {
  position: relative;

  button {
    background-color: #fff;
    border: 0;
    color: artp_colors.$link-blue;
    left: 5px;
    outline: none;
    padding: 0;
    position: absolute;

    &:hover {
      color: artp_colors.$link-blue-hover;
      text-decoration: underline;
    }

    &::-moz-focus-inner {
      border: 0;
    }

    &:focus {
      border: 0;
      outline: 0 !important;
    }

    .fa {
      margin-right: 5px;
    }
  }

  .popup-wrapper {
    background-color: #fff;
    border: solid 1px #ccc;
    box-shadow: 2px 2px 5px #555;
    padding: 15px;
    padding-bottom: 40px;
    position: absolute;
    z-index: 100;

    .links {
      display: flex;
      flex-flow: column;
      font-weight: normal;
      text-align: center;

      a {
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 5px;
        text-decoration: none;
        transition: all linear 0.25s;

        &:hover {
          background-color: #ccc;
          color: color.adjust(#337ab7, $lightness: -50%);
        }
      }
    }

    .close-popup-wrapper {
      background-color: #337ab7;
      bottom: -1px;
      color: #fff;
      cursor: pointer;
      left: 0;
      padding: 10px;
      position: absolute;
      text-align: center;
      width: 101%;
    }

    h1 {
      font-size: 24px;
      margin-top: 0;
    }
  }
}
