@use '../../../../../../assets/stylesheets/constants';

.ails-info-testimonials-block {
  margin-top: 60px;
  display: flex;
  justify-content: center;

  .testimonial {
    max-width: 500px;

    .icon {
      background-image: url("#{constants.$image-source}ail/ail-guillemets-bleu.svg");
      background-repeat: no-repeat;
      background-position: top left;
      background-size: 40px;
      margin-bottom: 15px;
      height: 40px;
    }

    .testimonial-info {
      font-style: italic;
      font-size: 16px;
    }

    .testimonial-contact {
      font-weight: bold;
      font-size: 16px;
      margin: 15px 0;
      height: 30px;
    }
  }
}
