.ails-landingpage-sales {
  --ails-landingpage-sale-spacing: 50px;
  
  margin-bottom: var(--margin-bottom-block);
  padding:  var(--padding-block);

  .container {
    display: flex;
    flex-flow: column;
    margin-bottom: calc(var(--margin-bottom-block) - var(--ails-landingpage-sale-spacing));

    .all:first-of-type {
      margin-top: 0;
    }
  }
}
