@use "sass:color";

@use '../../../../assets/stylesheets/artp_colors';

.components-common-alert {
  align-items: center;
  background-color: rgba(235, 235, 235, 0.75);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  
  .message {
    background-color: artp_colors.$white;
    box-shadow: 2px 2px 5px #ccc;
    max-width: 370px;
    padding: 25px 50px 70px 25px;
    position: relative;
    text-align: justify;
    
    .button {
      bottom: 5px;
      position: absolute;
      right: 5px;
      
      button {
        background-color: artp_colors.$white;
        border: 0;
        font-weight: bold;
        
        &:hover {
          background-color: color.adjust(artp_colors.$white, $lightness: -5%, $space: hsl);
        }
      }
    }
  }
}
