.account-lots-lists {
  .actions {
    display: flex;
    flex-flow: row;
    margin-bottom: 10px;
    padding-top: 5px;

    div {
      padding: 5px;

      &.fill {
        flex: 1;
      }

      &.actions-select {
        width: 40px;
      }

      span {
        cursor: pointer;

        &:hover {
          font-weight: 600;
        }
      }
    }
  }

  .lots-list {
    border-top: solid 1px #ccc;
    display: flex;
    flex-flow: row;

    .lots-list-select {
      align-self: center;
      padding: 5px;
      width: 40px;

      .artp-checkbox-container > label {
        margin-bottom: 0;
      }
    }

    .lots-list-details {
      display: flex;
      flex: 1;
      flex-flow: column;
      padding: 5px;

      .lots-list-label {
        font-weight: 600;
      }
    }

    .lots-list-pdf {
      padding: 5px;
      width: 215px;

      .account-lots-lists-pdf-link {
        margin-top: 3px;
      }
    }
  }
}
