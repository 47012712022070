.marketplace-classified-images {
  img {
    max-width: min(450px, calc(100% - 20px));
    width: auto !important;
  }

  @media(max-width: 767px) {
    img {
      max-width: min(450px, calc(100% - 50px));
    }

    .slick-prev {
      left: 0;
    }

    .slick-next {
      right: 0;
    }
  }

  .slick-dots li button::before {
    font-size: 12px;
  }

  .slick-list {
    .slick-track {
      align-items: center;
      display: flex;
      flex-flow: row;

      .slick-slide {
        div {
          text-align: center;
        }
      }
    }
  }
}
