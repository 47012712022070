.artp-Header-Favorites {
  align-self: stretch;
  align-items: center;
  color: inherit;
  display: flex;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  > img {
    height: 12px;
    margin-right: 5px;
    margin-top: -2px;
  }
}
