.sales-contacts-modale {
  .artp-modal-content {
    @media(min-width: 370px) {
      min-width: 350px;
    }
  }

  .artp-modal-header {
    h2 {
      font-size: 24px;
      margin: 10px 0;
      padding: 0;
    }
  }

  h1 {
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  p {
    font-size: 16px;
    margin: 0 0 15px;

    .fa {
      margin-right: 5px;
    }
  }
}
