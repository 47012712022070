.lots-list-details {
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-flow: column;
  padding: 10px;

  a {
    color: #000;
    text-decoration: none;
  }

  .lots-list-label {
    font-weight: 600;
  }

  &:hover {
    box-shadow: 2px 2px 5px #ccc;
  }
}

