@use '../../../../../../../../assets/stylesheets/artp_colors';
@use '../../../../common/stylesheets/constants';

.subscriptions-list-theme-physicproduct-oneday {
  position: relative;
  
  .annotate-content {
    background-color: #fff;
    box-shadow: 2px 2px 5px #555;
    padding: 15px 30px 15px 15px;
    position: absolute;
    width: 325px;
    z-index: 1;
    
    .annotate-details {
      position: relative;
      text-align: left;
      width: 100%;

      .close-details {
        height: 50px;
        padding: 5px;
        position: absolute;
        right: -30px;
        text-align: center;
        top: -15px;
        width: 50px;
        z-index: 2;
      }
    }
  }
}
