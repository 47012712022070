.lot-list-breadcrumb {
  display: inline-flex;
  flex-flow: row;
  font-size: 12px;
  margin: 15px 0;

  .breadcrumb-item {
    margin-right: 7px;
  }

  .breadcrumb-separator {
    margin-right: 7px;

    &::after {
      content: '/';
    }
  }
}
