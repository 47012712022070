.marketplace-classified-actions {
  border: solid 1px #c9c9c9;
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  margin-top: 30px;
  padding: 5px;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    margin: 20px 0;
    text-transform: uppercase;
  }

  h2 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin: 10px 0 25px;
    text-align: center;
  }

  .statute {
    color: #525252;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;

    .value {
      font-weight: normal;
    }
  }
}
