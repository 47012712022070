@use '../../../../../../../assets/stylesheets/artp_colors';
@use '../../../common/stylesheets/constants';

.subscriptions-list-theme-physicproduct {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  height: 185px;
  margin: 10px 5px;
  max-width: calc(100% / 2);

  .filler {
    flex: 1;
  }

  .old-price,
  .price {
    .currencie {
      font-size: 70%;
    }
  }

  .old-price {
    color: constants.$main-gray;
    font-size: 14px;
    font-weight: bold;
    height: 20px;
    text-align: center;
    text-decoration: line-through;
  }

  .price {
    color: constants.$main-price;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  .duration {
    align-items: center;
    align-self: center;
    color: constants.$main-gray;
    display: flex;
    flex-flow: column;
    font-size: 14px;
    font-weight: 600;
    height: 70px;
    justify-content: center;
    margin: 10px 0;
    max-width: 155px;
    text-align: center;

    p {
      margin-bottom: 5px;

      &.annotate {
        font-size: 12px;
      }
    }
  }

  .users-count {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .select {
    align-items: center;
    display: flex;
    justify-content: center;

    .artp-checkbox-container {
      padding: 0;

      label {
        font-weight: 600;
        padding-left: 10px;

        .svg-container .svg-checkbox {
          stroke-width: 1px;

          &.svg-checkbox-unchecked {
            stroke-dasharray: 49, 87.4;
          }
        }
      }
    }
  }
}
