.price-index-chart {
  .tab-content .icon-btn {
    $icon-color: rgb(51, 102, 204); //bootstrap color

    background-color: transparent;
    color: $icon-color;
    cursor: pointer;
    font-size: 2rem;
    height: 38px;
    padding: 0.5rem;
    transition: filter 0.2s, background-color 0.2s, color 0.2s;
    width: 38px;

    &.active {
      background-color: $icon-color;
      color: #fff;
    }

    &:not(.active):hover {
      filter: grayscale(75%) brightness(75%);
    }

    &:active {
      filter: grayscale(50%) brightness(50%);
    }
  }

  .no-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filters {
    .filter {
      width: 250px;
    }
  }

  .price-index-select {
    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      padding: 4px;
    }

    .react-select__control {
      min-height: 34px;
    }
  }

}
