@use '../../../../../assets/stylesheets/constants';

.search-universale-section-header {
  h3 {
    font-weight: 400;
    font-size: 32px;
    display: grid;
    grid-template-areas:
      'title title'
      'count all';
    color: #000;
    column-gap: 15px;
    row-gap: 5px;
    align-items: baseline;
    padding: 0 15px 15px;
    margin-top: 0;

    @media(min-width: constants.$main-viewport-desktop-width) {
      margin: 0 30px 10px;
      padding: 15px;
    }

    @media (min-width: 992px) {
      grid-template-areas: 'title all';
    }

    .no-underline {
      color: #000;
    }

    .title {
      font-size: 22px;
      grid-area: title;

      @media (min-width: 768px) {
        font-size: 32px;
      }

      @media (min-width: 992px) {
        display: flex;
        flex-flow: row;
        align-items: baseline;
        column-gap: 15px;
      }
    }

    .count {
      grid-area: count;
      font-weight: 400;
      font-size: 14px;
    }

    .all {
      grid-area: all;
      white-space: nowrap;
      flex: 1;
      text-align: right;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #5e636e;
    }
  }
}
