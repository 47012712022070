@use '../../../../../../../assets/stylesheets/artp_colors';

.myartprice-common-quickfilter {
  .array-artists-link {
    cursor: pointer;
    padding: 2px 5px 2px 10px;
    transition: background-color 0.5s ease-in-out;

    .fa {
      margin-right: 5px;
    }
  }
  
  .myartprice-common-quickfilter-header {
    border-bottom: 1px solid artp_colors.$gray-eee;
    padding: 7px 0;

    a {
      color: #000;
      cursor: pointer;
      float: right;
      text-decoration: underline;
    }
  }

  .search-quick-filter {
    border-bottom: 1px solid artp_colors.$gray-eee;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .fa-table {
      color: #999;
    }

    .box-header {
      color: #888;
      cursor: pointer;
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      padding-left: 15px;

      @media(min-width: 992px) {
        padding-left: 5px;
      }

      .fa {
        color: #999;
        margin-left: 5px;
        transition: all 0.5s ease;
      }
    }

    .filter-and-facets {
      overflow: hidden;
      transition: max-height 0.5s ease;
      padding-left: 15px;
      
      @media(min-width: 992px) {
        padding-left: unset;
      }
      
      .filter {
        padding: 5px;
      }

      .facets {
        height: calc(100% - 85px);
        margin-top: 5px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 5px;

        .facet {
          align-items: center;
          border-radius: 3px;
          cursor: pointer;
          display: flex;
          flex-flow: row;
          justify-items: center;
          padding: 2px 5px 2px 10px;
          transition: background-color 0.5s ease-in-out;

          &.bc {
            font-style: italic;
            color: #AAA;
          }
          
          &:hover {
            background-color: #c9c9c9;
            z-index: 10;
          }

          &.loading {
            cursor: inherit;

            &:hover {
              background-color: inherit;
            }
          }

          .check {
            margin-right: 5px;
            width: 15px;

            .fa.loading {
              cursor: wait;
            }
          }

          .name {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            z-index: 10;
            
            
          }

          .count {
            font-size: 12px;
            margin-left: 5px;
            text-align: right;
            width: 20px;
            z-index: 10;
          }
        }
      }
    }
  }
}
