.LoadingOverlay {
  background: rgba(255, 255, 255, 0.75);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 46;

  .LoadingOverlay-Spinner {
    align-items: flex-start;
    height: auto;
    position: absolute;
    top: min(100px, 50%);
    transform: translateY(-50%);
  }

  &.on-body {
    position: fixed;

    .LoadingOverlay-Spinner {
      top: 50vh;
    }
  }
}
