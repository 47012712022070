.artists-artist-filters-display {
  &.common-dropdown {
    &.open {
      background-color: var(--color-active-or-hover) !important;
    }

    .title {
      font-size: 12px;

      button {
        color: #000;

        .title-text {
          align-items: center;
          display: inline-flex;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .fa {
          display: none;
        }
      }
    }

    .dropdown {
      top: 55px;

      &::before {
        background: var(--color-active-or-hover);
        content: '';
        display: block;
        height: 20px;
        position: absolute;
        right: 50px;
        top: -10px;
        transform: rotate(45deg);
        width: 20px;
      }

      .artp-checkbox-container {
        .check-box-checked-square {
          &::before {
            background-color: var(--color-active-or-hover);
          }
        }
      }

      .actions {
        display: flex;
        justify-content: space-evenly;
        margin-top: 50px;

        button {
          width: 40%;

          &.cancel {
            background-color: #fff;
          }

          &.ok {
            background-color: var(--color-active-or-hover);
          }
        }
      }
    }
  }
}
  

