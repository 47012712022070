.payments-navigation {
  margin: 30px 0;

  .container {
    display: flex;
    //column-gap: 15px;
    //place-content: space-evenly;

    .logo {
      cursor: pointer;
      width: 190px;
      margin-right: 50px;
      display: flex;

      img {
        width: 100%
      }
    }

    .step {
      display: flex;
      flex: 1;
      align-items: center;

      .label {
        color: #ccc;
        font-size: 22px;
        font-weight: 300;
      }

      .icon {
        margin-left: 5px;
        display: flex;
        font-size: 22px;
        border-radius: 30px;
        border: solid 1px #ccc;
        justify-content: center;
        color: #ccc;
        align-items: center;
        font-weight: 300;
        height: 50px;
        width: 50px;
      }
      
      &.hidden {
        display: none;
      }

      &.active {
        .label {
          color: #000;
        }

        .icon {
          color: #fff;
          background-color: #4D09A5;
          border-color: #4D09A5;
        }
      }

      &.checked {
        .label {
          color: #000;
        }

        .icon {
          color: #000;
          border-color: #000;
        }
      }
    }
  }
}
