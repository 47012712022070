.common-accordion {
  --transitionLength: 0.5s;
  --transitionTiming: ease;

  .accordion-item {
    border: solid 1px #ccc;
    border-top: 0;
    overflow: hidden;

    &:first-of-type {
      border-top: solid 1px #ccc;
    }


    .accordion-animation-wrapper {
      display: grid;
      grid-template-rows: 0fr;
      overflow: hidden;
      transition: grid-template-rows var(--transitionLength) var(--transitionTiming);

      .accordion-animation {
        min-height: 0;

        .accordion-transform-wrapper {
          transform: translateY(-100%);
          visibility: hidden;
          transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s var(--transitionLength) var(--transitionTiming);

          .accordion-content {
            padding: 0 15px 15px;
          }
        }
      }
    }

    &.accordion-item-active {
      .accordion-animation-wrapper {
        grid-template-rows: 1fr;

        .accordion-transform-wrapper {
          transform: translateY(0);
          visibility: visible;
          transition: transform var(--transitionLength) var(--transitionTiming), visibility 0s linear;
        }
      }
    }

    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .title {
      cursor: pointer;
      padding: 25px 15px;

      h3 {
        margin: 0;
        font-size: 20px;

        .fa {
          color: #999;
          margin-right: 10px;
        }
      }
    }
  }
}
