.TotalAccess {
  .total-access-horizontal-bar {
    padding: 15px 0 25px;
    
    .common-dropdown {
      background: #efefef !important;
    }
  }

  .view-by {
    display: inline-block;
    margin-top: 15px;

    span {
      cursor: pointer;
      margin: 0 10px;

      &.active {
        cursor: auto;
        font-weight: bold;
      }
    }
  }
}
