.auctioneers-sale-geo {
  margin-top: 30px;
  width: 100%;

  .map {
    height: 380px;
    overflow: hidden;

    .spinner {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-top: 180px;
      width: 100%;
    }
  }
}
