.reports-report-summary-header {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 5px;
  border-right: 0 !important;
  
  div {
    font-weight: 600;
    padding: 5px 0;
    
    &:first-child {
      text-align: center;
    }
  }
}
