.chronological-chart {
  .tab-content {
    padding-top: 40px;

    .icon-btn {
      $icon-color: rgb(51, 102, 204); //bootstrap color

      background-color: transparent;
      color: $icon-color;
      cursor: pointer;
      font-size: 2rem;
      height: 38px;
      padding: 0.5rem;
      transition: opacity 0.2s, background-color 0.2s, color 0.2s;
      width: 38px;

      &.active {
        background-color: $icon-color;
        color: #fff;
      }

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 0.4;
      }
    }

    .recharts-layer.clickable .recharts-bar-rectangle,
    .recharts-active-dot {
      opacity: 1;
      transition: opacity 0.15s;

      &:hover {
        cursor: auto; // pointer; // TODO: a remettre pour les clicks
        opacity: 0.6;
      }
    }
  }
}
