.artists-follow {
  position: absolute;
  background-color: #fff;
  padding: 15px;
  z-index: 100;
  border: solid 1px #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }
}
