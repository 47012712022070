.artists-artist-header {
  background-color: var(--common-gray);
  padding: 15px 30px;

  .container {
    display: flex;
    flex-flow: column;

    @media (min-width: 992px) {
      flex-flow: row;
    }

    .artists-artist-header-content {
      flex: 1;

      .action {
        font-weight: 600;
        text-transform: uppercase;
      }

      .title {
        display: flex;
        flex-flow: row;

        h1 {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -0.02em;
          line-height: 1.2;
          margin-bottom: 0;
        }
      }

      .dates {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.02em;
        line-height: 24px;
      }

      .figures {
        column-gap: 10px;
        display: grid;
        grid-template-areas:
          'col1'
          'col2'
          'col3'
          'col4';
        margin-top: 15px;
        position: relative;
        row-gap: 10px;
        text-align: center;

        @media (min-width: 450px) {
          flex-wrap: nowrap;
          grid-template-areas:
            'col1 col2'
            'col3 col4';
        }

        @media (min-width: 768px) {
          flex-wrap: nowrap;
          grid-template-areas: 'col1 col2 col3 col4';
        }

        @media (min-width: 1600px) {
          display: flex;
          flex-flow: row;
        }

        .figure {
          background-color: #fff;
          column-gap: 10px;
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          padding: 15px 10px;
          position: relative;

          @media (min-width: 530px) {
            padding: 5px 10px;
          }

          @media (min-width: 768px) {
            padding: 5px 10px 15px;
          }

          @media (min-width: 1200px) {
            padding: 5px 10px;
          }

          @media (min-width: 1600px) {
            margin-right: 10px;
            width: 235px;
          }

          &.ca {
            grid-area: col1;
          }

          &.rank {
            grid-area: col2;
          }

          &.sales {
            grid-area: col3;
          }

          &.country {
            grid-area: col4;
          }

          .year {
            bottom: 0;
            font-size: 8px;
            font-weight: 300;
            left: 10px;
            letter-spacing: 0.04em;
            position: absolute;
            text-transform: lowercase;
          }

          .legend {
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0.04em;
            line-height: 15px;
            text-align: left;
          }

          .number {
            display: flex;
            flex-flow: column;
            font-size: 20px;
            font-weight: 400;
            text-align: right;

            .number-legend {
              font-size: 10px;
              font-weight: 300;
              text-align: left;
            }
          }
        }
      }

      .biography {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -0.01em;
        line-height: 25px;
        margin: 15px 0;

        .bio {
          strong,
          b {
            font-weight: 500;
          }

          a {
            color: #000;
            text-decoration: underline;
            font-weight: 500;

            &:hover {
              color: #666;
            }
          }
        }

        .more {
          color: #000;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0.04em;
          line-height: 16px;
        }

        .collpased {
          height: 0;
          overflow: hidden;
        }

        .biographyoverview {
          margin-bottom: 15px;
          max-height: 105px;
          overflow: hidden;
          padding: 15px 0;
          transition: max-height linear 0.2s;

          &.open {
            margin-bottom: 0;
            max-height: 1000px;
          }
        }
      }

      .aliases {
        font-size: 12px;
        font-style: italic;
        padding: 10px 0 0;
      }

      .links {
        display: flex;
        flex-flow: column;
        font-size: 12px;
        justify-content: space-between;
        margin-bottom: 15px;
        padding: 15px 0;

        @media (min-width: 600px) {
          flex-flow: row;
        }

        a {
          color: #000;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
