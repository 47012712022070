.artp-Header-bottom {
  align-items: stretch;
  background-color: #fff;
  display: grid;
  grid-template-areas:
    'menu-mobile logo menu-desktop filler-1 subscribe search'
    'menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small';
  grid-template-columns: auto auto auto 1fr auto auto;
  grid-template-rows: auto min-content;
  height: calc(var(--header-height, 131px) - var(--header-top-height));
  padding: 0 calc(30px + var(--removed-body-scroll-bar-size, 0px)) 0 30px;
  z-index: 2;

  @media (max-width: 768px) {
    grid-template-areas:
      'menu-mobile filler-2 logo menu-desktop filler-1 subscribe search'
      'menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small menu-desktop-small';
    grid-template-columns: auto 1fr auto auto 1fr auto auto;
  }
}
