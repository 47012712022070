@use '../../../../assets/stylesheets/constants';

.ails-landingpage {
  --margin-bottom-block: 30px;
  --block-background-color: #f5f5f5;
  --padding-block: 40px 0;
  
  @media(min-width: 768px) {
    --margin-bottom-block: 60px;
  }
  
  .bg {    
    &:nth-child(even) {
      background-color: var(--block-background-color);
    }
  }
  
  
  .back-to-top-button {
    float: right;
    background-color: #0E637C;
    position: sticky;
    transform: translateY(-10px);
  }

  .logo-sticky {
    background: url(#{constants.$image-source}ail/lp-background.png) no-repeat;
    background-size: cover;
    color: #fff;
    position: sticky;
    z-index: 41;
    font-size: 36px;
    font-weight: 400;
    top: 101px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    text-align: center;

    @media(min-width: 768px) {
      top: 131px;
    }
    
    @media(min-width: 1350px) {
      top: 107px;
    }
  }

  h2 {
    text-align: center;
    font-size: 28px;
    margin: 0 0 30px;
    font-weight: 500;
    
    @media(min-width: 768px) {
      font-size: 32px;
      margin: 0 0 60px;
    }
  }


  a.btn {
    --transition-delay: 0.25s;
    transition: var(--transition-delay) ease-in-out background-color, var(--transition-delay) ease-in-out color;

    &:hover {
      background-color: #5E636E;
      color: #fff;
    }
  }
}
