.ails-landingpage-records-record {
  --image-size: 270px;

  @media(min-width: 992px) {
    --image-size: 215px;
  }

  @media(min-width: 1200px) {
    --image-size: 270px;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .record {
    background-color: #fff;
    border: solid 1px #7C0F3D;
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-template-areas: 'repro'
      'legend';

    @media(min-width: 540px) {
      grid-template-areas: 'legend repro';
      grid-template-columns: calc(100% - var(--image-size)) var(--image-size);
    }
    
    .encart {
      color: #fff;
      position: absolute;
      top: 0;
      text-transform: uppercase;
      background-color: #7C0F3D;
      padding: 2px 5px;
      text-align: center;
      font-size: 11px;
      width: 200px;
    }

    .legend {
      grid-area: legend;
      display: flex;
      flex-flow: column;
      padding: 15px;
      align-self: center;

      div {
        &.price {
          font-weight: 600;
          font-size: 30px;
          margin-bottom: 20px;
        }

        &.auctioneer {
          font-weight: 600;
          margin-bottom: 20px;
        }

        &.dt {
          display: flex;
          margin-top: 15px;
          position: absolute;
          bottom: 0;
          right: 0;

            @media (min-width: 540px) {
              position: relative;
            }
          
          .block {
            color: #fff;
            padding: 10px 20px;
            display: flex;
            flex-flow: column;
            background-color: var(--color-ail);
            align-items: baseline;
            column-gap: 5px;


            
            div {
              &.part1 {
                text-align: center;
                font-size: 30px;
                font-weight: 600;
                line-height: 30px;
              }

              &.part2 {
                font-size: 10px;
              }
            }
          }
        }

        &.location {
          margin-bottom: 20px;
        }

        &.artist {
          margin-bottom: 5px;
          font-weight: 600;
        }

        &.title {
          font-style: italic;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 65px);

          @media (min-width: 540px) {
            margin-bottom: 20px;
          }
        }

        &.technique {
          margin-bottom: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    .repro-container {
      grid-area: repro;
      align-self: center;
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: 30px 15px 15px;

      @media (min-width: 540px) {
        padding: 15px;
      }
      
      .repro {
        min-height: var(--image-size);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
}
