.lots-lot-sale {
  display: flex;
  flex-flow: column;

  .sale-title {
    font-style: italic;
  }

  .sale-title {
    display: inline;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .sale-charity {
    display: inline;
    margin-bottom: 5px;
  }

  .sale-date {
    display: inline;
    margin-bottom: 5px;
  }

  .sale-auctioneer {
    display: inline;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .sales-add-to-calendar {
    .react-add-to-calendar__button {
      background-color: #fff;
      border: 0;
    }
  }

  .sale-warning {
    display: block;
    margin-bottom: 5px;
  }

  .sales-add-to-calendar {
    display: inline;
    white-space: nowrap;
  }

  .address {
    margin: 0 0 5px;
  }
}
