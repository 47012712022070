.artp-Header-subscribe-link {
  align-items: center;
  color: #4d09a5;
  display: flex;
  grid-area: subscribe;
  padding: 0 20px;
  text-decoration: none;

  &:hover {
    color: #4d09a5;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    display: none !important;
  }

  > img {
    margin-right: 5px;
    height: 16px;
  }

  > span {
    white-space: nowrap;
  }
}
