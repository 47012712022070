.account-lots-lists-list-show {
  .header {
    text-align: center;

    h1 {
      align-items: baseline;
      display: flex;
      flex-flow: row;
      font-weight: 600;
      justify-content: center;
    }
  }

  .actions {
    align-items: center;
    display: inline-flex;
    flex-flow: row;
    gap: 15px;
    margin: 15px 0;
    width: 100%;

    .select-all {
      padding: 5px;
      width: 30px;

      .artp-checkbox-container {
        .check-box {
          margin: 0;
        }
      }
    }

    &.first-line {
      margin: 15px 0 0;
    }

    &.second-line {
      gap: 5px;
      margin: 5px 0 15px;
    }
  }

  .paginate {
    width: 100%;

    .artp-ui-pagination {
      place-content: center;
    }
  }

  .lot {
    display: flex;
    flex-flow: row;

    @media(max-width: 720px) {
      flex-direction: column;
      margin-top: 20px;
    }

    .col-1 {
      align-items: center;
      align-self: center;
      display: flex;
      flex-flow: column;
      width: 30px;

      @media(max-width: 720px) {
        align-self: flex-start;

        .count {
          margin-right: 5px;
        }
      }

      .count {
        font-size: 16px;
        font-weight: bold;
      }

      .artp-checkbox-container {
        .check-box {
          margin: 0;
        }
      }
    }

    .col-2 {
      border-bottom: 2px solid #eee;
      flex: 1;

      .lots-tile-access-wide:hover {
        box-shadow: none;
      }
    }
  }

  .back-to-top-container {
    bottom: 25px;
    float: right;
    margin-bottom: 10px;
    position: sticky;
    transform: translateX(10px);

    @media(min-width: 1300px) {
      transform: translateX(60px);
    }
  }
}
