.account-left-bar {
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;

  .action {
    padding: 9px;
    padding-left: 5px;
  }

  .section {
    cursor: pointer;
    padding: 6px;
    transition: background-color 0.125s linear;

    &:hover {
      background-color: #ccc;
    }

    &.active {
      font-weight: bold;
    }
  }
}
