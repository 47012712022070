.shortcuts {
  .artp-input-label {
    all: unset !important;
  }

  .search-artist {
    margin-top: 5px;
  }
  
  .filters {
    border: 0;
  }
}
