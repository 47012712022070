@use '../../../../../assets/stylesheets/constants';

.x-landingpage-x-row6 {
  position: relative;
  background-color: #FAFAFA;

  @media(min-width: 768px) {
    background-color: #FFF;
  }

  .container {
    z-index: 1;
    position: relative;

    @media(min-width: 768px) {
      height: 429px;
    }

    h2 {
      text-align: center;
      margin-bottom: 50px;
    }
  }

  .bg {
    height: 460px;
    width: 249px;
    position: absolute;
    display: none;

    @media(min-width: 768px) {
      display: block;
    }

    &.bg-left {
      left: 0;
      background: url('#{constants.$image-source}landing/lp-arrow-left.jpg') no-repeat;
    }

    &.bg-right {
      right: 0;
      background: url('#{constants.$image-source}landing/lp-arrow-right.jpg') no-repeat;
    }
  }

  .container {
    padding-top: 60px;
    padding-bottom: 60px;
    
    @media(min-width: 768px) {
      padding-bottom: 0;
    }
  }
}
