.folder-mobile {
  padding: 10px 20px;
  transition: transform 250ms ease-out;
  width: 98vw;

  &:hover {
    background-color: #f1f1f1;

    .folder-title {
      color: inherit;
    }
  }

  .folder-title {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .chevron {
      padding: 5px;
      width: 2rem;
    }
  }
}

.folder-content-mobile {
  position: absolute;
  transform: translateX(100%);
  transition: transform 250ms ease-out;
  width: 100vw;

  > ul {
    padding: 0;
  }

  &.selected {
    transform: translateX(0);
  }

  .folder-content-mobile-title {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding: 10px;

    .chevron {
      cursor: pointer;
      padding: 5px;
      width: 2.5rem;
    }
  }

  .artp-Header-Menu-Folder-Item {
    padding: 15px 30px 15px 20px;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
