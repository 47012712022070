.account-messages-message {
  padding-left: 15px;

  .header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .title {
    font-size: 24px;
  }

  .body {
    border: solid 1px #ccc;
    border-radius: 5px;
    display: flex;
    flex-flow: row wrap;
    font-size: 16px;
    height: 100%;
    margin-top: 15px;
    min-height: 250px;
    padding: 15px;
  }
}
