.homepage-auto-promo {
  background-color: #233A84;
  border-radius: 10px;
  color: #fff;
  display: flex;
  flex-flow: column;
  height: 280px;
  justify-content: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  padding: 0 5px;

  @media(min-width: 768px) {
    height: 240px;
  }

  @media(min-width: 992px) {
    height: 231px;
  }

  @media(min-width: 1200px) {
    height: 280px;
  }

  .l0 {
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 15px;
    text-transform: uppercase;

    @media(min-width: 768px) {
      font-size: 14px;
      line-height: 24px;
    }

    @media(min-width: 992px) {
      font-size: 16px;
      line-height: 27px;
    }
  }

  .l1 {
    align-self: center;
    font-size: 20px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 20px;
    max-width: 400px;

    @media(min-width: 768px) {
      font-size: 20px;
      line-height: 27px;
    }

    @media(min-width: 992px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  .l2 {
    align-self: center;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    div {
      border-bottom: solid 1px #fff;
      display: grid;
      grid-template-columns: 1fr 30px;
      width: 345px;
      padding-bottom: 5px;

      button {
        background-color: transparent;
        border: 0;
        font-weight: 700;
        outline: 0;
        width: 100%;
      }

      input {
        background-color: transparent;
        border: 0;
        outline: 0;
        width: 100%;

        &::placeholder {
          color: #fff;
        }
      }
    }
  }
}
