.artists-artist {
  .lot {
    .img-container {
      --container-size: 230px;

      //display: flex;
      height: var(--container-size);
      //justify-content: center;
      line-height: var(--container-size);
      position: relative;
      text-align: center;
      width: var(--container-size);

      .fake-img {
        color: #fff;
        font-size: 10px;
        line-height: 14px;
        padding: 0 30px;
        position: absolute;
        top: 41%;

        p {
          margin-bottom: 5px;
        }
      }

      img {
        max-height: 100%;
        max-width: 100%;

      }

      .no-img {
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: center;
        width: 100%;

        p {
          color: #b4b2ab;
          font-weight: 300;
        }

        img {
          max-height: 100px;
        }
      }
    }
  }
}
