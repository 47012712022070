.payments-grid-layout {
  position: relative;

  .bg {
    display: none;
    right: 0;
    top: 0;
    position: absolute;
    background-color: #333333;
    border-top-left-radius: 20px;
    height: 100%;
    width: calc(((100vw - var(--container-width)) / 2) + var(--container-width) * 0.3);

    @media(min-width: 768px) {
      width: calc(((100vw - var(--container-width)) / 2) + var(--container-width) * 0.5);
    }

    @media(min-width: 992px) {
      width: calc(((100vw - var(--container-width)) / 2) + var(--container-width) * 0.4);
    }

    @media(min-width: 1200px) {
      width: calc(((100vw - var(--container-width)) / 2) + var(--container-width) * 0.35);
    }
  }

  .container {
    position: relative;

    .container-grid {
      display: grid;
      grid-template-columns: 100%;
      grid-template-areas: 'right' 
        'left';

      @media(min-width: 768px) {
        grid-template-areas: 'left right';
        grid-template-columns: 50% 50%;
      }

      @media(min-width: 992px) {
        grid-template-columns: 60% 40%;
      }

      @media(min-width: 1200px) {
        grid-template-columns: 65% 35%;
      }

      .left {
        grid-area: left;

        @media(min-width: 768px) {
          padding-right: 30px;
        }
      }

      .right {
        grid-area: right;

        @media(min-width: 768px) {
          padding-left: 30px;
        }
      }
    }
  }
}
