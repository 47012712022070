.artp-Header-Badge {
  align-items: center;
  background-color: #e31717dd;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  font-weight: 600;
  height: 18px;
  justify-content: center;
  line-height: 18px;
  min-width: 18px;
  pointer-events: none;
  text-align: center;

  &.disabled {
    background-color: #cccd;
    color: #000;
  }

  &.invisible {
    opacity: 0;
  }
}
