.auctioneers-sale-common-lot {
  margin-bottom: 45px;
  position: relative;
  width: 210px;

  .img {
    align-items: center;
    display: flex;
    height: 170px;
    justify-content: center;
    margin-bottom: 15px;

    .fa {
      color: #ccc;
      font-size: 70px;
    }

    img {
      max-height: 160px;
      max-width: 160px;
    }
  }

  .artist {
    margin-bottom: 5px;
    text-align: center;
  }

  .category {
    text-align: center;
  }

  .prices {
    text-align: center;
  }

  .number {
    background-color: #f1f1f1;
    border-bottom-right-radius: 10px;
    color: #555;
    display: inline-block;
    font-size: 12px;
    left: 0;
    letter-spacing: 0;
    line-height: 1;
    min-width: 10px;
    padding: 3px 7px 5px 3px;
    position: absolute;
    top: -20px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .push {
    font-size: 14px;
    font-style: italic;
    margin-top: 10px;
    text-align: center;
  }

  .title {
    margin-bottom: 5px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
