.marketplace-search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  
  .marketplace-horizontal-bar {
    margin-top: -42px;
    
    @media(min-width: 850px) {
      width: 800px;      
    }
    
    .searchbar-container {
      background-color: rgb(255, 255, 255);
      border-radius: 20px;
      padding: 15px;
      box-shadow: 0 4px 8px #0003;
      display: flex;

    @media(max-width: 450px) {
      flex-flow: column;
      row-gap: 15px;
      
      .search-helpers-advanced-button {
        max-width: 200px;
        align-self: end;
      }
    }
      
      .search-item {
        flex: 1;
      }

      .search-helpers-advanced-button {
        margin-left: 40px;
      }

      input {
        outline: 0;  
      }
      
      button {
        background-color: #E53140;
        color: #fff;

        &.blue-btn {
          background-color: #fff;
          border: solid 1px #E53140;
          color: #E53140;
        }
      }
    }

    .filters-container {
      display: flex;
      column-gap: 15px;
      padding: 0 15px;
      align-items: center;
      
      .common-dropdown {
        border: solid 1px #ccc;
        border-radius: 5px;
        padding: 5px 10px;
        max-height: 34px;
        
        .title {
          font-size: 14px;
        
          button {
            color: #000 !important;
          }
        }
      }

      .searchbar-count {
        width: auto;
        text-align: left;
        font-size: 16px;
        color: #666;
        padding-bottom: 0;
        min-height: auto;
      }
    }
  }
  
  &.no-header {
    .marketplace-horizontal-bar {
      margin-top: 20px;
    }
  }
}
