@use '../../../../../assets/stylesheets/constants';

.subscriptions-list-numbers {
  position: relative;
  height: 285px;
  background-color: #fafafa;
  margin-top: 60px;
  display: none;

  @media(min-width: 768px) {
    display: block;
  }

  .bg {
    height: 100%;
    width: 285px;
    position: absolute;

    &.bg-left {
      left: 0;
      background: url('#{constants.$image-source}subscription/bg-arrow-left.jpg') no-repeat;
    }

    &.bg-right {
      right: 0;
      background: url('#{constants.$image-source}subscription/bg-arrow-right.jpg') no-repeat;
    }
  }


  .container {
    display: flex;
    flex-flow: column;
    row-gap: 40px;
    position: relative;

    h2 {
      text-align: center;
      font-size: 32px;
      line-height: 32px;
      font-weight: 600;
    }

    .numbers {
      display: grid;
      grid-template-columns: 33% 33% 33%;

      div {
        text-align: center;
        display: flex;
        flex-flow: column;
        row-gap: 15px;

        .number {
          font-size: 36px;
          line-height: 32px;
          font-weight: bold;

          &.number-1 {
            color: #4D09A5;
          }

          &.number-2 {
            color: #8F2DC3;
          }

          &.number-3 {
            color: #B438CB;
          }
        }

        .label {
          font-size: 20px;
          line-height: 32px;
          color: #000;
        }
      }
    }
  }
}
