.ails-landingpage-header {
    display: grid;
    row-gap: 20px;
    column-gap: 30px;
    margin-bottom: var(--margin-bottom-block);
    grid-template-columns: 1fr;
    grid-template-areas: 'logo'
      'address'
      'contact'
      'description';

    @media(min-width: 480px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'address contact'
      'description description'
      'logo logo';
    }

    @media(min-width: 768px) {
      grid-template-areas: 'img logo'
      'img address'
      'img contact'
      'description description';
    }

    @media(min-width: 992px) {
      grid-template-areas: 'img address contact'
      'img description description'
      'img logo logo';
      grid-template-columns: 370px 1fr  1fr;
    }

    .logo {
      grid-area: logo;
      flex: 1;

      img {
        max-height: 30px;
      }
    }

    .header-address {
      grid-area: address;
    }

    .header-contact {
      grid-area: contact;
    }

    .c1 {
      grid-area: img;
      width: 100%;
      display: none;

      @media(min-width: 768px) {
        display: block;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      }
    }

    .c2 {
      grid-area: description;
      padding-top: 20px;
      border-top: solid 1px #ccc;

      p {
        font-size: 16px;
      }
    }
  }
