.auctioneers-docs {  
  .no-access {
    align-items: center;
    background-color: #fafafa;
    border: 1px solid #eaeaea;
    border-radius: 3px;
    display: flex;
    flex-flow: column;
    font-size: 26px;
    height: 555px;
    justify-content: center;
    outline: none;
    padding: 15px;
    position: relative;
  }
}
