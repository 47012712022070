.artists-artist-breadcrumb {
  background-color: var(--common-gray);
  margin-bottom: 10px;

  ul.breadcrumb {
    background-color: var(--common-gray);
    list-style: none;
    padding: 0;

    li {
      display: inline;
      font-size: 9px;
      font-weight: 300;
      line-height: 24px;

      &::before {
        color: #000;
        content: '/\00a0';
        padding: 4px;
      }

      &:first-child {
        &::before {
          color: #000;
          content: '';
          padding: 0;
        }
      }

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
