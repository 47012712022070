.payments-platforms {
  .payment-error {
    align-items: center;
    background: #ff000011;
    border: 1px solid #ff0000;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 10px 10px 0 10px;

    .error-message {
      color: #ff0000;
      font-size: 16px;
      padding: 10px;
    }

    .payment-common-help {
      background: none;
    }
  }
}
