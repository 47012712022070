@use "sass:color";
.myartprice-lot {
  font-size: 16px;

  .bar {
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 15px;
    padding: 10px 0;

    .flex-filler {
      display: inline-block;
    }

    .filters-container {
      padding-left: 0;
      padding-right: 0;

      .common-dropdown {
        background: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 34px;
        padding: 5px 10px;
        text-align: left;
        text-decoration: none;
        transition: background ease-out 100ms 0ms;

        .title {
          font-size: 16px;

          button {
            color: #000;
            font-size: 14px;
          }
        }
      }

      &.grid-override {
        display: grid;
        grid-template-areas:
          'left nav list void right'
          'navmobile navmobile navmobile navmobile navmobile';
        grid-template-columns: auto auto auto 1fr auto;

        .back {
          grid-area: left;
        }

        .standalone {
          grid-area: list;
        }

        .buttons-navigation {
          grid-area: nav;
          margin-right: 5px;

          @media (max-width: 991px) {
            grid-area: navmobile;
            margin-top: 10px;

            .prev {
              margin-left: 0;
            }
          }
        }

        .preferences {
          grid-area: right;
        }
      }
    }

    .back {
      background-color: #fff;
      border: solid 1px #ccc;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px 10px;
      text-decoration: none;
      transition: background-color 0.25s linear;

      &:hover {
        background-color: color.adjust(#fff, $lightness: -20%);
      }
    }
  }
}
