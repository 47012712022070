@use '../../../../assets/stylesheets/artp_colors';

.events-categories-title {
  font-size: 22px;
  position: relative;
  text-align: center;

  &::before {
    background-color: #333;
    border-bottom: 1px solid #333;
    content: '';
    height: 0;
    left: 0;
    position: absolute;
    top: 50%;
    width: 20%;
  }

  &::after {
    background-color: #333;
    border-bottom: 1px solid #333;
    content: '';
    height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 20%;
  }
}

.events-list {
  display: flex;
  row-gap: 15px;
  flex-wrap: wrap;

  h2 {
    font-size: 3rem;
  }

  h3 small span {
    font-size: 1.35rem;
  }
}


.not-partner-events {
  border-left: solid 1px #333;
  border-top: 0;

  height: 100%;
  max-height: 125em;
  min-height: 60vh;

  overflow-y: auto;

  &.no-border {
    border-left: 0;
  }

  .events-list {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    border-left: 0;
    border-top: solid 1px #333;
    margin-top: 2.5rem;
    min-height: 25vh;
  }
}
