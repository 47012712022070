.group {
    .payments-informations-form-tvaintra {
      display: flex;
      flex-flow: column;


      .tva-input {
        flex: 1;
      }

      .no-tva {
        width: 100%;
        margin-left: 20px;
        display: flex;
        flex-flow: row;
        column-gap: 5px;

        input {
          margin-top: 0;
          width: 15px !important;
          box-shadow: none !important;
        }

        label {
          padding-top: 5px;
          font-size: 12px;
          cursor: pointer;
          margin-left: 5px;
          align-content: center;
          font-weight: 300;
        }
      }
    }
  }
