.artists-artist-lots-estimate {
  .overlapping-content {
    .estimate-content {
      background: rgba(217, 211, 195, 0.2);
      padding: 15px;

      @media (min-width: 992px) {
        background: none;
        padding: 0 10px;
      }

      .content {
        margin: 10px 0;
      }
    }
  }
}
