.homepage-articles {
  .articles {
    .article {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 30px;
      border-bottom: 1px solid #CAC6BA;
      padding-bottom: 30px;
      margin-top: 30px;
      
      &:last-of-type {
        border: 0;
      }
      
      @media(min-width: 768px) {
        grid-template-columns: 200px 1fr;
      }
      
      @media(min-width: 992px) {
        padding-bottom: 50px;
        margin-top: 50px;
        grid-template-columns: 270px 1fr;
      }
      
      .image {
        align-self: center;
        display: none;
        justify-self: center;

        @media(min-width: 768px) {
          display: block;
        }
        
        img {
          max-width: 100%;
        }
      }

      .content {
        .dt {
          font-weight: 400;
          font-size: 12px;
          line-height: 125.2%;
          letter-spacing: 0.03em;
          color: #CAC6BA;
          margin-bottom: 10px;
        }

        .title {
          color: #1D1D1F;
          font-weight: 700;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 10px;
        }

        .excerpt {
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
          color: #5E636E;
          margin-bottom: 20px;
        }

        @media(min-width: 992px) {
          .title {
            font-size: 26px;
            line-height: 36px;
          }

          .excerpt {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
