.artp-Header-search-button {
  align-items: center;
  background-color: transparent;
  border: unset;
  color: #c01b88;
  display: flex;
  grid-area: search;
  padding: 0 5px;
  text-decoration: none;

  @media (max-width: 425px) {
    flex-direction: column;
  }

  > img {
    margin-right: 5px;
    height: 20px;
  }
}
