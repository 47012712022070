@use '../../../../../../../assets/stylesheets/artp_colors';

.arrow-container {
  $default-color: artp_colors.$success-bg;
  $default-border-color: artp_colors.$success-border;
  $active-color: artp_colors.$primary-bg;
  $arrow-spacing: -15px;
  $arrow-width: 25%;
  $arrow-size: 20px;

  $inner-height: calc(#{$arrow-size} * 1.414213562);
  $margin-left: calc(#{$arrow-size} * -1 / 2);
  $margin-top: calc((#{$inner-height} - #{$arrow-size}) / 2);

  display: flex;
  flex-flow: row;
  font-size: 12px;
  margin-left: $arrow-spacing;
  width: $arrow-width;

  @media (max-width: 768px) {
    margin-left: 0;
  }

  .inner {
    align-items: center;
    background-color: $default-color;
    border-bottom: solid 1px $default-border-color;
    border-top: solid 1px $default-border-color;
    color: #fff;
    display: flex;
    flex: 1;
    height: $inner-height;
    margin-left: $margin-left;
    padding-left: calc(#{$inner-height} / 2 + 10px);

    @media (max-width: 768px) {
      border: 0;
      border-bottom: solid 1px $default-border-color;
      border-right: solid 1px $default-border-color;
      border-top: solid 1px $default-border-color;
      height: auto;
      margin-left: 0;
      padding: 5px;
      text-align: center;

      .fa-hourglass-half {
        display: none;
      }
    }

    .fa {
      margin-right: 5px;
    }
  }

  .left,
  .right {
    border-bottom: solid 1px $default-border-color;
    border-right: solid 1px $default-border-color;
    height: $arrow-size;
    transform: rotate(-45deg);
    transform-origin: center;
    width: $arrow-size;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .left {
    background-color: #fff;
    margin-top: $margin-top;
  }

  .right {
    background-color: $default-color;
    margin-left: $margin-left;
    margin-top: $margin-top;
    z-index: 10;
  }

  &.comming {
    .inner {
      background-color: #fff;
      color: #000;
    }

    .left,
    .right {
      background-color: #fff;
    }
  }

  &.last {
    border-right: solid 1px $default-border-color;

    @media(max-width: 768px) {
      border-right: 0;
    }

    .right {
      display: none;
    }

  }

  &.first {
    margin-left: 0;

    .inner {
      margin-left: 0;
      padding-left: 5px;
    }

    .left {
      display: none;
    }
  }
}
