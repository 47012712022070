@use '../../../../../../assets/stylesheets/artp_colors';

.account-confirm-account-step {
  margin-left: 15px;

  .box {
    background: artp_colors.$gray-light;
    border: solid 1px artp_colors.$gray;
    color: artp_colors.$gray-darker;
    font-weight: bold;
    height: 38px;
    width: 38px;

    &.valid {
      background: artp_colors.$success-bg;
      border: solid 1px artp_colors.$success-border;
      color: #fff;
    }

    &.in-progress {
      background: artp_colors.$primary-bg;
      border: solid 1px artp_colors.$primary-border;
      color: #fff;
    }
  }
}
