@use '../../../assets/stylesheets/artp_colors';

.artdbweb-lot-Indicator {
  .title {
    font-size: 16px;
  }
  
  .legal {
    font-size: 12px;

    &.hidden-xs {
      margin-top: 145px;
    }
  }

  .no-access {
    border-bottom: 0;
    color: artp_colors.$gray-darker;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    top: 35px;
    width: 100%;
    z-index: 2;

    &.absolute {
      position: absolute;
    }
    
    @media(max-width: 767px) {
      background: #f1f1f1;
      width: calc(100% - 15px);
    }
  }

  .caret {
    margin-left: 5px;
    transition: transform 0.2s ease;
    
    &.inverse {
      transform: rotate(-180deg);
    }
  }
}
