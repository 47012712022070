@use '../../../../../../../assets/stylesheets/artp_colors';

.new-data-header {
  color: #888;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  margin-left: 15px;

  @media(min-width: 992px) {
    margin-left: 5px;
  }
}

.myartprice-common-verticalbar-content-datas {
  border-bottom: 1px solid artp_colors.$gray-eee;
  margin-bottom: 15px;
}
