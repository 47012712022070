@use "sass:color";

@use '../../../../assets/stylesheets/artp_colors';

.common-stylesheets-forms {
  $background-color-locked: #E5E3DD;
  $border-color-locked: #EFF0F6;
  $shadow-color-locked: #0D0A2C0F;

  $background-color-warning: #FFEBEE;
  $border-color-warning: #E57373;
  $shadow-color-warning: #E575350;

  $radius: 15px;

  h3 {
    margin-top: 60px;
    font-size: 16px;
    font-weight: 600;
  }

  .group {
    display: flex;
    flex-flow: row;
    margin: 10px 0;

    .cell {
      flex: 1;
      margin-right: 10px;
      position: relative;

      &.right {
        text-align: right;
      }
      
      .checkboxes {
        margin-bottom: 15px;
        display: grid;
        grid-template-columns: 25px 1fr;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        
        input {
          height: 15px;
          
          &.warning {
            width: auto;
            box-shadow: 0 1px 15px 0 red;
            background-color: white;
          }
        }
      }

      &.warning {
        &::after {
          content: '⚠';
          position: absolute;
          right: 10px;
          top: 5px;
          font-size: 1.6em;
          color: red;
        }

        .react-select__control {
          background-color: $background-color-warning;
          border: 1px solid $border-color-warning;
          box-shadow: 0 8px 25px 0 $shadow-color-warning;

          .react-select__single-value {
            color: #000;
          }

          .react-select__indicator {
            svg {
              color: transparent;
            }
          }
        }

        input {
          cursor: default;
          background-color: $background-color-warning;
          border: 1px solid $border-color-warning;
          box-shadow: 0 8px 25px 0 $shadow-color-warning;
          color: #000;
        }
      }

      &.locked {
        &::after {
          content: '✓';
          position: absolute;
          right: 10px;
          top: 7px;
          font-size: 1.6em;
          color: green;
        }

        .react-select__control {
          background-color: $background-color-locked;
          border: 1px solid $border-color-locked;
          box-shadow: 0 8px 25px 0 $shadow-color-locked;

          .react-select__single-value {
            color: #000;
          }

          .react-select__indicator {
            svg {
              color: transparent;
            }
          }
        }

        input {
          cursor: default;
          background-color: $background-color-locked;
          border: 1px solid $border-color-locked;
          box-shadow: 0 8px 25px 0 $shadow-color-locked;
          color: #000;
        }
      }


      &:last-of-type {
        margin-right: 0;
      }

      .react-select__control {
        height: 45px;
        border-radius: $radius;
        border: solid 1px #D9D3C3;
        box-shadow: 0 8px 25px 0 #0D0A2C0F;
        padding: 3px;
        width: 100%;

        .react-select__value-container {
          padding-left: 20px;
          font-size: 16px;

          input {
            padding: inherit;
          }

          .react-select__placeholder {
            font-size: 16px !important;
          }
        }
      }

      input {
        height: 45px;
        width: 100%;
        border-radius: $radius;
        padding: 10px 20px;
        border: solid 1px #D9D3C3;
        font-size: 16px;
        box-shadow: 0 8px 25px 0 #0D0A2C0F;
      }

      button {
        transition: all linear 200ms;
        padding: 10px 30px;
        font-size: 16px;

        &.link {
          color: artp_colors.$link-blue;
          background-color: transparent;
          border: 0;
          font-weight: 300;
          transition: color linear 200ms;

          &:hover {
            color: artp_colors.$link-blue-hover;
          }
        }

        &[type = submit] {
          border-radius: var(--button-radius);
          background-color: #4D09A5;
          color: #fff;

          &:disabled {
            opacity: 0.15;
            font-style: italic;
          }
          
          &:hover {
            background-color: color.adjust(#4D09A5, $lightness: -10%, $space: hsl);
            border-color: color.adjust(#4D09A5, $lightness: -10%, $space: hsl);
          }
        }
      }

      .input-button {
        display: flex;
        flex-flow: row;
        box-shadow: 0 8px 25px 0 #0D0A2C0F;
        border-radius: $radius;

        input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          box-shadow: none;
        }

        button {
          border-radius: 0;
          color: #000;
          background-color: #fff;
          padding-left: 15px;
          padding-right: 15px;
          border: solid 1px #D9D3C3;
          border-left: 0;

          &:last-of-type {
            background-color: #4D09A5;
            color: #fff;
            padding-left: 10px;
            padding-right: 15px;
            border-left: 0;
            border-right: 0;
            border-radius: 0 $radius $radius 0;
            box-shadow: none;

            &:hover {
              background-color: color.adjust(#4D09A5, $lightness: -10%, $space: hsl);
              border-color: color.adjust(#4D09A5, $lightness: -10%, $space: hsl);
            }
          }
        }
      }
    }
  }
}
