.Search-HorizontalBar {
  .search-params {
    margin-top: 15px
  }

  .artists-horizontal-bar {
    .container {
      .filters-container {
        display: flex;
        flex-flow: row;
        padding: 0;

        #advanced-search {
          @media (max-width: 767px) {
            span {
              display: none;
            }

            .fa {
              font-size: 2em;
            }
          }
        }

        .filler {
          flex: 1;
        }
      }
    }
  }
}
