.homepage-reports-thumbnail {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 180px;

  @media(min-width: 768px) {
    height: 280px;
  }

  img {
    max-width: 100%;
  }

  .desc {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    padding: 20px;
    flex-flow: column;
    height: 100%;

    @media(min-width: 768px) {
      font-size: 24px;
      padding: 80px 20px 20px;
    }

    @media(min-width: 992px) {
      padding: 40px 20px 20px;
    }

    @media(min-width: 1200px) {
      font-size: 22px;
      padding: 80px 50px 20px 20px;
    }

    p {
      flex: 1;
    }
  }
}
