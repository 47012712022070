.artists-artist-filters-icon {
  align-items: center;
  background-color: rgb(67, 64, 65);
  border-radius: 5px;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-left: 15px;
  padding: 5px;
  width: 35px;
}
