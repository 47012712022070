.artists-artist-lots-no-access-drawer-price {
  height: 120px;
  width: 120px;
  background-color: #fff;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-10deg);
  flex-flow: column;
  
  div {
    color: #000;
    font-size: 10px;
    font-weight: bold;

    &.price {
      color: #4d09a5;
      margin: -3px 0 -4px;
      font-size: 24px;
    }
  }
}
