.artp-Header-Dropdown-container {
  display: flex;
  position: relative;

  .artp-Header-Dropdown-button {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .artp-Header-Dropdown {
    --dpdwn-container-width: 10px;
    --dpdwn-container-left: 0;
    background: #fff;
    box-shadow: 0 3px 6px #00000029;
    margin-top: 1rem;
    min-width: 250px;
    position: absolute;
    z-index: 4;

    &::before,
    &::after {
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }

    &::after {
      border: 10px solid transparent;
      border-bottom-color: #dfded7;
      left: calc(50% - 10px);
    }

    &.normal {
      left: 0;
      margin-left: 50%;
      transform: translateX(-50%);
    }

    &.left {
      right: 0;

      &::after {
        left: auto;
        right: calc(var(--dpdwn-container-width) / 2 - 9px);
      }
    }

    &.right {
      left: 0;

      &::after {
        left: calc(var(--dpdwn-container-width) / 2 - 9px);
        right: auto;
      }
    }

    @media (max-width: 768px) {
      left: calc(50vw - var(--dpdwn-container-left) - var(--dpdwn-container-width) / 2) !important;
      margin-left: 50%;
      right: auto !important;
      transform: translateX(-50%);
      width: 100vw;

      &::after {
        left: calc(var(--dpdwn-container-left) + var(--dpdwn-container-width) / 2 - 10px) !important;
        right: auto !important;
      }
    }

    .header {
      background-color: #dfded7;
      color: #000;
      padding: 10px;
      text-align: center;
    }

    .content {
      border: #dfded7 1px solid;
    }

    .link-list {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .link-list-item {
      align-items: center;
      color: inherit;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      padding: 10px 30px 10px 20px;
      text-decoration: none;

      &:hover {
        background-color: #dfded7;
        text-decoration: none;
      }
    }
  }

  .mobile-opaque-background {
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
  }

  .desktop-transparent-filler {
    height: 15px;
    position: absolute;
    top: 100%;
    width: 100%;
  }
}
