@use "sass:color";

@use 'constants';
@use '../../../../../assets/stylesheets/constants' as constants2;

.card-container {
  position: relative;

  .card {
    display: flex;
    position: relative;
    flex-flow: column;
    border-radius: 20px;
    overflow: hidden;
    border: solid 1px;

    .infos {
      min-height: 135px;
      margin-top: 15px;
      display: flex;
      flex-flow: column;
    }

    .modules {
      padding: 15px;
      flex: 1;

      ul {
        margin-top: 15px;
        margin-left: -48px;

        li {
          position: relative;
          font-weight: 300;
          font-size: 16px;
          line-height: 25px;
          list-style-type: none;
          transition: all linear 0.3s;
          text-decoration: none;
          background-size: 15px;
          background-repeat: no-repeat;
          background-position: 0 5px;
          padding-left: 20px;
          cursor: pointer;

          @media(min-width: 425px) {
            margin-bottom: 15px;
          }

          @media(min-width: 768px) {
            cursor: default;
          }

          &.switch {
            background-image: none !important;
            padding-top: 30px;

            .artp-flipswitch-container {
              position: absolute;
              left: -9px;
              top: 0;

              .artp-input-label {
                font-weight: 300;
                font-size: 14px;
              }
            }
          }

          ul {
            margin-left: -55px;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s linear;

            @media(min-width: 768px) {
              max-height: none;
            }

            &.visible {
              max-height: 400px;
            }

            li {
              margin-bottom: 5px;
              padding-left: 20px;
              background-position: 5px 6px;
              background-size: 7px 7px;
              line-height: 24px;
              font-size: 14px;
              
              &:first-of-type {
                margin-top: 5px;
              }
            }
          }

          &.strike {
            background-image: none;
            text-decoration: line-through;

            .artp-input-label {
              font-weight: normal;
            }
          }

          ul {
            margin-top: 5px;
          }
        }


        &.active {
          li {
            font-weight: 500;

            &.switch {
              .artp-flipswitch-container {
                .artp-input-label {
                  font-weight: 500;
                }
              }
            }

            ul {
              li {
                font-weight: 300;
              }
            }
          }
        }

        img {
          width: 30px;
          position: absolute;
          margin-left: 5px;
          top: -10px;
        }

      }
    }

    &.disabled {
      color: #ccc !important;

      .modules {
        ul {
          li {
            color: #ccc !important;
            font-weight: normal !important;
            background-image: none !important;

            .artp-flipswitch-container {
              .artp-flipswitch {
                background-color: #4D09A5;

                &:after {
                  background-color: #ccc;
                }
              }

              .input-hidden:checked + .artp-flipswitch {
                background-color: #fff;
                border-color: #ccc;
              }

              .artp-input-label {
                font-size: 14px !important;
                font-weight: 300 !important;
              }
            }
          }
        }
      }
    }

    &.essential {
      border: solid 1px constants.$essential;
    }

    &.subscription.professional {
      border-color: constants.$professional;
    }

    &.store.professional {
      border-color: constants.$marketplace-professional;
        background-color: #f0ebf7;
        box-shadow: 3px 3px 10px color.adjust(constants.$marketplace-professional, $lightness: 50%, $space: hsl);
    }

    &.basics {
      border-color: constants.$basic;
    }

    &.premium {
      border-color: color.adjust(#f0ebf7, $lightness: -15%, $space: hsl);
      background-color: #f0ebf7;
      box-shadow: 3px 3px 6px color.adjust(#f0ebf7, $lightness: -15%, $space: hsl);
    }

    &.basicsstore {
      border-color: constants.$basicsstore;
    }

    &.artpricestore {
      border-color: constants.$store;
    }
  }
}
