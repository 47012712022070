.auctioneer-top-bar-link {
  &.dead-link {
    color: #ccc;
    padding: 15px;
  }

  &.link {
    color: #333;
    padding: 15px;
    text-decoration: none;
    transition: background-color 0.125s linear;

    &:hover {
      background-color: #ddd;
      text-decoration: underline;
    }

    &.active {
      background-color: #ccc;

      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }
}
