.marketplace-classified-actions-price {
  margin-bottom: 25px;
  position: relative;

  .classified-details-price-block {
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    .classified-details-price-label {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
