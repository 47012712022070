.payments-common-cart-summary-promocode {
  color: #000;
  display: flex;
  flex-flow: column;
  align-self: center;
  width: 100%;

  &.common-stylesheets-forms {
    @media(min-width: 768px) {
      margin: 0 0 30px;
    }

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: 300;
      
      @media(min-width: 768px) {
        font-size: inherit;
        font-weight: bold;
      }
    }
  }
}
