.payments-platforms-offline {
  .phones {
    margin-bottom: 40px;
    
    p {
      margin-bottom: 5px !important; 
      
      &::before {
        content: '> ';
      }

      &.countries {
        font-style: italic;
        font-size: 12px !important;
        
        &::before {
          content: '    ';
        }
      }
    }
  }
}
