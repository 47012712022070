.artists-artist-marketplace {
  --store-height: 120px;

  @media(min-width: 768px) {
    --height: 850px;
  }

  @media(min-width: 992px) {
    --height: 610px;
  }


  &.block {
    padding-top: 0 !important;
  }
}
