.artp-Header-MyAccount-button {
  align-items: center;
  display: flex;

  .logo-silhouette {
    height: 16px;
    margin-right: 5px;
    margin-top: -2px;
  }
}


// styling for removed scrollbar
.artp-Header-container .artp-Header-item.artp-Header-MyAccount-Dropdown {
  padding: 0 calc(25px + var(--removed-body-scroll-bar-size, 0px)) 0 25px;
}

// styling for layout empty
a.artp-Header-MyAccount-Dropdown {
  color: inherit;
}
