.artists-artist-lots-no-access-drawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: #DBCEED !important;
  
  @media(min-width: 768px) {
    max-width: 630px;  
  }
  
  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }
  
  .details {
    column-gap: 15px;
    display: flex;
    flex-flow: row;
    margin-top: 30px;
    
    .args {
      font-size: 15px;
      line-height: 23px;
    }
  }
  
  .links {
    flex-flow: column;
    margin-top: 30px;
    display: flex;
    column-gap: 15px;
    row-gap: 15px;

    @media(min-width: 768px) {
      flex-flow: row;
    }
  }
}
