@use "sass:color";
.subscriptions-list-table {
  margin-top: 60px;

  h2 {
    text-align: center;
    font-weight: normal;
    font-size: 32px;
    line-height: 42px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .ia-container {
    display: flex;
    column-gap: 5px;
    align-items: center;

    .ia {
      height: 30px;
      top: -5px;
      position: relative;
    }
  }

  table {
    width: 100%;
    margin-top: 40px;
    font-weight: 300;
    font-size: 14px;
    border-radius: 20px;

    $essential: #FBFBFA;
    $basics: #F4F3F1;
    $premium: #EFEEEB;
    $professional: #DEDEDE;
    $store: #F4F3F1;
    $basicsstore: #EFEEEB;

    .artp-flipswitch-container {
      .artp-flipswitch {
        &:before {
          top: 3px !important;
        }
      }

      .input-hidden:checked + .artp-flipswitch {
        &:before {
          top: -10px !important;
        }
      }
    }

    &.skeleton {
      width: 100%;
      height: 900px;
      display: flex;
      align-content: center;
      justify-content: center;
      background-color: #F4F3F1;
      border: #D9D3C3 solid 1px;

      .spin {
        color: #999;
      }
    }

    tr {
      border-bottom: solid 1px color.adjust($professional, $lightness: -5%);

      &:last-of-type {
        border-bottom: none;
      }

      td:first-child {
        display: flex;
        align-items: center;
      }
    }

    th {
      color: #000;
      font-weight: 600;
      font-size: 24px;
      width: 175px;
      position: relative;

      .head {
        display: flex;
        flex-flow: column;
        width: 100%;

        &.store {
          height: 150px;
        }

        .title {
          align-content: center;
          flex: 1;
        }
      }

      .ai {
        position: absolute;
        top: -25px;
        right: 0;

        img {
          height: 25px;
        }
      }

      .populaire {
        color: #233a84;
        border-radius: 15px;
        border: solid 1px #233a84;
        padding: 5px 0;
        background-color: #fff;
        font-size: 12px;
        position: absolute;
        top: -14px;
        left: 19%;
        right: 19%;
      }

      &.custom-round {
        border-top-left-radius: 20px;
        overflow: hidden;
      }

      .price {
        color: #000;
        font-size: 14px;
        font-weight: 300;
      }

      .artp-btn {
        width: 80%;
        padding: 10px;
        align-self: center;
        font-size: 14px;
      }
    }

    td,
    th {
      padding: 10px;
      text-align: center;

      &:first-of-type {
        text-align: left;
        width: auto;
      }

      &.essential {
        background-color: $essential;
      }

      &.professional {
        background-color: $professional;
      }

      &.premium {
        background-color: $premium;
      }

      &.basics {
        background-color: $basics;
      }

      &.basicsstore {
        background-color: $basicsstore;
      }

      &.artpricestore {
        background-color: $store;
      }
    }

    
    @mixin focus($className) {
      th,
      td {
        &.#{$className} {
          border-left: solid 1px #4e00a1;
          border-right: solid 1px #4e00a1;
        }
      }

      th.#{$className} {
        border-top: solid 1px #4e00a1;
      }

      tr:last-of-type {
        td.#{$className} {
          border-bottom: solid 1px #4e00a1;
        }
      }
    }
    
    &.subscription {
      @include focus(premium);
    }
    
    &.store {
      @include focus(professional);
    }

    .subscriptions-list-common-users {
      font-size: 16px;
      justify-content: left;
      font-weight: 600;

      .group {
        font-weight: normal;
      }
    }

    .subscriptions-list-common-period {
      max-width: 370px;

      .artp-btn {
        font-size: 16px;
      }
    }

    .upgrade {
      font-weight: 300;
      font-size: 16px;
      display: flex;
      align-items: center;

      &.disabled {
        color: #ccc;

        span {
          cursor: not-allowed;

          &.active {
            font-weight: normal;
          }
        }
      }

      .artp-flipswitch-container {
        margin-right: 10px;
      }

      span {
        cursor: pointer;
        width: 140px;

        &.active {
          font-weight: 600;
        }
      }
    }
  }
}
