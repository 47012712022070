@use "sass:color";
@use '../../../../../assets/stylesheets/constants';

.ails-landingpage-consignment {
  background-position: center center;
  background-size: cover;
  position: relative;
  height: 235px;
  margin-bottom: var(--margin-bottom-block);
  overflow: hidden;
  border: solid 1px #0F637C;

  .consignment {
    height: 100%;
    padding: 0;
    background-color: #f1f1f1;
    right: 0;
    position: absolute;
    background-image: url('#{constants.$image-source}landing/consignment.png');
    background-position: center center;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-flow: column;

    @media(min-width: 440px) {
      width: 400px;
    }

    h2 {
      text-align: center;
      background-color: #0F637C;
      color: #fff;
      font-size: 18px;
      margin: 0;
      padding: 15px 0;
    }

    p {
      flex: 1;
      font-size: 24px;
      display: flex;
      flex-flow: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      padding: 15px;
      text-align: center;
      
      .artp-btn {
        background-color: #0F637C;
        border-color: #0F637C;
        color: #fff;

        &:hover {
          background-color: color.adjust(#0F637C, $lightness: 15%);
          border-color: color.adjust(#0F637C, $lightness: 15%);
        }
      }
    }
  }
}
