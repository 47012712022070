.artists-artist-filters {
  border-bottom: solid 1px var(--color-active-or-hover);
  height: 60px;

  .container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: var(--container-width);
    padding: 0 15px;
    position: relative;
    width: 100%;

    .filter {
      align-items: center;
      border-right: solid 1px var(--color-active-or-hover);
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 300;
      height: 100%;
      justify-content: center;
      margin-right: 50px;
      width: 105px;
      z-index: 1;

      @media(min-width: 768px) {
        justify-content: left;
      }


      &.filter-menu {
        padding-right: 15px;
        width: 120px;
        display: none;

        &.filter-menu-visible {
          display: flex;
        }

        &::after {
          border-right: solid 2px #b5b5b5;
          border-top: solid 2px #b5b5b5;
          content: '';
          height: 11px;
          left: 110px;
          position: absolute;
          transform-origin: center;
          transition: transform 500ms ease-out;
          width: 11px;
        }

        &.next-hide {
          &::after {
            transform: rotate(-135deg);
          }
        }

        &.next-show {
          &::after {
            transform: rotate(45deg);
          }
        }
      }

      &:last-of-type {
        border-left: solid 1px var(--color-active-or-hover);
        border-right: 0;
        justify-content: flex-end;
        padding-left: 15px;
        width: auto;
      }
    }

    .filler {
      flex: 1;
    }
  }
}
