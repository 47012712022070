.subscriptions-list-cards-subscription-ia {
    z-index: 1;
    position: absolute;
    right: -15px;
    top: -25px;

    img {
      height: 40px;
      width: 40px;
    }
  }
