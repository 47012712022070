.ails-landingpage-sales-sale-lot {
  padding-top: 10px;
  background-color: #fff;
  display: flex;
  flex-flow: column;
  position: relative;
  height: 415px;
  text-align: center;

  a {
    color: #000;
    text-decoration: none;
  }

  .legend {
    display: flex;
    flex-flow: row;
    margin-top: 15px;


    .info-ail {
      font-size: 14px;
      flex: 1;
      width: 100%;

      .artist {
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .lot-title {
        font-style: italic;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 5px;
      }

      .technique {
        margin-top: 5px;
      }

      .estimate {
        margin-top: 20px;
        font-weight: 600;

        .title {
          color: #707070;
          padding-right: 10px;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  .repro {
    display: flex;
    justify-content: center;
    padding-top: 25px;

    img {
      max-height: 230px;
      max-width: 230px;
    }
  }
}

