.lots-lot-access {
  $default-font-size: 16px;
  font-size: $default-font-size;

  .content {
    display: flex;
    flex-flow: column;

    @media (min-width: 768px) {
      flex-flow: row;
    }

    .datas {
      flex: 1;

      .original-currency {
        color: #666;
        font-size: 0.9em;
        font-style: italic;
        font-weight: normal;
        margin-left: 5px;
      }

      .artist {
        font-size: 20px;
        margin-bottom: 0;
        margin-top: 20px;
      }

      .title {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        margin-bottom: 5px;
        margin-top: 20px;
      }

      .description {
        h2 {
          font-size: $default-font-size;
          line-height: 25px;
          margin-bottom: 0;
          margin-top: 2px;
        }

        h3 {
          font-size: $default-font-size;
          line-height: 25px;
          margin-bottom: 0;
          margin-top: 2px;
        }

        h4 {
          font-size: $default-font-size;
          line-height: 25px;
          margin-bottom: 0;
          margin-top: 2px;
        }
      }

      .prices {
        margin-top: 20px;

        .price {
          font-weight: bold;
        }

        .estimation {
          font-weight: bold;
        }
      }

      .details {
        margin-top: 20px;

        .details-title {
          font-weight: bold;
        }

        .block {
          margin-bottom: 5px;
          position: relative;
        }
      }

      .lot-url {
        margin-top: 2px;
      }

      .sale {
        margin-top: 20px;
      }
    }

    .reproductions {
      align-items: center;
      height: 500px;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 30px;
      position: relative;
      text-align: center;
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }

      img {
        flex-shrink: 0;
        max-height: 100%;
        max-width: 100%;
      }

      .img-nav {
        align-items: center;
        background-color: transparent;
        border-radius: 25px;
        color: #ccc;
        font-size: 2em;
        padding: 15px;
        position: absolute;
        transition: all 250ms linear;

        &:hover {
          background-color: #f1f1f1;
          color: #333;
          cursor: pointer;
        }

        &.img-nav-next {
          padding-left: 20px;
          padding-right: 25px;
          right: 0;
          top: 49%;
        }

        &.img-nav-previous {
          left: 0;
          padding-right: 20px;
          top: 49%;
        }
      }
    }
  }
}
