@use "sass:color";
.auctioneers-sale-lots-lot {
  font-size: 16px;

  .bar {
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 15px;
    padding: 10px 0;

    .back {
      background-color: #fff;
      border: solid 1px #ccc;
      border-radius: 3px;
      cursor: pointer;
      padding: 5px;
      transition: background-color 0.25s linear;

      &:hover {
        background-color: color.adjust(#fff, $lightness: -20%);
      }
    }
  }
}
