@use '../../../../../../../../assets/stylesheets/artp_colors';

.account-confirm-identity-field-component-document-selector-upload-placeholder {
  cursor: pointer;
  outline: 1px dashed #aaa;
  outline-offset: 0;
  padding: 5px;
  position: relative;

  &.disabled {
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: default;
    fill: #555;
    outline: 0;

    &:hover {
      background-color: #eee;
      fill: #555;
    }
  }

  &:hover,
  &.drag-active {
    fill: #4285f4;
    outline-color: #4285f4;
  }

  &.drag-accept {
    fill: #32cd32;
    outline-color: #32cd32;
  }

  &.drag-reject {
    fill: #f00;
    outline-color: #f00;
  }

  .uploader-icon {
    height: 32px;
    width: 100%;
  }

  .placeholder-text {
    color: #777;
    text-align: center;
  }

  .existing-url {
    .lnk {
      color: artp_colors.$link-blue;
      margin-top: 10px;
      text-align: center;

      &:hover {
        color: artp_colors.$link-blue-hover;
      }
    }

    .info {
      color: #777;
      font-style: italic;
      margin-top: 10px;
      text-align: center;
    }
  }

  .to-upload {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: -20px;
    width: 100%;

    .icon {
      align-items: center;
      background-color: #ccc;
      border-radius: 50%;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 50px;
    }
  }

  img {
    max-height: 200px;
    max-width: 100%;
    outline: none;
  }

  object {
    border: solid 1px #ccc;
    outline: none;
  }
}
