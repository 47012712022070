.artists-artist-adagp-or-copyright {
  background-color: #efefef;
  font-size: 13px;
  line-height: 18px;
  margin-top: 100px;
  padding: 15px;

  @media(min-width: 768px) {
    .container {
      padding-left: var(--block-width);
    }
  }

  .copyright {
    padding-left: 130px;
    margin-top: 15px;
  }

  .adagp {
    display: flex;
    flex-flow: row;

    .picto {
      align-self: center;
      width: 130px;

      img {
        width: 110px;
      }
    }

    .content {
      align-self: center;
      flex: 1;

      p {
        margin-bottom: 0;
      }

      a {
        font-size: 10px;
      }
    }
  }
}
