.display {
  align-items: center;
  display: flex;
  padding: 7px;

  .artp-input-label {
    align-self: center;
    color: #999;
    font-size: 14px;
    padding: 0 10px 0 0;
    text-align: right;
    width: 150px;
  }

  .btn-group {
    display: flex;
    flex-flow: row;
  }
}
