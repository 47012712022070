@use "sass:color";
.layout-header-promotion-notify {
  background-color: #BF187C;
  position: absolute;
  width: 100%;
  top: -100px;
  z-index: 21;
  transition: top linear 0.3s;
  height: auto;

  &.visible {
    top: var(--header-height, 131px);

    @media(min-width: 768px) {
      height: 82px;
    }
  }

  .container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 100%;
    row-gap: 15px;
    padding: 5px 40px;

    @media (min-width: 768px) {
      column-gap: 10px;
      flex-flow: row;
    }

    @media (min-width: 992px) {
      padding: inherit;
    }

    span.content {
      font-size: 16px;
      text-align: center;

      @media(min-width: 768px) {
        text-align: left;
      }

      @media(min-width: 992px) {
        font-size: 18px;
      }

      @media(min-width: 1200px) {
        font-size: 20px;
      }
    }

    button {
      padding: 10px 15px;
      border-radius: 5px;
      border: solid 1px #fff;
      color: #fff;
      transition: background-color 0.2s;
      background-color: transparent;
      font-size: 16px;
      white-space: nowrap;

      @media(min-width: 992px) {
        font-size: 18px;
      }

      @media(min-width: 1200px) {
        font-size: 20px;
      }

      &:hover {
        text-decoration: none;
        background-color: color.adjust(#BF187C, $lightness: -15%);
      }
    }

  }

  .close-notifier {
    position: absolute;
    right: 0;
    top: -1px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    background-color: #FFFFFF45;
    font-size: 24px;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: color.adjust(#FFFFFF45, $lightness: -20%);
    }
  }


}
