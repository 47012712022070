.artists-artist {
  .menu {
    background-color: #000;
    color: #fff;
    display: none;
    font-size: 14px;
    height: var(--block-height);
    letter-spacing: 0.05em;
    line-height: 19px;
    mix-blend-mode: difference;
    padding: var(--first-block-top-margin) 0 15px 5px;
    position: sticky;
    top: 132px;
    width: calc(var(--block-width) - 15px);
    z-index: 2;

    @-moz-document url-prefix('') {
      background-color: inherit;
    }

    @media(min-width: 992px) {
      display: block;
    }

    .menu-container {
      div {
        border-bottom: solid 1px #333;
        cursor: pointer;
        font-weight: 300;
        margin-bottom: 7px;
        padding-bottom: 7px;
        width: 280px;

        &:hover {
          font-weight: 700;
        }

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
