.artp-Header-Account-Login {
  align-items: center;
  color: #FFF;
  display: flex;
  background-color: #233A84;
  text-decoration: none;
  font-weight: 600;

  > img {
    margin-top: -2px;
    height: 16px;
  }

  > span {
    margin-left: 5px;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

// styling for removed scrollbar
.artp-Header-container .artp-Header-item.artp-Header-Account-Login {
  padding: 0 calc(25px + var(--removed-body-scroll-bar-size, 0px)) 0 25px;
}
