.payments-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  z-index: 1000;
  overflow: hidden;
}
