.account-lots-lists-add-to-list-button-create-content-creation {
  display: flex;
  flex-flow: column;

  p {
    text-align: center;

    &.title {
      font-weight: 600;
    }
  }

  input {
    border: 0;
    border-bottom: solid 1px #ccc;
  }

  select {
    margin-top: 10px;
    text-align-last: center;
  }

  input,
  select {
    align-self: center;
    height: 35px;
    margin-bottom: 15px;
    text-align: center;
    width: 70%;
  }

  .action-buttons {
    align-self: center;

    button {
      margin: 5px;
    }
  }
}
