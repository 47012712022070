.x-landingpage-x-row2 {
  background: #F4F4F4 0 0 no-repeat padding-box;

  .container {
    position: relative;

    .row2 {
      display: flex;
      column-gap: 30px;
      padding: 60px 0;
      flex-flow: column;

      @media(min-width: 768px) {
        padding: 150px 0 60px;
      }

      h1 {
        font: normal normal bold 42px/52px Open Sans;
        color: #223257;
        text-align: center;
      }

      h2 {
        font: normal normal 600 34px/52px Open Sans;
        color: #223257;
        text-align: center;
      }

      .infos {
        align-self: center;

        @media(min-width: 992px) {
          width: 830px;
        }

        .prices {
          margin-top: 60px;
          display: grid;
          grid-template-columns: 1fr;
          grid-row-gap: 50px;
          text-align: center;
          margin-bottom: 50px;

          @media(min-width: 992px) {
            grid-template-columns: 250px 1fr;
            column-gap: 50px;
            margin-bottom: 0;
          }

          .left {
            width: 100%;
            display: flex;
            justify-content: center;

            .price {
              background: transparent linear-gradient(103deg, #223257 0%, #7980B7 100%) 0 0 no-repeat padding-box;
              height: 250px;
              width: 250px;
              border-radius: 50%;
              display: flex;
              flex-flow: column;
              justify-content: center;
              align-items: center;

              div {
                color: #fff;
                font-size: 20px;

                span.number {
                  font-weight: bold;
                  font-size: 60px;
                }
              }
            }
          }

          .right {
            align-self: center;
            font-size: 20px;
            
            p {
              text-align: center;
              
              @media(min-width: 992px) {
                text-align: left;
              }
            }
          }
        }

        .cta {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: 50px;
          justify-items: center;
          margin-top: 30px;

          @media(min-width: 992px) {
            justify-items: left;
            grid-template-columns: 250px 1fr;
          }

          .left {
            align-content: center;
            justify-self: center;

            a {
              @media(min-width: 992px) {
                width: 100%;
              }
            }
          }

          .payment-common-available-payments {
            width: 380px;
            border: 0;
          }
        }
      }
    }
  }
}
