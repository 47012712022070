@use '../../../../assets/stylesheets/artp_colors';

.artdbweb-lot-Indicator-LinkButton {
  background: none;
  border: 0;
  color: artp_colors.$primary;
  outline: none;
  padding: 5px;

  &:hover {
    background: artp_colors.$gray-light;
  }
  
  &:not(:hover) {
    &:active,
    &:focus {
      background: artp_colors.$gray-lighter;
    }
  }
}
