.myartprice-settings {
  margin-bottom: 30px;

  .title {
    h2 {
      margin-bottom: 30px;

      @media (max-width: 992px) {
        margin-bottom: 5px;
      }
    }
  }

  .info {
    font-size: 16px;
    line-height: 26px;
    margin: 20px 0;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 15px;

    @media (min-width: 425px) {
      grid-template-columns: 1fr 1fr;

      &.myartprice-settings-sections {
        grid-template-columns: 1fr;
      }
    }

    @media (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .artist {
      margin-bottom: 5px;
    }
  }
}
