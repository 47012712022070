@use '../../../../assets/stylesheets/artp_colors';

.CookieConsentOverlay {
  $color: #444;
  background: $color;
  bottom: 0;
  box-shadow: 9px 12px 2px $color, 0 0 14px $color;
  color: #fff;
  min-height: 130px;
  padding-top: 15px;
  position: fixed;
  width: 100%;
  z-index: 103;

  .text {
    padding: 0 15px 15px;
    text-align: justify;

    a {
      color: #53afff;

      &:hover {
        color: #3e86c3;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-bottom: 15px;

    .btn {
      border-radius: 0;
      height: 40px;
      margin-left: 20px;
      padding: 5px 10px;
      width: 180px;
    }

    @media(max-width: 550px) {
      flex-direction: column-reverse;

      .btn {
        height: 35px;
        margin-bottom: 15px;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}
