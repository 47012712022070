@use '../../../../../../assets/stylesheets/artp_colors';
@use '../constants';

.marketplace-stores-top-store {

  align-content: center;
  display: flex;
  flex-direction: column;
  height: calc(#{constants.$height-img-container} + #{constants.$margin-top-link} + #{constants.$height-link});
  justify-content: center;
  width: min-content;

  @media (max-width: 768px) {
    width: 100%;
  }

  .store-img {
    height: constants.$height-img-container;

    a {
      align-items: center;
      display: flex;
      height: 100%;


      img {
        margin: 0 10px;
        max-height: 170px;

        @media (max-width: 768px) {
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  .store-link {
    font-size: 16px;
    height: constants.$height-link;
    letter-spacing: 2px;
    margin-top: constants.$margin-top-link;
    text-align: center;
    width: 100%;
    
    a {
      color: artp_colors.$gray-darker;
      text-decoration: none;
    }
  }
}
