.auctioneer-agreement {
  h1 {
    margin-bottom: 20px !important;
  }

  h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  ul {
    margin-top: 20px;

    li {
      margin-bottom: 15px;
    }
  }

  .chk-agreement {
    position: relative;

    label {
      padding-left: 20px;
    }

    input {
      position: absolute;
    }
  }

  .content {
    border: solid 1px #ccc;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    height: 600px;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-y: auto;
    padding: 15px;

    p:nth-child(-n + 2) {
      font-size: 20px;
    }
  }

  .contact {
    font-size: 16px;
    margin-top: 15px;

    h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }

    .title {
      font-size: 18px;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .artp-modal-header {
    h2 {
      font-size: 16px;
    }
  }
}
