@use '../../../../../../../assets/stylesheets/constants';

ul.subscriptions-list-cards-modules-premium {
  li {
    background-size: 10px 18px;
    background-image: url('#{constants.$image-source}subscription/check-premium-2.svg');
    
    ul {
      li {
        background-image: url('#{constants.$image-source}subscription/li-premium.png');        
      }
    }
    
    &.ia {
      cursor: default !important;
      
      ul {
        li {
          cursor: default !important;          
        }
      }
    }
  }
}
