.x-landingpage-x-row3-stamps {
  width: 100%;
  margin-top: 100px;

  .items {
    display: grid;
    place-content: space-evenly;
    grid-template-columns: 1fr;
    align-items: baseline;
    grid-row-gap: 15px;

    @media(min-width: 992px) {
      grid-template-columns: repeat(4, calc((100% - 3 * 30px) / 4));
    }
    
    .item {
      display: grid;
      flex-flow: column;
      row-gap: 20px;
      grid-template-columns: 100px 1fr;
      
      @media(min-width: 992px) {
        grid-template-columns: 1fr;
      }

      .check {
        text-align: right;
        
        @media(min-width: 992px) {
          text-align: center;
        }

        img {
          width: 50px;

          @media(min-width: 992px) {
            width: 90px;
          }
        }
      }

      p {
        padding: 0 20px;
        font: normal normal normal 18px/24px Open Sans;
        text-align: left;
        margin-bottom: 0;
        align-content: center;

        @media(min-width: 992px) {
          text-align: center;
        }
      }
    }
  }
}

