@use '../../../../../assets/stylesheets/constants';

.subscriptions-list-estimate {
  background-color: #F1F1EF;
  padding: 30px 0;
  margin: 60px 0;

  .container {
    @media(min-width: 992px) {
      background: url('#{constants.$image-source}subscription/estimate.png') left center no-repeat;
      padding-left: 430px;
    }

    h2 {
      border: 0;
      margin-bottom: 30px;
    }
    
    p {
      line-height: 25px;
    }

    button {
      padding: 10px 20px;
      margin: 30px 0;
    }
  }
}
