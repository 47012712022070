@use "sass:color";
@use '../../../../assets/stylesheets/artp_colors';

.artists-artist {
  --first-block-top-margin: 20px;
  --block-margin: 60px;
  --block-width: 370px;
  --block-height: 255px;
  --container-width: 100%;

  @media (min-width: 768px) {
    --container-width: 750px;
  }

  @media (min-width: 992px) {
    --container-width: 970px;
  }

  @media (min-width: 1200px) {
    --container-width: 1170px;
  }

  @media(min-width: 1400px) {
    --container-width: 1320px;
  }

  @media(min-width: 1600px) {
    --container-width: 1520px;
  }

  @media(min-width: 2000px) {
    --container-width: 1960px;
  }

  .container {
    position: relative;
  }

  h1,
  h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 38px;
    margin-top: 0;
  }

  .sub-h2 {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.02em;
    line-height: 24px;
    margin-bottom: 15px;
  }

  .block-date-ail {
    background-color: var(--color-ail);
    color: #fff;
    display: flex;
    flex-flow: row;
    padding: 5px 0;
    text-align: center;
    justify-content: center;
    width: 75px;

    .subblock-date-ail {
      display: flex;
      flex-flow: column;
    }

    .day {
      font-size: 34px;
      font-weight: 600;
      text-align: center;
      line-height: 30px;
    }

    .other {
      font-size: 10px;
      text-align: center;
    }

    &.two-part {
      width: 100px;

      .subblock-date-ail {
        display: flex;
        flex-flow: column;
        position: relative;

        .separator {
          border-left: solid 1px #fff;
          height: 40px;
          width: 1px;
          background-color: var(--color-ail);
          position: absolute;
          top: 9px;
          left: 0;
        }

        .day {
          font-size: 24px;
          line-height: 28px;
        }

        .other {
          padding: 0 15px;
        }
      }
    }
    
  }

  .block-no-menu {
    margin: 0 0 60px;
    min-height: 200px;
    padding-top: 15px;
    position: relative;
  }

  .block {
    margin: 0 0 30px;
    min-height: 200px;
    padding-top: 15px;
    position: relative;

    &.first {
      margin-top: 15px;

      @media (min-width: 992px) {
        margin-top: calc(-1 * var(--block-height));
      }
    }

    @media (min-width: 992px) {
      .container {
        padding-left: var(--block-width);
      }
    }
  }

  .block-left {
    margin-left: calc((100vw - var(--container-width)) / 2);
    padding: 0 5px;
  }

  .back-to-top-container {
    bottom: 25px;
    float: right;
    margin-bottom: 25px;
    position: sticky;
    right: 25px;
    z-index: 1000;

    .back-to-top-button {
      background-color: #2b2b2b;
    }
  }
  
  .artp-btn-artist {
    &:hover {
      background-color: color.adjust(#d9d3c3, $lightness: -10%);
      border-color: color.adjust(#d9d3c3, $lightness: -15%);
      color: #000;
    }

    &:disabled,
    &.disabled {
      &:hover {
        background-color: inherit;
      }
    }
    
    &.artp-btn-artist-primary {
      background-color: #d9d3c3;

      &:hover {
        background-color: color.adjust(#d9d3c3, $lightness: -10%);
        border-color: color.adjust(#d9d3c3, $lightness: -15%);
        color: #000;
      }
    }
  }
}
