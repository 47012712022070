@use '../../../../../assets/stylesheets/constants';
@use '../common/stylesheets/constants' as constants2;

.subscriptions-purchase-header {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-flow: column;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    height: 217px;

    &.newcomer {
      height: 90px;
    }
  }

  .bg-right,
  .bg-left {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    width: 232px;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .bg-left {
    background: url('#{constants.$image-source}icon-chevron-blue-left.jpg') left no-repeat;
    left: 0;
  }

  .bg-right {
    background: url('#{constants.$image-source}icon-chevron-blue-right.jpg') right no-repeat;
    right: 0;
  }

  .header {
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      height: 217px;
    }

    .container {
      text-align: center;

      h1 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 15px;

        @media (min-width: 768px) {
          margin-bottom: 30px;
          margin-top: 45px;
        }

        @media (min-width: 992px) {
          font-size: 30px;
        }
      }

      &.auctioneer {
        h1 {
          margin-bottom: 15px;
          margin-top: 15px;

          img {
            height: 75px;
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 25px;

        @media (min-width: 425px) {
          font-size: 16px;
          line-height: 30px;
        }

        @media (min-width: 992px) {
          font-size: 18px;
        }
      }

    }

    &.no-subscription {
      @media(min-width: 768px) {
        height: 195px;
      }

      .container {
        h1 {
          margin-top: 30px;
        }
      }
    }
  }
}
