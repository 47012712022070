.subscriptions-list-cards-subscription-price {
  padding-left: 15px;
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  align-self: center;

  .strike {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    text-decoration: line-through;
  }

  .duration {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    padding-top: 10px;
  }
}
