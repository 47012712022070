.payments-informations {
  .left {
    h1 {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    h3 {
      margin-top: 20px;
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-top: 60px;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }

  .right {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    h4 {
      font-size: 24px;
      font-weight: 300;
      margin-bottom: 10px;

      @media (min-width: 768px) {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .verified {
    color: #ff0000;
    margin-bottom: 30px;
    font-weight: 300;
  }

  .help-on-mobile {
    margin-top: 60px;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .bad-proforma {
    .message {
      padding: 40px 0;
    }

    .help {
      margin-top: 20px;
    }
  }
}
