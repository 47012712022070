.account-lots-lists-add-to-list-button-create-content-success {
  p {
    margin-top: 30px;
    text-align: center;

    a {
      margin-left: 5px;
    }

    .fa-check-circle {
      font-size: 30px;
    }
  }
}
