.ails-landingpage-records {
  margin-bottom: var(--margin-bottom-block);
  padding:  var(--padding-block);
  scroll-margin-top: 250px;

  .records {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;

    @media(min-width: 992px) {
      grid-template-columns: calc(50% - 15px) calc(50% - 15px);
    }
  }
}
