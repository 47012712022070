@use '../../../../../../assets/stylesheets/artp_colors';

a.selected-param {
  background-color: artp_colors.$gray-light;
  border-radius: 0.25em;
  color: artp_colors.$gray-darker;
  display: inline;
  font-size: 75%;
  line-height: 1;
  margin-right: 10px;
  padding: 0.2em 0.6em 0.3em;
  text-align: center;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;

  .fa {
    color: artp_colors.$gray-darker;
    display: inline-block;
    margin-left: 3px;
  }
  
  .appear-on-hover {
    display: none;
  }
  
  .disappear-on-hover {
    display: inline-flex;
  }
  
  
  &:hover {
    background-color: artp_colors.$gray;
    
    .appear-on-hover {
      display: inline-flex;
    }
    
    .disappear-on-hover {
      display: none;
    }
  }
}
