.actions-contact {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  position: relative;

  .actions-contact-block {
    margin-bottom: 5px;

    &.actions-contact-block-inline {
      display: flex;
      justify-content: center;

      .actions-contact-block-label {
        margin-right: 5px;
      }
    }

    .actions-contact-block-label {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
