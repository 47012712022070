.small-section {
  > strong {
    font-size: 1.65rem;

    @media (max-width: 768px) {
      display: block;
    }
  }
  
  span {
    font-size: 1.6rem;
    
    &.inner-html {
      p {
        margin-bottom: 0;
      }
    }
  }
  
  * {
    font-family: sans-serif !important;
  }
}
