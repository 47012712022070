.x-landingpage-x-row7 {
  position: relative;
  margin-top: 350px;
  
  h3,
  h2 {
    color: #223257;
  }

  h2 {
    font: normal normal bold 42px/52px Open Sans;
  }

  h3 {
    font: normal normal 600 30px/52px Open Sans;
  }
}
