@use '../../../../../assets/stylesheets/artp_colors';

.common-drop-down-to-validate {
  .total-count {
    background-color: artp_colors.$gray-light;
    font-size: 12px;
    font-style: italic;
    margin: 0 -10px;
    padding: 5px 20px;
  }
  
  .submit-and-cancel {
    margin-top: 15px;
  }
}
