@use '../../../../assets/stylesheets/constants';

.subscriptions-list {
  .artp-flipswitch-container  {
    .artp-flipswitch {
      margin-top: 1px;
      background-color: #ccc;
      border: solid 1px #ccc;
      height: 17px;
      width: 34px;
      
      &:before {
        font-size: 8px;
        content: "❌";
        right: 3px;
        color: #fff;
        position: absolute;
        top: -6px;
        left: inherit;
      }
      
      &:after {
        background-color: #fff;
        height: 13px;
        width: 13px;
        top: 1px;
      }
      
      &:not(.disabled):hover {
        filter: none !important;
      }
    }

    .input-hidden:checked + .artp-flipswitch {
      background-color: #E4E0D4;
      border: solid 1px #E4E0D4;

      &:after {
        left: calc(100% - 1px);
      }
      
      &:before {
        top: -13px;
        content: url('#{constants.$image-source}icon-check.svg');
        padding-top: 11px;        
        transform: scale(.5);
        font-size: 14px;
        left: 1px;
        right: inherit;
      }
    }
  }
}
