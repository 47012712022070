.DevInfo {
  left: 0;
  padding: 5px;
  position: absolute;
  top: 0;
  z-index: 100;

  &.shown {
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    border-bottom-right-radius: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.8);
    cursor: help;
    padding: 15px;
  }

  .toggle {
    color: #0083f5;
    font-size: 15px;
  }

  .info {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;

    &.host {
      color: #660;
    }

    &.env {
      color: #066;
    }

    &.git {
      color: #606;
    }
  }
}
