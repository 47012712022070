@use '../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-header-store-images {
  display: flex;
  flex-flow: column;
  font-size: 16px;
  height: 450px;

  @media(max-width: 767px) {
    height: inherit;
  }

  .image {
    flex: 1 100%;

    div:first-child {
      align-content: center;
      display: flex;
      height: 100%;
      justify-content: center;

      img {
        border-radius: 5px;
        margin: auto;
        max-height: 400px;
        max-width: 100%;

        @media(max-width: 767px) {
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
    }
  }

  .controls {
    display: flex;
    flex-flow: row nowrap;
    font-size: 36px;

    @media(max-width: 767px) {
      display: none;
    }

    .left {
      justify-content: flex-end;
    }

    .left,
    .right {
      align-self: flex-end;
      display: flex;
      flex: 1;
      padding: 0 10px;

      button {
        align-self: flex-end;
        background-color: transparent;
        border: 0;
        color: artp_colors.$link-blue;
        padding: 0;

        &:hover {
          color: artp_colors.$link-blue-hover;
          text-decoration: underline;
        }
      }
    }
  }
}
