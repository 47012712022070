.lots-thumbnails-carousel {
  .slick-slide {
    display: flex !important;
    height: 100%;
    justify-content: center;

    &.slick-active {
      z-index: 10;
    }
  }
}
