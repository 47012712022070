.AccountLangTour {
  .section {
    min-height: 300px;

    .section-title {
      color: #c01b88;
      font-size: 30px;
      margin-bottom: 30px;
      text-align: center;

      @media (max-width: 992px) {
        font-size: 24px;
      }
    }

    .section-content {
      font-size: 18px;

      @media (max-width: 992px) {
        font-size: 16px;
      }
    }

    .section-button {
      align-items: center;
      background: #fff;
      border: 1px #c01b88 solid;
      border-radius: 6px;
      color: #c01b88;
      cursor: pointer;
      display: inline-flex;
      padding: 10px;
      text-decoration: none;

      &:hover {
        background: #c01b88;
        color: #fff;
      }
    }
  }
}
