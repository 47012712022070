@use '../../../../assets/stylesheets/constants';

.homepage-advantages {
  h2.title {
    margin-bottom: 0;
    display: grid;
    grid-template-rows: 1fr 1fr;

    .sub-h2 {
      color: #555;
      text-transform: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      height: 55px;
      display: flex;
      align-items: center;
    }

    @media (min-width: 1200px) {
      display: block;

      .sub-h2 {
        position: absolute;
        width: 570px;
        top: -7px;
        right: 0;
      }
    }
  }

  .advantage-block {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    height: 270px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #fff;
    background-image: url("#{constants.$image-source}hp/free-test.jpg");
    text-shadow: 0 3px 6px #000000b4;

    .l1 {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 5px;
    }

    .l2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 15px;
    }
  }
}
