.reports-report-table-loginNeeded {
  text-align: center;
  padding: 20px;
  background-color: var(--common-gray);
  white-space: normal;
  
  a {
    text-decoration: none;
  }
}
