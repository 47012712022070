.artist-highlight-on {
  margin-bottom: 60px;

  .slick-active {
    z-index: 10;
  }

  @media (min-width: 992px) {
    display: block;
  }

  .ads-legend {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    margin-bottom: 5px;
  }

  img {
    max-height: 250px;
    max-width: 100%;

    &.large {
      max-width: 100%;

      @media (max-width: 1199px) {
        display: none;
        height: 0;
      }
    }

    &.small {
      display: none;
      height: 0;
      max-width: 100%;

      @media (max-width: 1199px) {
        display: block;
        height: auto;
      }
    }
  }

}
