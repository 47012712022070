.ails-info-staff {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 40px;

  p {
    text-align: center;
    max-width: 850px;
    margin-bottom: 50px;
    font-size: 20px;
  }

  @media(max-width: 768px) {
    img {
      width: 480px;
    }
  }
}
