@use '../../../../../assets/stylesheets/artp_colors';

.artp-modal-methodology {
  margin: 15px;

  .artp-modal-content {
    max-width: 690px;

    .text {
      max-height: 600px;
      overflow-y: auto;
    }

    .bottom {
      margin-top: 20px;
      text-align: right;
    }
  }
}

.reports-report-methodology {
  border-bottom: solid 1px #ccc;
  color: #333;
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: center;

  .cta {
    color: artp_colors.$link-blue;
    cursor: pointer;

    &:hover {
      color: artp_colors.$link-blue-hover;
      text-decoration: underline;
    }
  }
}
