.marketplace-classified-same-artist-and-customer-list {
  margin: 40px 0;

  .header {
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 30px;
  }

  .classifieds {
    column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 100%;
    overflow: hidden;
    overflow-x: auto;

    .classified {
      .img-container {
        height: 150px;
        overflow: hidden;
        text-align: left;
        width: 100%;

        img {
          height: calc(100% - 6px);
        }
      }

      p {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
}
