@use '../../../../../../../assets/stylesheets/constants';

.artists-artist-marketplace {
  .artists-artist-marketplace-empty {
    height: 300px;
    padding-top: 20px;

    .actions {
      background: rgba(217, 217, 217, 0.2);
      display: flex;
      flex-flow: row;
      font-size: 16px;
      height: 130px;
      line-height: 24px;
      margin-top: 30px;
      padding: 30px;
      position: relative;

      div {
        align-self: center;

        &:first-of-type {
          width: 250px;
        }

        &:last-of-type {
          flex: 1;
          text-align: left;
        }
      }
    }
  }
}
