.marketplace-classified {
  margin: 15px 0;

  .managment {
    margin-bottom: 15px;
  }

  .centered-on-mobile {
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .cols {
    column-gap: 30px;
    display: grid;
    grid-template-areas:  'col1'
      'col3'
      'col2';
    grid-template-columns: 100%;

    .col-1 {
      align-self: center;
      grid-area: col1;
    }

    .col-2 {
      grid-area: col2;
    }

    .col-3 {
      grid-area: col3;
    }

    @media(min-width: 768px) {
      grid-template-areas: 'col1 col1'
        'col2 col3';
      grid-template-columns: 1fr 285px;
    }

    @media(min-width: 1200px) {
      grid-template-areas: 'col1 col2 col3';
      grid-template-columns: 475px 1fr 285px;
    }
  }
}
