@use '../../../../assets/stylesheets/constants';

.ails-info {
  h2 {
    text-align: center;
    font-size: 28px;
    margin: 70px 0;
    font-weight: bold;

    @media(min-width: 768px) {
      margin: 50px 0;
      font-size: 32px;
    }
  }
}
