.payments-common-cart-summary {
  padding: 20px 0;
  display: flex;
  flex-flow: column;
  align-self: center;

  .help-on-desktop {
    display: none;

    @media(min-width: 768px) {
      display: block;
    }
  }

  h3 {
    line-height: 34px;

    &.header {
      margin-bottom: 30px;
    }
  }

  .encart {
    margin-bottom: 30px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    overflow: hidden;
    border: solid 2px #E4E2DC;


    div.row {
      padding: 10px 30px;

      &:first-of-type {
        font-size: 16px;
      }

      &:nth-of-type(even) {
        background-color: #E4E2DC;
      }

      ul {
        padding-left: 0;

        li {
          margin-top: 10px;
          list-style-type: none;

          &.first-line {
            display: flex;
            
            .quantity {
              font-weight: 600;
              margin-right: 4px;
            }
            
            .flex-1 {
              flex: 1;
            }
          }

          &:not(.first-line) {
            margin-left: 10px;
          }
        }
      }
    }

    h3 {
      margin-top: 10px;
      display: flex;
      flex-flow: row;

      span {
        flex: 1;
      }
    }
  }

  .payments-info {
    border: solid 1px #E4E2DC;
    padding: 15px;
    border-radius: 20px;

    .cards {
      text-align: center;
      margin: 15px 0;
    }
  }


  .cart-details {
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
    margin-bottom: 30px;
    display: none;

    @media(min-width: 768px) {
      display: block;
    }

    .line {
      border-top: solid 1px #E4E2DC;
      padding: 10px 0;

      &:first-of-type {
        border-top: 0;
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
