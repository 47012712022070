@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.skeleton {
  padding: 0;
  background: linear-gradient(to right, transparent 8%, rgba(130, 130, 130, 0.1) 18%, transparent 33%);
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

