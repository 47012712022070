@use '../../../../../../assets/stylesheets/artp_colors';

.lots-tile-access-square {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  margin: 20px 10px;
  position: relative;
  text-align: center;
  width: 30%;

  a {
    color: #000;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  .account-lots-lists-select-lot {
    left: 5px;
    padding: 15px;
    position: absolute;
    top: 5px;
  }

  .button-follow {
    display: none;
    position: absolute;
    right: 5px;
    top: 5px;

    button {
      background: none;
      border: 0;
      outline: none;
    }

    @media(max-width: 720px) {
      display: inline;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    transition: 0.2s;

    .button-follow {
      display: inline;
    }
  }

  div {
    padding: 2px 0;
  }

  .lot-title {
    font-style: italic;
    font-weight: 600;
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 210px;
  }

  .prices {
    font-size: 1.4rem;
    font-weight: 600;
  }

  .lot-img {
    align-items: center;
    display: flex;
    height: 270px;
    justify-content: center;
    width: 100%;

    img {
      margin: auto;
      max-height: 210px;
      max-width: 210px;
      text-align: center;
    }
  }

  .lot-artist {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lot-sale {
    text-align: center;
  }

  .lot-auctioneer {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.unfollowed {
  opacity: 0.4;
}
