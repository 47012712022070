@use '../../../../../../assets/stylesheets/constants' as constants2;
@use '../../common/stylesheets/constants';

.subscriptions-list-fake {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 100%;

  .card {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px #2c2c2c1a;
    color: #000;
    display: flex;
    flex-flow: column;
    padding: 0 15px 20px;
    width: 100%;

    .block-1 {
      padding: 0 15px;

      h2 {
        background-color: #2c2c2c1a;
        border-radius: 4px;
        height: 32px;
        margin: 20px 0;
        width: 100%;
      }

      .arguments {
        ul {
          background-color: #2c2c2c1a;
          border-radius: 4px;
          height: 240px;
        }

        .details {
          background-color: #2c2c2c1a;
          border-radius: 4px;
          height: 31px;
          margin-top: 20px;
          text-align: center;
        }
      }

      .filler {
        flex: 1;
      }
    }

    .block-2 {
      display: none;
    }
  }

  .images-option {
    height: 31px;
  }

  @media (min-width: 868px) {
    width: 100%;

    .card {
      flex-flow: row;
      height: auto;

      .block-1 {
        display: flex;
        flex-flow: column;
        width: 425px;

        .arguments {
          padding-bottom: 0;

          ul {
            height: 200px;
          }

          .details {
            margin-top: 20px;
          }
        }
      }

      .block-2 {
        display: flex;
        flex: 1;
        flex-flow: column;
        padding: 0 15px;

        .physicproducts {
          background-color: #2c2c2c1a;
          border-radius: 4px;
          color: constants.$main-gray;
          height: 200px;
          margin: 20px 0;
          width: 100%;
        }

        .buy {
          background-color: #2c2c2c1a;
          border-radius: 4px;
          height: 50px;
          margin-bottom: 0;
          margin-top: 33px;
          width: 100%;
        }
      }
    }

    @media (min-width: 1200px) {
      padding: 0;
      width: 380px;

      .card {
        flex-flow: column;
        height: 755px;
        padding: 10px 0;

        .block-1 {
          width: 100%;

          .arguments {
            padding-bottom: 20px;

            ul {
              height: 270px;
            }

            .details {
              height: 40px;
              margin-top: 30px;
            }
          }
        }

        .block-2 {
          .physicproducts {
            height: 200px;
            margin: 0;
          }

          .buy {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
