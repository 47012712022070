
.homepage-myartprice {
  position: absolute;
  top: 0;
  background: #233A84;
  border-radius: 0;
  width: 100%;
  color: #fff;

  .myartprices {
    display: flex;
    width: 100%;
    padding: 15px;
    column-gap: 35px;
    align-items: center;
    justify-content: space-evenly;

    .icon {
      margin-right: 7px;
      background-color: #fff;
      border-radius: 47px;
      color: #233A84;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;

      &.follow {
        background-color: #233a84;
      }

      img {
        width: 30px;

        &.myartprice-ico {
          margin-left: 2px;
          margin-top: 5px
        }
      }
    }

    .myartprice {
      display: flex;
      align-items: center;
      position: relative;

      &.title {
        display: none;

        @media(min-width: 992px) {
          display: inherit;
        }
      }

      &.fav {
        display: none;

        @media(min-width: 768px) {
          display: inherit;
        }
      }

      .txt {
        display: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding-top: 7px;

        @media(min-width: 768px) {
          display: block;
        }

        .sub-txt {
          display: flex;
          flex-flow: column;
          font-weight: 400;
          font-size: 9px;
          height: 20px;
          color: #D6D6D6;


          div {
            line-height: 13px;
            padding: 0 3px;

            &.sub-txt-alert {
              margin-top: 3px;
              background-color: #E53140;
            }
          }
        }
      }

      .count {
        position: absolute;
        background: #9747FF;
        border-radius: 10px;
        font-size: 10px;
        top: -7px;
        color: #fff;
        padding: 1px 4px;
        left: 32px;
        font-weight: 500;
        min-width: 18px;
        text-align: center;
      }

      &:first-of-type {
        font-weight: 300;
        font-size: 23px;
        line-height: 28px;
      }
    }

    a {
      color: #fff;
    }
  }
}
