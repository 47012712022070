@use '../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-form-images {
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  grid-column-gap: 10px;
  grid-row-gap: 45px;
  grid-template-columns: 1fr;


  @media(min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .cell {
    height: 220px;
    position: relative;

    > div:first-child {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;

      img {
        max-height: 200px;
        max-width: 100%;
      }
    }
  }
}
