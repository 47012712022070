.artp-Header-Burger {
  background-color: transparent;
  border: unset;
  grid-area: menu-mobile;

  @media (min-width: 768px) {
    display: none !important;
    grid-area: none;
  }

  > img {
    height: 20px;

    @media (max-width: 600px) {
      height: 16px;
    }
  }
}
