.myartprice {
  #title {
    box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.1;
    margin: 20px 0;
  }
  
  .pagination {
    @media (max-width: 768px) {
      .artp-ui-pagination-container {
        div {
          min-width: 30px;
          padding: 3px;
        }
      }
    }
  }
}
