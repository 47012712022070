@use '../../../../../assets/stylesheets/artp_colors';

.account-lots-lists-list {
  h1 {
    font-weight: 600;
    margin: 40px 0;
    text-align: center;
  }

  .actions {
    align-items: center;
    display: inline-flex;
    flex-flow: row;
    gap: 15px;
    margin-bottom: 10px;
    width: 100%;

    .select-all {
      padding: 5px;
      width: 30px;

      .artp-checkbox-container {
        .check-box {
          margin: 0;
        }
      }
    }
  }

  .lots-list {
    border-top: solid 1px #ccc;
    display: flex;
    flex-flow: row;

    .lots-list-select {
      align-self: center;
      padding: 5px;
      width: 40px;

      .artp-checkbox-container > label {
        margin-bottom: 0;
      }
    }

    .lots-list-pdf {
      padding: 5px;
      width: 215px;

      .account-lots-lists-pdf-link {
        margin-top: 3px;
      }
    }
  }
}
