@use '../../../../assets/stylesheets/artp_colors';
@use '../../../../assets/stylesheets/constants';

.search-universal {
  .search-universal-results {
    background-color: #fff;
  }

  .back-to-top-container-search {
    bottom: 25px;
    margin-bottom: 10px;
    position: sticky;
    float: right;
    padding-right: 20px;
  }

  a.no-underline:hover {
    text-decoration: none;
  }

  .items-container {
    .items {
      display: grid;
      row-gap: 15px;
      grid-template-columns: 1fr;
      transition: grid-template-rows 0.5s ease-in-out;
      padding: 0 15px;
      margin-bottom: 40px;

      @media (min-width: constants.$main-viewport-desktop-width) {
        grid-row-gap: 25px;
        display: grid;
        grid-template-rows: 0fr;
        justify-content: space-between;
        justify-items: center;
        row-gap: 25px;
        transition: grid-template-rows 0.5s ease-in-out;
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: 600px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media (min-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media (min-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
      }

      .open {
        grid-template-rows: 1fr;
      }

      .item {
        --picto-size: 50px;
        
        display: grid;
        grid-template-areas: 'r0 r1'
          'r0 r2';
        grid-template-columns: var(--picto-size) 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 5px;

        @media(min-width: constants.$main-viewport-desktop-width) {
          --picto-size: 170px;
          
          overflow: hidden;
          grid-template-columns: 1fr;
          height: 220px;
          width: var(--picto-size);
          text-align: center;
          grid-template-areas: 'r0'
            'r1'
            'r2';
        }

        .r0 {
          grid-area: r0;
          width: var(--picto-size);
          display: flex;
          justify-content: center;
          
          @media(min-width: constants.$main-viewport-desktop-width) {
            width: 100%;
          }
        }

        .r1 {
          grid-area: r1;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          align-self: end;
          display: inherit;
          
          @media(min-width: constants.$main-viewport-desktop-width) {
            text-align: center;
            align-self: inherit;
          }
        }

        .r2 {
          grid-area: r2;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;

          @media(min-width: constants.$main-viewport-desktop-width) {
            text-align: center;
          }
        }

        .r1,
        .r2 {
          @media(min-width: constants.$main-viewport-desktop-width) {
            display: contents;
          }
          
          a {
            color: #000;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .picto,
        .no-image,
        .fake-picto {
          height: var(--picto-size);
          width: var(--picto-size);
          background-position: center;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.4);
          background-repeat: no-repeat;
          
          @media(min-width: constants.$main-viewport-desktop-width) {
            height: 170px;
            width: 170px;
          }
        }

        .no-image,
        .fake-picto {
          background: #1a171b;
          opacity: 0.85;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 40px;
          color: #fff;

          @media(min-width: constants.$main-viewport-desktop-width) {
            font-size: 100px;
          }
        }

        .no-image {
          background-color: #888;
          background-size: 55px;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }

  .wide-items-container {
    overflow: hidden;
    transition: 0.25s ease-in-out max-height;
    max-height: 1877px;

    &.articles-container-hidden {
      max-height: 0;
    }

    .wide-items {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 15px;

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }

      .wide-item {
        height: 195px;
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: solid 1px #ccc;
        padding: 15px;
        align-items: center;

        @media (min-width: 768px) {
          height: 250px;

          &:nth-last-of-type(2) {
            border: 0;
          }
        }

        @media (min-width: 992px) {
          padding: 0 30px;
        }

        &:last-of-type {
          border: 0;
        }

        @media (min-width: 992px) {
          grid-template-columns: 150px 1fr;
          column-gap: 15px;
        }

        @media (min-width: 1200px) {
          align-items: normal;
          grid-template-columns: 220px 1fr;
        }

        .cover {
          border-radius: 10px;
          background-size: contain;
          height: 170px;
          width: 170px;
          background-position: center;
          display: none;
          justify-self: center;

          @media (min-width: 992px) {
            display: block;
            height: 130px;
            width: 130px;
          }

          @media (min-width: 1200px) {
            height: 170px;
            width: 170px;
          }
        }

        .data-item {
          padding-right: 15px;
          overflow: hidden;

          .date {
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.03em;
            color: rgba(202, 198, 186, 1);
          }

          .title-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            color: #1d1d1f;
            margin-bottom: 10px;
            margin-top: 5px;

            @media (min-width: 992px) {
              line-height: 25px;
            }
          }

          .excerpt {
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            color: #5e636e;

            .htl {
              font-weight: 600;
              font-style: italic;
              color: #000;
            }
          }

          .link {
            margin-top: 15px;
            text-align: right;
          }
        }
      }
    }
  }
}
