.marketplace-classified-actions-currencies {
  align-self: center;
  margin-bottom: 25px;

  .btn-group {
    .btn {
      font-size: 12px;
      font-weight: 700;
      height: 25px;
      padding: 0;
      width: 40px;
    }
  }
}
