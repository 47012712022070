.common-preferences {
  background: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  text-align: left;
  text-decoration: none;
  transition: background ease-out 100ms 0ms;

  &:hover {
    background-color: #eee;
  }

  .title {
    font-size: 14px;

    button {
      color: #000;
      outline: none;
      padding: 0;

      i {
        vertical-align: middle;
      }

      &:hover {
        color: #000;
      }
    }
  }
}
