@use '../../../../assets/stylesheets/artp_colors';

.lots-lot-similar {
  border-top: solid 1px artp_colors.$gray;
  margin-bottom: 40px;
  margin-top: 20px;

  &.no-indicator {
    margin-top: 60px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .lots-container {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    &.with-records {
      padding-top: 60px;
    }
    
    .lot-similar {
      .description {
        text-align: center;
        flex-direction: column;
        font-size: 14px;

        @mixin ellipsis {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .header {
          font-weight: bold;
          width: 100%;
        }

        div {
          @include ellipsis;
          margin-bottom: 5px;
        }

        .img {
          align-items: center;
          display: flex;
          height: 150px;
          justify-content: flex-start;
          margin-bottom: 15px;
          margin-top: 5px;

          a {
            display: block;
            height: 100%;
            width: 100%;

            img {
              max-height: 100%;
              max-width: 100%;
            }
          }
        }

        .link {
          @include ellipsis;
        }

        .category {
          @include ellipsis;

          font-style: italic;
          margin-bottom: 5px;
        }

        .location {
          margin-bottom: 15px;
        }

        .source {
          @include ellipsis;
        }
      }
    }
  }
}
