#modal-root {
  .imagePopup-bg {
    background-color: rgba(0, 0, 0, 0.8);
    justify-content: stretch;
    overflow: auto;
    
    .artp-modal.imagePopup {
      background: transparent;
    }
  }
}

.imagePopup {
  $modal-height: calc(100vh - 100px);
  $modal-width: calc(100vw - 30px);
  height: 100%;
  width: 100%;
  
  .image-container {
    align-items: center;
    cursor: default;
    display: flex;
    height: 100%;
    justify-content: stretch;
    width: 100%;

    img {
      margin: auto;
    }
    
    &.contain-mode {
      img {
        cursor: zoom-out;
      }
    }

    &.contain {
      img {
        cursor: zoom-in;
        max-height: $modal-height;
        max-width: $modal-width;
      }
    }
  }

  .fa-times {
    color: #fff;
    cursor: pointer;
    position: fixed;
    right: 15px;
    top: 15px;
  }

  .fa-chevron-right,
  .fa-chevron-left {
    color: #fff;
    cursor: pointer;
    position: fixed;
    top: 50%;
  }
  
  .fa-chevron-left {
    left: 15px;
  }

  .fa-chevron-right {
    right: 45px;
  }
}
