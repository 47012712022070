.account-lots-lists-select-all-lots-button {
  .button-select {
    background: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    float: left;
    min-width: inherit;
    padding: 5px 10px;
    text-align: left;
    text-decoration: none;
    transition: background ease-out 100ms 0ms;
    white-space: nowrap;

    .artp-checkbox-container .check-box {
      margin-right: 5px;
    }

    &:hover {
      background-color: #f1f1f1;
    }

    @media (max-width: 767px) {
      .fa {
        font-size: 2em;
      }
    }
  }
}
