.folder-desktop {
  &.border-right {
    margin-right: 20px;
    padding-right: 10px;
  }
  
  &:nth-of-type(3),
  &:nth-of-type(4) {
    position: relative;

    &::after {
      border-left: 1px solid #d2cec5;
      content: '';
      height: 40%;
      position: absolute;
      right: 0;
      top: 30%;
    }
  }
}
