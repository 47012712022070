.x-landingpage-x-row5 {
  .container {
    padding: 60px 15px;

    h2 {
      text-align: center;
      font-size: 42px;
      line-height: 60px;
      font-weight: bold;
      color: #4D09A5;
      margin-bottom: 60px;
      padding: 0 31px;
      
      img {
        margin-top: -35px;
        height: 70px;
      }
    }

    p {
      text-align: center;
    }

    .r1 {
      display: grid;
      grid-column-gap: 60px;
      grid-template-columns: 1fr;
      grid-row-gap: 60px;

      @media(min-width: 992px ) {
      grid-template-columns: calc(50% - 15px) calc(50% - 15px);
        
      }
      .c {
        display: flex;
        flex-flow: column;

        h3 {
          text-align: center;
          color: #4D09A5;
          font-size: 24px;
          font-weight: 600;
        }

        img {
          align-self: center;
          margin-top: 60px;
          width: 100%;
        }

        p {
          margin-top: 20px;
          font-size: 18px;
        }
      }
    }
    
    .r2 {
      font-size: 18px;
      margin-top: 60px;
    }
  }
}
