@use "sass:color";
.artists-artist-lots {
  .artists-artist-lots-grid {
    display: grid;

    &.artists-artist-lots-grid-1 {
      grid-template-areas: 'step-future'
        'highlighton'
        'subscriptions-ad'
        'step-past';
    }

    &.artists-artist-lots-grid-2 {
      grid-template-areas: 'step-past'
        'highlighton'
        'subscriptions-ad'
        'step-future';
    }

    .subscriptions-ad {
      grid-area: subscriptions-ad;
    }
    
    .artist-highlight-on {
      grid-area: highlighton;
    }

    .step-past {
      grid-area: step-past;
    }

    .step-future {
      grid-area: step-future;
    }
  }

  .lots {
    column-gap: 30px;
    display: grid;
    grid-template-columns: 260px 260px 260px 260px 260px 260px;

    @media(min-width: 769px) {
      &.ails {
        &.ails-1 {
          grid-template-columns: 550px 260px 260px 260px 260px 260px;
        }

        &.ails-2 {
          grid-template-columns: 550px 550px 260px 260px 260px 260px;
        }

        &.ails-3 {
          grid-template-columns: 550px 550px 550px 260px 260px 260px;
        }

        &.ails-4 {
          grid-template-columns: 550px 550px 550px 550px 260px 260px;
        }

        &.ails-5 {
          grid-template-columns: 550px 550px 550px 550px 550px 260px;
        }
      }
    }
  }

  a {
    color: #000;
    text-decoration: none;

    &.styled {
      color: #0d5b9b;
    }
  }
  
  .lot {
    background-color: var(--common-gray);
    border: solid 1px var(--common-gray);
    display: flex;
    flex-flow: column;
    height: 100%;
    padding: 50px 15px 15px;
    position: relative;
    row-gap: 10px;
    
    a {
      color: #000;
      text-decoration: none;
    }

    p {
      font-size: 11px;
      font-weight: 300;
      letter-spacing: 0.04em;
      line-height: 19px;
      margin-bottom: 0;
      text-align: center;

      &.title {        
        a {
          color: #000;
          font-style: italic;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .lock {
      color: #b4b2ab;
      display: flex;
      font-size: 11px;
      justify-content: center;
      line-height: 15px;
      place-items: center;
    }

    .tag {
      display: flex;
      flex-flow: row;
      font-size: 10px;
      font-weight: 300;
      left: 0;
      line-height: 16px;
      position: absolute;
      top: 0;

      .dt {
        background-color: #d9d3c3;
        min-width: 45px;
        padding: 3px 5px;
        text-align: center;
      }

      .country {
        background-color: #fff;
        padding: 3px 10px;
      }
    }

    &.fake {
      background-color: #d9d3c3;
      display: flex;
      flex-flow: column;
      margin-right: 15px;
      padding: 0;
      row-gap: 0;
      width: 260px;

      .fake-bg {
        display: none;
      }

      .logo {
        padding: 15px;
      }

      .fake-container {
        flex: 1;
        padding: 0 15px 15px;
        text-align: center;

        .link:hover {
          background-color: color.adjust(#fff, $lightness: -7%);
          border: 1px solid color.adjust(#333, $lightness: -7%);
        }

        p {
          letter-spacing: -0.015em;

          &.logo {
            margin: 20px 0 0;
          }

          &.title {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            margin-bottom: 15px;
          }

          &.content {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 15px;
          }
        }

        a {
          font-size: 14px;
          padding: 10px;
        }
      }

      &.horizontal {
        flex-flow: row;
        max-width: 700px;
        min-height: 151px;
        width: 100%;

        .fake-bg {
          background-color: #2b2b2b;
          display: block;
          height: auto;
          width: 65px;
        }

        img {
          height: fit-content;
          position: absolute;
          width: 150px;
        }

        .fake-container {
          padding: 10px 10px 10px 90px;
          text-align: left;

          p {
            text-align: left;
          }
        }
      }
    }
  }
}
