@use '../../../../../../assets/stylesheets/constants';


.payments-informations-form-password {
  .group {
    display: flex;
    flex-flow: column;
    position: relative;

    .validation {
      position: absolute;
      right: -340px;
      width: 340px;
      background-color: #fff;
      padding: 15px;
      z-index: 1;
      box-shadow: 1px 2px 7px rgba(0, 0, 0, .085);
      border-radius: 20px;
      
      ul {
        padding-left: 25px;

        li {
          margin-bottom: 5px;
          list-style-type: none;
          padding-left: 20px;

          &.verified {
            color: #008000;
            background-image: url('#{constants.$image-source}icon-check.svg');
            background-size: 10px 18px;
            background-repeat: no-repeat;
          }
        }
      }
    }

    .cell {
      .input-button {
        button:last-of-type {
          background-color: transparent;
          color: #000;

          &:hover {
            border: none;
            background-color: transparent;
            color: #000;
          }
        }
      }

      &.warning::after {
        right: 50px;
        top: 6px;
      }

      &.links {
        margin-left: 25px;
        margin-top: 5px;
        font-weight: 300;

        a {
          font-weight: 600;
        }
      }
    }
  }
}
