#modal-root {
  position: relative;
  z-index: 4023;
}

.modal-black-bg {
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: opacity 333ms ease-in-out;
  width: 100%;

  &.modal-hidden {
    opacity: 0;
    pointer-events: none;

    .artp-modal {
      transform: translateY(-100%);
    }
  }

  &.modal-visible {
    opacity: 1;

    .artp-modal {
      transform: translateY(0);
    }
  }

  .artp-modal {
    align-self: center;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    flex-flow: column;
    margin: 0 10px;
    transition: transform 333ms ease-in-out;
    display: grid;
    grid-template-rows: 1fr;

    &.with-title {
      grid-template-rows: 90px 1fr;

      .artp-modal-content {
        max-height: calc(80vh - 90px);
      }
    }

    &.with-footer {
      grid-template-rows: 1fr 90px;

      .artp-modal-content {
        max-height: calc(90vh - 90px);
      }
    }

    &.with-title.with-footer {
      grid-template-rows: 90px 1fr 90px;

      .artp-modal-content {
        max-height: calc(90vh - 180px);
      }
    }

    .artp-modal-header {
      align-items: center;
      display: flex;
      font-size: 1.5rem;
      justify-content: space-between;
      padding: 0 1.6rem;
      width: 100%;
      overflow: hidden;
      top: 0;
      margin-bottom: 15px;

      h2,
      .clickable-icon {
        color: #fff;
        padding: 0 0.5rem;
      }

      &:not(.noheadbar) {
        background-color: #333;
        box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.57);
      }

      .noheadbar h2 {
        color: #333;
        font-weight: 600;
        padding: 0;
      }
    }

    .artp-modal-content {
      justify-self: center;
      padding: 2rem;
      overflow-y: auto;

      &.fitted {
        border-left: 1px solid rgba(0, 0, 0, 0.25);
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        margin: auto;
        max-width: 65%;
      }
    }

    .artp-modal-footer {
      position: fixed;
      bottom: 0;
      background-color: #fff;
      z-index: 2;
      width: 100%;
      height: 80px;
      padding: 20px;
      border-top: solid 1px #ccc;
      display: flex;
      justify-content: right;
      column-gap: 15px;
    }
  }
}

.fixed-gridcol-2 {
  grid-column: 2;
}

.clickable-icon {
  cursor: pointer;
  font-size: 1.5rem;
  margin: unset;
  padding: 0.5rem;
  transition: filter 0.2s;
}

.clickable-icon:hover {
  filter: brightness(90%);
}

.clickable-icon:active {
  filter: brightness(60%);
}
