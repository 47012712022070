.x-landingpage-x-row7-faq.common-accordion {
  .accordion-item {
    border: 0;
    border-bottom: solid 1px #ccc;

    &:first-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
    
    .title {
      padding: 15px;
      
      h3 {
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

  .accordion-content {
    font-size: 16px;
  }
}
