@use '../../../../../../assets/stylesheets/constants';

.payments-platforms-stripe-checkout-form {
  .klarna-container {
    margin-top: 40px;
    font-size: 16px;
    display: flex;
    flex-flow: row;
    align-items: flex-end;

    .klarna {
      overflow: hidden;
      border-radius: 5px;
      margin-right: 5px;
      height: 25px;
      width: 25px;
      background-size: 100% 100%;
      background-image: url(#{constants.$image-source}/platform/klarna.png);
    }
  }
}
