.artists-artist-common-drawer-no-access {
  .bg {
    background-color: #000;
    height: 100vh;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
  }

  .box-no-access {
    display: flex;
    flex-flow: column;
    left: 15px;
    border-radius: 20px;
    position: fixed;
    top: calc(100vh / 2 - 250px);
    width: calc(100vw - 30px);
    z-index: 4;
    background-color: #fff;
    padding: 30px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 22px;
    }

    @media(min-width: 768px) {
      left: calc(100vw / 2 - 360px);
      width: 720px;
    }

    .close {
      right: -7px !important;
      top: -5px;
    }

    .box-content {
      p {
        font-size: 18px;
        margin: 20px 15px;
      }

      .actions {
        margin-top: 50px;
        display: flex;
        column-gap: 15px;
        justify-content: center;
      }
    }
  }
}

