
.payment-common-help {
  margin-bottom: 30px;
  color: #000;
  border-radius: 20px;
  padding: 10px 20px;
  background-color: #E4E2DC;
  text-align: center;
  font-size: 16px;

  h3 {
    margin-top: 10px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }
}
