@use "sass:color";
@use '../../Cards/constants';

.subscriptions-list-table-button {
  font-weight: 500;
  
  &.basics {
    color: #000;
    background-color: constants.$basic;
    border: solid 1px color.adjust(constants.$basic, $lightness: -10%);

    &:hover {
      background-color: color.adjust(constants.$basic, $lightness: -10%);
    }
  }

  &.basics.store {
    color: #000;
    background-color: constants.$basicsstore;
    border: solid 1px color.adjust(constants.$basicsstore, $lightness: -10%);

    &:hover {
      background-color: color.adjust(constants.$basicsstore, $lightness: -10%);
    }
  }

  &.premium {
    color: #fff;
    background-color: constants.$premium;
    border: solid 1px color.adjust(constants.$premium, $lightness: -10%);

    &:hover {
      background-color: color.adjust(constants.$premium, $lightness: -10%);
    }
  }

  &.professional {
    color: #000;
    background-color: constants.$professional;
    border: solid 1px color.adjust(constants.$professional, $lightness: -10%);

    &:hover {
      background-color: color.adjust(constants.$professional, $lightness: -10%);
    }

    &.store {
      color: #fff;
      background-color: constants.$marketplace-professional;
      border: solid 1px color.adjust(constants.$marketplace-professional, $lightness: -10%);

      &:hover {
        background-color: color.adjust(constants.$marketplace-professional, $lightness: -10%);
      }
    }
  }
  
  &.artprice.store {
    color: #000;
    background-color: constants.$store;
    border: solid 1px color.adjust(constants.$store, $lightness: -10%);

    &:hover {
      background-color: color.adjust(constants.$store, $lightness: -10%);
    }
  }
}
