.classified-description {
  margin-top: 0;

  .artist {
    font-weight: bold;
    font-size: 19px;
    line-height: 26px;
    margin-bottom: 15px;

    .button-follow {
      .btn {
        font-size: 12px;
      }
    }

    .artist-dates {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
    }
  }

  h1.title {
    font-style: italic;
    font-size: 18px;
    line-height: 25px;
    margin: 0;
    margin-bottom: 15px;

    .dates {
      font-style: normal;
      font-size: 14px;
      line-height: 25px;
      margin-left: 5px;
    }
  }

  .idclassified {
    font-style: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 40px;
  }
}
