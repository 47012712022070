@use '../../../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-form-images-image-placeholder {
  position: relative;

  &:hover {
    .actions {
      opacity: 1;
      visibility: visible;
    }
  }

  &.primary {
    .legend {
      bottom: -30px;
      color: #666;
      font-style: italic;
      left: 0;
      position: absolute;
    }

    img {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.7), 0 6px 20px 0 rgba(0, 0, 0, 0.7);
    }

    .fa-star-o {
      color: #4b32b9;
      font-size: 30px;
      position: absolute;
      right: -17px;
      text-shadow: -2px 2px 3px #fff;
      top: -17px;
    }
  }

  .actions {
    background-color: artp_colors.$white;
    border-bottom-right-radius: 3px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: visibility 0s, opacity 0.25s linear;
    visibility: hidden;
    width: 22px;

    .action {
      align-items: center;
      display: flex;
      height: 22px;
      justify-content: center;
    }

    @media(max-width: 768px) {
      opacity: 1;
      visibility: visible;
      width: 44px;

      .action {
        height: 44px;
      }
    }
  }
}
