.myartprice-common-modale-artist-follow-settings {
  .artp-modal-header {
    padding: 0 5px;

    h2 {
      margin-top: 10px;
    }

    .fixed-gridcol-2 {
      flex: 1;
    }
  }

  .settings-categories {
    display: flex;
    flex-wrap: wrap;

    .is-favorite {
      font-size: 1em;
      padding: 10px;
      width: 100%;
    }

    h2 {
      font-size: 1em;
      width: 100%;
    }

    > * {
      font-size: 0.8em;
      min-width: 50%;
    }

    &:first-child {
      min-width: 100%;
    }

    .actions {
      border-top: solid 1px #ccc;
      display: flex;
      justify-content: space-evenly;
      margin-top: 15px;
      padding: 15px;
      width: 100%;

      button {
        width: 30%;
      }
    }
  }
}
