@use '../../../../../assets/stylesheets/constants';

.faq-mobile-section {
  margin-bottom: 0;
  padding: 0;

  li {
    border-top: solid 1px #ccc;
    cursor: pointer;
    font-weight: normal;
    list-style-type: none;
    padding: 25px;
    transition: background-color 0.25s linear;

    img {
      max-width: 100%;
    }

    .faq-title {
      margin: 0;
    }

    .faq-item {
      margin: 0;
      margin-top: 15px;
      max-height: 0;
      overflow: hidden;
      padding-left: 15px;

      li {
        border-top: 0;
        cursor: inherit;
        list-style-type: inherit;
        padding: inherit;
        transition: background-color 0.25s linear;
      }
    }

    &.active {
      background-color: #f6f6f6;

      .faq-title {
        font-size: 20px;
        font-weight: bold;
      }

      .faq-item {
        max-height: 10000px;
      }
    }
  }
}

