@use '../../../../../assets/stylesheets/artp_colors';

.marketplace-store-header-store {
  font-size: 16px;
  position: relative;

  .back-button {
    margin-top: 30px;
  }

  button {
    cursor: pointer;
    line-height: 1em;
  }

  .store-content {
    .error {
      color: artp_colors.$danger;
      font-size: 14px;
      margin-left: 10px;
    }

    .header {
      h1 {
        display: flex;
        flex-flow: row;
        column-gap: 5px;
        font-size: 30px;
        margin-top: 0;
      }
    }

    .body {
      display: flex;
      flex-flow: row wrap;
      height: 100%;

      .images {
        flex: 1;
        padding: 15px;

        @media(max-width: 767px) {
          flex: 100%;
        }
      }

      .infos {
        flex: 1;
        padding: 15px;

        @media(max-width: 767px) {
          padding: 15px 0;
        }
      }
    }

    .store-follow {
      button {
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        box-sizing: border-box;
        color: #333;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        font-size: 14px;
        font-weight: normal;
        line-height: 1em;
        margin-bottom: 0;
        padding: 6px 12px;
        text-align: center;
        touch-action: manipulation;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
      }
    }
  }
}
