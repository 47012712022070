@use '../../../../../assets/stylesheets/artp_colors';

.myartprice-common-tab-tile {
  $size-collapse: 600px;
  justify-content: space-evenly;
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  .container {
    border-bottom: solid 1px artp_colors.$gray;
    border-collapse: collapse;
    display: flex;
    justify-content: center;
  }

  @mixin tab-base {
    float: left;
    line-height: 20px;
    padding: 15px 0;
  }

  .bottom-line {
    border-top: solid 1px artp_colors.$gray;
    bottom: 0;
    line-height: 0;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  .tab-left,
  .tab-right {
    @include tab-base;
    width: 2%;

    @media(max-width: $size-collapse) {
      display: none;
    }
  }

  .tab {
    @include tab-base;
    align-items: center;
    border-left: 0;
    border-right: 0;
    border-top: solid 1px artp_colors.$white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    height: 55px;
    justify-content: center;
    margin-bottom: -1px;
    text-transform: capitalize;
    width: 30%;

    &.active {
      border-bottom: solid 1px artp_colors.$white;
      border-left: solid 1px artp_colors.$gray;
      border-right: solid 1px artp_colors.$gray;
      border-top: solid 1px artp_colors.$gray;
      cursor: default;

      &:hover {
        font-weight: normal;
      }
    }

    &:hover {
      font-weight: bold;
    }

    @media(max-width: 992px) {
      font-size: 14px;
    }

    @media(max-width: $size-collapse) {
      font-size: 16px;
      margin-top: 0;
      padding: 5px;
      text-align: center;
    }
  }

  .counter-badge {
    margin-left: 4px;
    padding: 3px 7px;
  }
}
