.artp-Header-Langs {
  align-items: center;
  display: flex;
  
  .artp-Header-Langs-flag-button {
    border-radius: 50%;
  }
}

.artp-Header-Langs-flag {
  font-size: 14px;
  margin-right: 7px;
  margin-top: -2px;
}
