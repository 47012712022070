.artists-artist-articles {
  padding: 30px 0;

  .actions {
    margin-top: 40px;
    text-align: center;

    @media(min-width: 768px) {
      text-align: left;
    }
  }

  .articles {
    column-gap: 30px;
    display: flex;
    flex-flow: column;

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        .article {
          .content {
            .more {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .sub-title {
      font-size: 18px;
      line-height: 24px;
    }

    .article {
      background-color: #efefef;
      column-gap: 15px;
      display: flex;
      flex-flow: column;
      margin-top: 20px;
      padding: 15px;

      @media(min-width: 768px) {
        flex-flow: row;
      }

      .picto {
        align-items: center;
        align-self: center;
        display: flex;
        margin-bottom: 20px;
        width: 200px;

        @media(min-width: 768px) {
          align-self: flex-start;
          margin-bottom: 0;
        }
      }

      .content {
        flex: 1;

        h3 {
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          margin: 0 0 15px;
        }

        .more {
          font-size: 12px;
          font-weight: 300;
          line-height: 14px;
          margin-top: 30px;
        }

        .txt {
          font-size: 13px;
          font-weight: 300;
          line-height: 19px;
        }

        img {
          height: 150px;
          max-width: 100%;
        }
      }
    }
  }
}
