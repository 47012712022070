@use '../../../../../../assets/stylesheets/constants';

.marketplace-stores-common-no-access {
  background-color: #f1f1f1;
  border-radius: 15px;
  display: flex;
  flex-flow: row;
  position: relative;
  z-index: 2;

  .image {
    align-items: center;
    display: flex;
    height: 200px;
    width: 200px;

    img {
      width: 100%;
    }
  }

  .content {
    border-radius: 5px;
    flex: 1;
    padding: 40px 15px;

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .text {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}
