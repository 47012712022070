.store-Stats-BestClassifieds {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 255px;

  .store-Stats-BestClassifieds-item {
    min-width: 150px;
    width: 20%;

    .img {
      align-items: center;
      display: flex;
      height: 170px;
      justify-content: center;
      margin-bottom: 15px;

      img {
        max-height: 160px;
        max-width: 160px;
      }
    }

    .title {
      margin-bottom: 5px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .artist {
      margin-bottom: 5px;
      text-align: center;
    }

    .category {
      text-align: center;
    }
  }
}
