@use '../../../../../assets/stylesheets/constants';

.ails-info-spaces {
  .h2-subtitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 60px
  }

  .blocks {
    display: grid;
    grid-row-gap: 60px;
    grid-template-areas: 'a'
      'b'
      'c'
      'd'
      'e'
      'f'
      'g';

    @media(min-width: 768px) {
      grid-column-gap: 30px;
      grid-row-gap: 90px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: 'a b c'
      'd e f'
      'g g g';
    }


    .block {
      border: 1px solid #EFEFEF;

      h3 {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(min-width: 768px) {
          font-size: 28px;
          height: 60px;
        }
      }

      .numbers {
        margin-bottom: 15px;
        display: flex;
        flex-flow: row;
        column-gap: 15px;

        @media(min-width: 768px) {
          margin-bottom: 30px;
        }

        .number {
          display: flex;
          flex-flow: column;
          width: 100%;
          position: relative;

          div {
            text-align: center;
            font-size: 20px;
            color: #222;

            @media(min-width: 768px) {
              height: 50px;
            }

            @media(min-width: 1192px) {
              font-size: 22px;
            }

            &:first-of-type {
              color: #203A81;
              font-size: 32px;
              font-weight: bold;

              @media(min-width: 1192px) {
                font-size: 40px;
              }
            }
          }
        }
      }

      .options {
        background-color: #E8E8E8;
        width: 100%;
        height: 90px;
        padding: 15px;
        display: flex;
        flex-flow: row;
        column-gap: 10px;
        row-gap: 10px;
        flex-wrap: wrap;
        justify-content: center;

        div {
          font-size: 16px;
          display: flex;
          align-items: center;
          column-gap: 10px;

          &:before {
            content: '• ';
          }

          @media(min-width: 768px) {
            column-gap: 5px;
            font-size: 14px;
          }

          @media(min-width: 992px) {
            column-gap: 5px;
            font-size: 18px;
          }
        }
      }

      &.a {
        grid-area: a;
      }

      &.b {
        grid-area: b;
      }

      &.c {
        grid-area: c;
      }

      &.d {
        grid-area: d;
      }

      &.e {
        grid-area: e;

        img {
          height: 30px;

          @media(min-width: 768px) {
            position: absolute;
            top: -5px;
            height: 22px;
          }

          @media(min-width: 992px) {
            top: -10px;
            height: 25px;
          }

          @media(min-width: 1192px) {
            top: -5px;
          }

          &.fb {
            left: 10px;

            @media(min-width: 768px) {
              left: -7px;
            }

            @media(min-width: 992px) {
              left: 8px;
            }

            @media(min-width: 1192px) {
              left: 10px;
            }
          }

          &.x {
            left: 25px;

            @media(min-width: 768px) {
              left: 2px;
            }

            @media(min-width: 992px) {
              left: 20px;
            }

            @media(min-width: 1192px) {
              left: 25px;
            }
          }
        }
      }

      &.f {
        grid-area: f;
      }

      &.g {
        grid-area: g;
        border: 2px dashed #BCBCBC;
        padding: 30px 15px;
        display: flex;
        flex-flow: column;
        justify-content: center;

        @media(min-width: 768px) {
          padding: 0 60px;
        }

        p {
          text-align: center;
          font-size: 18px;
          margin: 0;

          &.title {
            line-height: 34px;
            font-weight: bold;
            color: #203A81;
            font-size: 28px;
            margin: 30px 0;
          }
        }

        div {
          text-align: center;

          a {
            padding: 15px 50px;
            font-size: 16px;
            background-color: #203A81;
            border-color: #203A81;
            color: #fff;

            @media(min-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }

    }
  }
}
