.Search {
  .Search-Body {
    display: flex;
    flex-direction: row;

    .Search-QuickFilters {
      margin-right: 15px;
      max-width: 240px;
      width: 30%;
    }

    .Search-children {
      flex: 1;
    }
  }
}
