@use '../../../../../../assets/stylesheets/constants';

.marketplace-stores-list-store {
  --columns: 1;
  --container-width: 100%;
  --cell-width: calc((var(--container-width) - (var(--gap) * (var(--columns) - 1))) / var(--columns));

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  width: var(--cell-width);

  @media (min-width: 768px) {
    --columns: 2;
    --container-width: 750px;
  }

  @media (min-width: 992px) {
    --container-width: 970px;
    --columns: 3;
  }

  @media (min-width: 1200px) {
    --container-width: 1170px;
    --columns: 4;
  }

  .img {
    height: 160px;
    overflow: hidden;
    width: var(--cell-width);

    img {
      align-self: center;
      width: var(--cell-width);
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    padding: 0 15px;

    a {
      color: #000;
      cursor: pointer;
    }
  }

  p {
    flex: 1;
    padding: 0 15px;
  }

  .button-follow {
    text-align: right;
  }
}
