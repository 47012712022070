@use '../common/stylesheets/constants';

.subscriptions-purchase-list {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0;
  position: relative;

  @media(min-width: 768px) {
    margin-top: 20px;
  }

  &.cards-1,
  &.cards-2 {
    justify-content: left;

    .subscriptions-purchase-list-theme {
      margin-right: 15px;
    }
  }

  &.upgrade {
    flex-flow: column-reverse;
  }

  @media(min-width: 1200px) {
    flex-flow: row;

    &.upgrade {
      flex-flow: row;
    }
  }

  .warning {
    background-color: #fff;
    border: solid 1px #2c2c2c1a;
    box-shadow: 0 0 10px #ddd;
    font-size: 24px;
    font-weight: 600;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 127px;
    width: 75%;
    z-index: 10;

    button {
      margin-top: 20px;
      
      @include constants.subscription-button;
    }
  }
}
