.artists-artist-header-signatures {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;


  .signature-container {
    position: relative;
    width: 100%;

    @media (min-width: 600px) {
      flex: 1 1;
      height: 100%;
    }

    .signature {
      background-blend-mode: multiply;
      background-color: var(--common-gray);
      background-position: center right;
      background-repeat: no-repeat;
      background-size: contain;
      height: 100px;
      margin-right: 20px;
      width: 250px;

      @media (min-width: 600px) {
        position: absolute;
        right: 0;
        top: -35px;
        width: 200px;
      }
    }
  }

  .links {
    .sig {
      align-items: center;
      align-self: center;
      display: flex;
      font-weight: 300;
      line-height: 14px;
      width: 170px;

      .lock {
        margin-right: 10px;
        width: 30px;
      }

      .od {
        flex: 1;
      }
    }
  }
}

.artists-artist-header-signatures-drawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: #DBCEED !important;

  @media(min-width: 768px) {
    max-width: 630px;
  }

  &.white {
    background-color: #fff !important;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
  }

  .signatures {
    background-color: #fff;
    display: flex;
    flex-flow: column;
    height: calc(90vh - 190px);
    overflow-y: auto;
    padding: 15px;
    row-gap: 20px;

    .item {
      margin-bottom: 15px;

      img {
        max-width: 90%;

        @media (min-width: 310px) {
          max-width: 300px;
        }
      }
    }
  }

  .account-links {
    margin-top: 30px;
    display: flex;
    flex-flow: row;
    column-gap: 15px;

    .link-default {
      background-color: #fff;

      &:hover {
        background-color: #0BA9DB !important;
      }
    }
  }
}

