.x-landingpage-x-row3 {
  background: transparent linear-gradient(90deg, #223257 0%, #7980B7 100%) 0 0 no-repeat padding-box;
  
  .container {
    padding: 60px 15px;
    color: #fff;
  
    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 42px;
      margin-bottom: 50px;
    }
    
    p {
      text-align: center;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;
    }
  }
}
