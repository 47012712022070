.x-landingpage-x-row6-testimonial {
  padding: 135px 40px 40px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 6px 6px 10px #2132562E;
  border: 1px solid #D4C1E8;
  position: relative;

  @media(min-width: 768px) {
    bottom: -350px;
    position: absolute;
    width: calc(100% - 30px);
  }

  @media(min-width: 992px) {
    bottom: -320px;
  }

  @media(min-width: 1200px) {
    bottom: -275px;
  }

  img {
    width: 80px;
    position: absolute;
    top: 50px;
  }

  p {
    font: italic normal normal 18px/29px Open Sans;
    margin-bottom: 25px;

    &.signature {
      font: normal normal bold 18px/29px Open Sans;
      margin-bottom: 0;
    }
  }
}

