@use "sass:color";
@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../common/stylesheets/constants';

.subscriptions-free-services-others {
  background-color: #fff;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  position: relative;
  text-align: center;

  @media(min-width: 768px) {
    flex-flow: row;
    height: 150px;
    text-align: left;
  }

  &.no-data {
    margin-top: 0;
  }

  .bg-right,
  .bg-left {
    display: none;
    height: 100%;
    position: absolute;
    top: 0;
    width: 232px;

    @media(min-width: 768px) {
      display: block;
    }
  }

  .bg-left {
    background: url('#{stylesheets-constants.$image-source}icon-chevron-red-left.jpg') left no-repeat;
    left: 0;
  }

  .bg-right {
    background: url('#{stylesheets-constants.$image-source}icon-chevron-red-right.jpg') right no-repeat;
    right: 0;
  }

  .container {
    display: flex;
    flex-flow: column;
    justify-content: center;

    @media(min-width: 768px) {
      align-items: center;
      flex-flow: row;
    }

    @media(min-width: 992px) {
      flex-flow: column;
    }

    @media(min-width: 1200px) {
      flex-flow: row;
    }

    .block {
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;

      @media(min-width: 768px) {
        max-width: 540px;
        width: 50%;
      }

      @media(min-width: 1200px) {
        height: 100%;
      }

      &.alone {
        text-align: center;
      }

      h2 {
        color: constants.$main-blue;
        cursor: pointer;
        font-size: 18px;
        line-height: 31px;
        font-weight: bold;

        @media(min-width: 768px) {
          font-size: 24px;
        }

        &:hover {
          color: color.adjust(constants.$main-blue, $lightness: -25%);
        }

        img {
          margin-right: 15px;
        }
      }
    }
  }
}
