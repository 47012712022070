$main-color: #c01b88;

.ModalTour-Navigation {
  column-gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .ModalTour-Navigation-btn {
    align-items: baseline;
    border-radius: 6px;
    column-gap: 10px;
    cursor: pointer;
    line-height: 14px;
    display: flex;
    padding: 20px;

    &.btn-prev {
      color: $main-color;
    }

    &.btn-next {
      background-color: $main-color;
      color: #fff;
    }
  }
  
  .arrow {
    font-size: 22px;
  }
}
