@use '../../../../../assets/stylesheets/artp_colors';

.myartprice-bar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  .filters-container {
    display: flex;
    flex-flow: row;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .fa {
      font-size: 18px !important;

      @media (max-width: 425px) {
        font-size: 16px !important;
      }
    }

    a {
      color: artp_colors.$gray-dark;
      font-size: 18px;
      text-decoration: none;
      transition: color ease-out 100ms 0ms;

      @media (max-width: 425px) {
        font-size: 16px;
      }

      &:hover {
        color: artp_colors.$gray;
      }
    }

    .dropdown.visible a {
      font-size: 14px;
    }

    .search-artist,
    input {
      flex: 1;
      margin-right: 10px;
    }

    .common-dropdown {
      margin-left: 10px;
    }

    .option {
      width: 100%;
    }
  }
}
