.auctioneer-contact-message-sent {
  .artp-modal-header {
    h2 {
      font-size: 16px;
      margin: 15px 0;
      padding: 0;
    }
  }

  .bottom {
    margin-top: 25px;
    text-align: center;
  }
}
