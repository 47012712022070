.homepage-teams {
  display: flex;
  flex-flow: column;
  min-height: 380px;
  max-height: 600px;
  margin-top: 100px;
  place-content: center;
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-radius: 10px;
  padding: 20px;

  .l0 {
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 30px;
  }

  .l1 {
    align-self: center;
  }
}
