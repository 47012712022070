.artists-artist-collaborations {
  .collaborations {
    column-gap: 30px;
    display: flex;
    flex-flow: row;

    a:hover {
      text-decoration: none;

      .collaboration {
        div:last-of-type {
          text-decoration: underline;
        }
      }
    }

    .collaboration {
      div {
        align-items: center;
        border-radius: 0;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 170px;

        &:first-of-type {
          background-color: #1d1d1d;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          height: 130px;
          padding: 15px;
        }

        &:last-of-type {
          background-color: #efefef;
          color: #000;
          font-size: 11px;
          font-weight: 300;
          height: 30px;
        }
      }
    }
  }
}
