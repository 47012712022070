@use "sass:color";
//$main-blue: #04328c;
$main-blue: #337ab7;
$main-blue-border: color.adjust($main-blue, $lightness: -5%);
$main-blue-hover: color.adjust($main-blue, $lightness: -10%);
$main-blue-border-hover: color.adjust($main-blue, $lightness: -12%);
$main-gray: #646464;
$main-price: #ffa500;
$second-gray: #1a171b;

$color-basics: #000;
$color-premium: #516cb1;
$color-professional: #ff552f;
$color-basics-store: #8851b1;
$color-store: #000;

@mixin subscription-button {
  background-color: #fff;
  border: 1px solid $main-blue-border;
  border-radius: 4px;
  color: #000;
  font-size: 16px;
  line-height: 31px;
  height: 35px;
  outline-style: none;
  padding: 0 30px;
  transition: background-color ease-out 200ms;
  white-space: nowrap;

  &:hover {
    background-color: color.adjust(#fff, $lightness: -10%);
    border-color: $main-blue-border-hover;
  }

  &.active {
    background: $main-blue 0 0 no-repeat padding-box;
    color: #fff;

    &:hover {
      background-color: color.adjust($main-blue, $lightness: -10%);
    }
  }
}
