.highlighted-title {
  $underline-width: 12;
  $underline-color: #ffd535;
  $underline-cap-width: 4px;
  $underline-width-scale: calc(#{$underline-width} / 8);

  display: table;
  margin-left: auto;
  margin-right: auto;
  padding: 0 5px;
  position: relative;
  text-align: center;

  .highlighted-title-content {
    background-image: linear-gradient(180deg, #{$underline-color}, #{$underline-color});
    background-position-x: calc(#{$underline-cap-width} * #{$underline-width-scale}), 0, 100%;
    background-position-y: calc(100% - 4px);
    background-repeat: no-repeat;
    background-size: calc(100% - calc(#{$underline-cap-width} * #{$underline-width-scale} * 2)) calc(#{$underline-width} * 1px), auto calc(#{$underline-width} * 1px), auto calc(#{$underline-width} * 1px);
    box-decoration-break: clone;
    display: inline;
    padding: 0 calc(0.12em + calc(#{$underline-cap-width} * #{$underline-width-scale}));
  }
}


