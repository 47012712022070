.artists-artist-header {
  .main-illustration {
    display: none;
    flex-basis: 280px;
    margin-right: 75px;

    &.multiple {
      overflow-x: hidden;
    }

    @media (min-width: 992px) {
      display: block;
    }

    &.hide-for-widescreen {
      display: block;
      margin: 0 auto;

      @media (min-width: 992px) {
        display: none;
      }
    }

    .slick-list {
      .slick-active {
        z-index: 10;
      }
    }

    .item {
      background-color: #fff;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-flow: column;
      padding: 15px 15px 25px;
      position: relative;

      .title {
        bottom: 3px;
        color: #999;
        font-size: 10px;
        font-style: italic;
        left: 3px;
        position: absolute;
      }

      &.ail {
        border: solid 1px var(--color-ail);
        max-height: 465px; // 100px more than the img max-height

        @media (min-width: 992px) {
          width: 280px !important;
        }

        a {
          color: #000;
          text-decoration: none;
        }

        h3 {
          background-color: var(--color-ail);
          color: #fff;
          font-size: 9px;
          font-weight: 600;
          left: 0;
          line-height: 12px;
          margin: 0;
          padding: 5px 0;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          top: 0;
          width: 155px;
        }

        .legend {
          display: flex;
          flex-flow: row;
          margin-top: 15px;

          .block-date-ail {
            bottom: 0;
            position: absolute;
            right: 0;
          }

          .info {
            flex: 1;
            width: 100%;

            .lot-title {
              font-style: italic;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: calc(100% - 65px);
              &.short {
                width: calc(100% - 85px);
              }
            }

            .auctioneer {
              font-weight: 600;
              text-decoration: underline;
            }
          }
        }
      }

      .repro {
        align-self: center;

        &.ail {
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }

        img {
          max-height: 365px;
          max-width: 100%;
        }
      }
    }
  }
}
