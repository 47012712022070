@use '../../../../assets/stylesheets/constants';

.indexes-header {
  color: #fff;

  .indexes-breadcrumb {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    margin-top: 15px;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .background {
    background: transparent linear-gradient(270deg, #329ae6 0%, #36c 100%) 0 0 no-repeat padding-box;
    height: 454px;
    position: absolute;
    width: 100%;

    .bg-left {
      display: none;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 345px;

      @media (min-width: 768px) {
        background: url('#{constants.$image-source}indexes-left.svg') left bottom no-repeat;
      }

      @media (min-width: 1200px) {
        display: block;
      }
    }

    .bg-right {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 345px;

      @media (min-width: 768px) {
        background: url('#{constants.$image-source}indexes-right.svg') right bottom no-repeat;
      }
    }
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin: 30px 0;
    text-align: center;
  }

  .actions {
    display: flex;
    flex-flow: row;

    .btn-group {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
    }

    .infos {
      align-self: center;
      background: url('#{constants.$image-source}ampoule.svg') left center no-repeat;
      padding-left: 20px;

      a {
        color: #fff;
      }

      @media(max-width: 767px) {
        background: none;
        padding-left: 0;
      }
    }
  }
}
