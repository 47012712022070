@use '../../../../assets/stylesheets/artp_colors';

.lots-show-no-access-subscription {
  background-image: radial-gradient(circle at center, #fff, #f1f1f1 100%);
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 30px;
  padding: 30px 0;

  @media (min-width: 768px) {
    background-image: linear-gradient(to right, artp_colors.$gray-light, artp_colors.$white 80%);
  }

  .content {
    @media (min-width: 768px) {
      display: flex;
      flex-flow: row;
    }

    .free-sample {
      bottom: 0;
      font-size: 14px;
      left: 15px;
      margin-top: 10px;
      width: 100%;
    }

    .info {
      display: flex;
      flex-flow: column;
      text-align: center;

      @media (min-width: 768px) {
        flex: 1;
      }

      .btn {
        font-size: 18px;
      }
    }

    .promotion {
      margin-top: 15px;
      text-align: center;

      img {
        @media (max-width: 400px) {
          max-width: 100%;
        }
      }

      @media (min-width: 768px) {
        margin-top: 0;
        width: 50%;
      }
    }
  }
}
