.myartprice {
  #no-results {
    width: 100%;
  }

  .pagination {
    @media (max-width: 768px) {
      .artp-ui-pagination-container {
        div {
          min-width: 30px;
          padding: 3px;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  .back-to-top-button {
    float: right;
    position: sticky;
    transform: translateY(-10px);
  }
}
