@use '../../../../../assets/stylesheets/artp_colors';

.lots-lot-no-access {
  $default-font-size: 16px;
  font-size: $default-font-size;
  margin-bottom: 50px;
  margin-top: 30px;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    flex-flow: column;
  }

  .header {
    h1 {
      font-size: 20px;
      margin-bottom: 10px;
      margin-top: 0;
    }

    h2 {
      font-size: 16px;
      margin-top: 0;
    }
  }

  .datas {
    display: flex;
    flex-flow: row;
    margin-top: 30px;

    .col-1 {
      display: flex;
      flex-flow: column;
      width: 50%;


      .content-no-access {
        border-bottom: 0;
        font-size: 18px;

        @media(max-width: 767px) {
          margin-bottom: 10px;
          text-align: center;
        }

        @media(min-width: 768px) {
          color: #ccc;
          font-size: 25px;
          left: 120px;
          letter-spacing: 2px;
          min-width: 450px;
          position: absolute;
          text-align: center;
          top: 95px;
          transform: rotate(-25deg);

          .fa-arrow-down {
            display: none;
          }
        }
      }
    }

    .col-2 {
      display: flex;
      flex: 1;
      flex-flow: column;
    }
  }

  .free {
    font-size: 16px;
    margin-bottom: 10px;
    padding-top: 2px;
    width: 100%;
  }

  .loader {
    background-image: linear-gradient(to right, artp_colors.$gray-light, artp_colors.$white 70%);
    border-radius: 5px;
    float: left;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 7px;
    white-space: nowrap;
    width: 90%;

    .loader-content {
      color: artp_colors.$gray-dark;
      float: left;
      padding: 2px 2px 2px 5px;

      &::after {
        content: attr(data-txt);
      }
    }
  }
}
