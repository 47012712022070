.account-top-bar {
  height: 50px;
  margin-bottom: 15px;
  padding-top: 9px;

  .actions {
    display: flex;
    flex-flow: row;

    .left {
      flex: 1;

      button {
        margin-right: 5px;
      }
    }

    .right {
      button {
        margin-left: 5px;
      }
    }
  }
}
