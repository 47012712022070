.artp-Header-top {
  align-items: stretch;
  background-color: #dfded7;
  border-bottom: 1px solid #d2cec5;
  color: #535862;
  display: flex;
  flex-direction: row;
  height: var(--header-top-height);
  z-index: 3;

  @media print {
    display: none;
  }

  .artp-Header-top-item {
    padding: 0 25px;

    @media (max-width: 1200px) {
      padding: 0 15px;
    }

    @media (max-width: 768px) {
      padding: 0 5px;
      flex: 1;
    }

    @media (max-width: 600px) {
      font-size: 12px;
    }

    @media (max-width: 425px) {
      font-size: 10px;
    }
  }
}
