.homepage-faq {
  .section {
    align-items: center;
    border-bottom: 1px solid #CAC6BA;
    display: grid;
    grid-template-columns: 1fr 144px;
    height: 100px;

    @media(min-width: 375px) {
      height: 75px;
    }


    @media(min-width: 768px) {
      grid-template-columns: 1fr 205px;
    }

    &:last-of-type {
      border: 0;
    }


    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #1D1D1F;
    }

    .lnk {
      text-align: right;

      a.artp-btn {
        font-size: 14px;
      }
    }
  }
}
