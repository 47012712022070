@use '../../constants';

.card {
  .subscriptions-list-cards-subscription-header {
    padding: 20px 15px;
    text-align: center;

    @media(min-width: 768px){
      height: 140px;
    }
    @media(min-width: 992px){
      height: 180px;
    }
    @media(min-width: 1200px){
      height: 150px;
    }
    
    
    h2 {
      font-size: 28px;
      margin-bottom: 10px;
      margin-top: 5px;
      font-weight: normal;
      line-height: 27px;
    }

    p {
      font-weight: 300;
    }
  }

  &.essential {
    .subscriptions-list-cards-subscription-header {
      background-color: #fff;
      border-bottom: solid 1px constants.$essential;
    }
  }

  &.subscription.professional {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$professional;
      color: #000;
    }
  }

  &.store.professional {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$marketplace-professional;
      color: #fff;
    }
  }


  &.marketplace-professional {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$marketplace-professional;
      color: #000;
    }
  }

  &.basics {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$basic;
      color: #000;
    }
  }

  &.premium {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$premium;
      color: #fff;
    }
  }

  &.basicsstore {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$basicsstore;
      color: #000;
    }
  }

  &.artpricestore {
    .subscriptions-list-cards-subscription-header {
      background-color: constants.$store;
      color: #000;
    }
  }
}
