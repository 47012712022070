.indexes-howto-video {
  height: calc((100vw - 60px - 6px) / 1.7777778);
  width: 100%;

  @media (min-width: 768px) {
    height: calc(566px / 1.7777778);
    width: 566px;
  }

  @media (min-width: 992px) {
    height: calc(866px / 1.7777778);
    width: 866px;
  }

  iframe {
    margin: 0;
  }
}
