.artists-artist-recommendations {
  .recommendations {
    column-gap: 20px;
    display: flex;
    flex-flow: row;

    a:hover {
      text-decoration: none;

      .page {
        text-decoration: underline;
      }
    }

    .recommendation {
      align-items: center;
      background-color: #efefef;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      flex-grow: 0;
      flex-shrink: 0;
      height: 100%;
      justify-content: flex-end;
      justify-items: center;
      padding: 15px;
      position: relative;
      width: 210px;

      .img-container {
        --container-size: 170px;

        height: var(--container-size);
        justify-content: center;
        line-height: var(--container-size);
        margin-bottom: 20px;
        position: relative;
        text-align: center;
        width: var(--container-size);

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      P {
        color: #000;

        &.artist {
          font-size: 13px;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 0;
        }

        &.page {
          font-size: 11px;
          line-height: 15px;
        }
      }
    }
  }
}
