@use "sass:color";

.ails-landingpage-link {
  &.btn-default {
    background-color: #0F637C;
    border-color: #0F637C;
    color: #fff;
    
    
    &:hover {
      background-color: color.adjust(#0F637C, $lightness: 15%); 
      border-color: color.adjust(#0F637C, $lightness: 15%);
    }
  }
}
