@use '../../../../assets/stylesheets/artp_colors';

.account-top-menu {
  $color-active: #e7e7e7;

  background-color: #f8f8f8;
  margin-bottom: 15px;

  ul {
    margin: 0 0 0 5px;
    padding: 0;
    z-index: 40;

    li {
      cursor: pointer;
      display: inline-block;
      list-style-type: none;
      z-index: 41;

      &.active {
        background-color: $color-active;
      }

      &.disabled {
        a {
          color: #ccc;
        }

        &:hover {
          background-color: #f8f8f8;
          cursor: inherit;
        }
      }

      &:hover {
        background-color: $color-active;

        a {
          text-decoration: none;
        }
      }

      a {
        color: #777;
        display: block;
        height: 100%;
        padding: 10px;
      }
    }
  }
}
