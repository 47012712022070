.ails-landingpage-agenda {
  display: flex;
  flex-flow: column;
  padding:  var(--padding-block);

  .agenda {
    border: solid 1px #ccc;
    padding: 30px;
    font-size: 16px;
    justify-self: center;


    @media(min-width: 768px) {
      width: 720px;
    }

    p {
      margin-bottom: 10px;
      display: flex;

      span {
        @media(min-width: 768px) {
          white-space: nowrap;
          font-weight: normal;
        }

        &.separator {
          margin: 0 5px;
        }
      }
    }

    a {
      color: #000;
      font-weight: 600;

      @media (min-width: 768px) {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .all {
      justify-content: center;

      a.artp-btn {
        margin-top: 20px;
        background-color: #7C0F3D;
        color: #fff;
        font-weight: inherit;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}
  
