.myartprice-lots-vertical-bar {
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-right: 20px;
  max-width: 240px;
  padding: 0 5px 10px;
  position: relative;
  width: 30%;
  flex-direction: column;
  opacity: 1;
  text-overflow: ellipsis;
  white-space: nowrap;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
