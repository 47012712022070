.homepage-stores {
  .data {
    color: #1D1D1F;

    a {
      text-decoration: none;
      color: #000;

      &:hover .l1-0 {
        text-decoration: underline;
      }
    }

    .store {
      height: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;

      @media(min-width: 768px) {
        width: 230px;
      }

      @media(min-width: 992px) {
        width: auto;
      }

      .l0 {
        margin-bottom: 15px;
        width: 100%;
        max-width: 370px;

        @media(min-width: 992px) {
          display: grid;
          column-gap: 15px;
          grid-template-columns: 185px 1fr;
        }

        @media(min-width: 1200px) {
          grid-template-columns: 235px 1fr;
        }

        .l0-0 {
          overflow: hidden;
          border: solid 1px #1D1D1F;
          border-radius: 10px;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          position: relative;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 370px;

          @media(min-width: 768px) {
            height: 235px;
            width: 100%;
          }

          @media(min-width: 992px) {
            background-image: none !important;
            height: 185px;
          }

          @media(min-width: 1200px) {
            height: 235px;
          }

          .l0-0-bg {
            background: #1a171b;
            opacity: 0.85;
            position: absolute;
            height: 100%;
            left: 0;
            width: 100%;
            top: 0;
            z-index: 0;

            @media(min-width: 992px) {
              display: none;
            }
          }

          .inititial {
            font-size: 100px;
            text-align: center;
            margin-top: -20px;
            margin-bottom: -5px;
            text-transform: uppercase;
            font-weight: 200;
            color: #fff;
            z-index: 1;

            @media(min-width: 992px) {
              color: #1D1D1F;
            }
          }

          .underline {
            border: solid 1px #fff;
            width: 50px;
            z-index: 1;

            @media(min-width: 992px) {
              border: solid 1px #1D1D1F;
            }
          }
        }

        .l0-1 {
          display: none;
          grid-template-rows: 75px 75px 75px;
          grid-row-gap: 5px;

          @media(min-width: 992px) {
            display: grid;
          }

          div {
            height: 100%;
            width: 100%;
            align-self: center;
            text-align: center;
            border-radius: 10px;
            background-size: cover;
            background-position: center;
          }
        }
      }

      .l1 {
        display: flex;
        flex-flow: column;
        width: 100%;
        text-align: center;

        @media(min-width: 768px) {
          text-align: left;
        }

        @media(min-width: 992px) {
          flex-flow: row;
        }

        .l1-0 {
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 5px;

          @media(min-width: 768px) {
            white-space: nowrap;
          }
        }

        .l1-1 {
          align-self: center;
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          text-transform: uppercase;
          width: 100%;

          @media(min-width: 992px) {
            width: auto;
          }
        }
      }
    }
  }
}
