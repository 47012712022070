.x-landingpage-header {
  box-shadow: 0 3px 6px #00000029;
  height: 120px;
  z-index: 1;
  position: relative;

  @media(min-width: 768px) {
    height: 80px;
  }

  .container {
    .r {
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 100%;
      flex-flow: column;
      column-gap: 30px;
      row-gap: 15px;

      @media(min-width: 768px) {
        flex-flow: row;
      }

      .logo {
        margin-top: 15px;
        width: 250px;
      }

      .baseline {
        font-size: 18px;
        flex: 1;
      }
    }
  }
}

