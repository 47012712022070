@use '../../../../assets/stylesheets/artp_colors';
@use '../../../../assets/stylesheets/constants';

.homepage-header {
  position: relative;
  background: #1f1e23 url('#{constants.$image-source}hp/bg.jpg') center center no-repeat;
  color: #fff;
  text-shadow: 0 3px 6px #000000b4;
  background-size: cover;

  @media (min-width: 768px) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    height: 360px;
  }

  &.co {
    background: none;
    height: 82px;

    @media (min-width: 768px) {
      background: #1f1e23 url('#{constants.$image-source}hp/bg-co.jpg') center center no-repeat;
      height: 260px;
    }
  }

  h1 {
    margin-top: 30px;
    font-weight: 300;
    font-size: 32px;
    line-height: 45px;

    @media (min-width: 768px) {
      line-height: 50px;
      margin-top: 40px;
      font-size: 40px;
    }

    @media(min-width: 992px) {
      line-height: 60px;
      font-size: 54px;
    }
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;

    @media(min-width: 768px) {
      font-size: 18px;
      line-height: 28px;
    }

    @media(min-width: 992px) {
      line-height: 31px;
    }
  }
}
