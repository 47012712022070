@use '../../../../../assets/stylesheets/artp_colors';

.not-partner-item {
  width: 100%;
  
  .card-title,
  .card-subtitle {
    h2 {
      line-height: 0.8;
      margin: 6px 0;
    }
    
    small {
      color: artp_colors.$gray-darker;
      font-size: 55%;
    }
  }
}
