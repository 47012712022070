.artp-modal.common-login-modal {
  border-radius: 0;
  background-color: #F9F8F6;
  padding: 40px;
  width: 90%;
  max-width: 900px;

  .artp-modal-content {
    width: 100%;
    display: flex;
    flex-flow: row;
    column-gap: 30px;

    h1 {
      font-weight: 300;
      margin-bottom: 30px;
      font-size: 25px;
    }

    .left {
      flex: 1;
    }

    .right {
      width: 290px;
    }
  }

  &.common-stylesheets-forms {
    h3 {
      font-weight: 300;
      margin-bottom: 0;
      margin-top: 0;
    }

    button {
      margin-top: 30px;
      background-color: #4D09A5;
      border-radius: 10px;
      color: #fff;
      padding: 10px 20px;
    }
  }
}
