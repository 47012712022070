@use "sass:color";
@use '../../../../../assets/stylesheets/artp_colors';

.artp-search-bar {
  background-color: inherit;
  display: flex;
  height: 100%;
  width: 100%;
  
  input {
    border: solid 1px #ccc;
    border-radius: 4px;
    flex: 1 1 auto;
    font-size: 16px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    
    &::placeholder {
      color: #999;
      opacity: 1; /* Firefox */
    }
  }
  
  
  button {    
    background-color: #337ab7;
    border: 0;
    color: #fff;
    height: 100%;
    padding: 10px 12px;
    transition: background-color 0.15s;
    
    i.fa.fa-search {
      font-size: 1.7rem;
    }
    
    &:hover {
      background-color: color.adjust(#2e6da4, $lightness: -10%);
      color: #fff;
    }
    
    &:focus {
      background-color: color.adjust(#2e6da4, $lightness: -15%);
      color: #fff;
    }
  }
  
  
  &.artp-input-group {
    input {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
    
    button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}
