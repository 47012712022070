.mobile-menu-container {
  background-color: #fff;
  touch-action: none;
  transform: translateY(-100%);
  transition: transform 250ms ease-out;
  z-index: 1;

  &.open {
    transform: translateY(0%);
  }
}
