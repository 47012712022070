.subscriptions-list-common-users {
  display: flex;
  flex-flow: row;
  column-gap: 15px;
  justify-content: center;
  margin: 30px 0;
  font-size: 18px;
  font-weight: 300;
  place-items: center;

  .group {
    border: solid 1px #D9DBE9;
    display: flex;
    flex-flow: row;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    color: #6F6C90;
    font-weight: 600;

    button {
      outline: none;
      border: 0;
      padding: 6px 12px;
      font-size: 14px;
      border: none;
      background-color: #fff;
    }

    .quantity {
      flex: 1;
      display: flex;
      width: 35px;
      align-items: center;
      justify-content: center;
    }
  }
}
