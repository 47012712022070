.artp-Header-Notification {
  .link-list-item {
    background-color: #f6deee !important;
    position: relative;

    &:hover {
      background-color: #e1cede !important;

      &::after {
        border-bottom-color: #e1cede;
      }
    }

    &::before,
    &::after {
      bottom: 100%;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }

    &::after {
      border: 10px solid transparent;
      border-bottom-color: #f6deee;
      left: calc(50% - 10px);
    }
  }

  + .artp-Header-Notification {
    .link-list-item {
      &::before,
      &::after {
        all: unset;
      }
    }
  }
}
