.search-item {
  align-items: center;
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    align-items: stretch;
    flex-direction: column;
  }

  .search-item-label {
    margin: 3px 15px 3px 0;
    text-align: end;
    width: 35%;

    @media (max-width: 767px) {
      text-align: start;
      width: 100%;
    }
  }

  .search-item-control {
    flex: 1;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.search-drawer {
  .search-item {
    margin: 10px;
  }
}

.Search-QuickFilters {
  .search-item {
    align-items: stretch;
    flex-direction: column;
    margin: 10px 10px 25px 0;

    .search-item-label {
      color: #888;
      font-size: 16px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      padding-left: 5px;
      text-align: start;
      width: 100%;
    }
  }
}
