.artp-Header-Pill {
  display: inline-block;
  position: relative;

  .pill-logo {
    height: 15px;
    right: 0;
    position: absolute;
    top: -5px;
    width: 15px;
  }
}
