.categories {
  column-gap: 10px;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  font-weight: 300;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 100%;

  @media (min-width: 768px) {
    column-gap: 5px;
  }

  .artp-btn {
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 300;

    @media (min-width: 768px) {
      margin-bottom: 5px;
      padding: 4px 15px;
    }
  }
}
