@use '../../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-edit-form-images-image {
  position: relative;

  > div:first-child {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 25px;
  }

  .updating-image {
    position: absolute;
  }
}
