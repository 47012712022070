.homepage-ails-sales {
  .data-ails {
    font-style: normal;
    margin-top: 15px;

    a {
      text-decoration: none;
      align-self: flex-start;

      &:hover .l3 {
        text-decoration: underline;
      }
    }

    .ail {
      color: #1d1d1f;
      display: flex;
      flex-flow: column;
      width: 100%;
      max-width: 95vw;
      border-radius: 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

      &.fake {
        box-shadow: none;

        .l0 {
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        }
      }


      .l0 {
        max-width: 280px;
        height: 280px;
        overflow: hidden;
        align-self: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        max-height: calc(100vw - 30px - 15px);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        @media(min-width: 375px) {
          max-width: 370px;
          height: 370px;
        }

        @media(min-width: 768px) {
          align-self: inherit;
          height: 230px;
        }

        @media(min-width: 992px) {
          height: 303px;
        }

        @media(min-width: 1200px) {
          height: 370px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      .l1 {
        font-size: 13px;
        font-weight: 600;
        margin-top: 15px;
        width: 100%;
        text-align: center;
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media(min-width: 768px) {
          white-space: nowrap;
          text-align: left;
          height: 25px;
        }

        .landingpage-title {
          color: #fff;
          padding: 5px 10px;
          background-color: var(--color-ail);

          @media(min-width: 768px) {
            position: absolute;
          }
        }
      }

      .l2 {
        padding: 10px;
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media(min-width: 768px) {
          text-align: left;
        }

        a {
          color: #5E636E;
        }
      }

      .l3 {
        column-gap: 5px;
        padding: 0 10px 10px;

        @media(min-width: 768px) {
          display: grid;
          grid-template-columns: 1fr 130px;
        }

        .l3-0 {
          flex: 1;
          color: #5e636e;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          text-align: center;
          text-transform: uppercase;
          align-self: center;

          @media(min-width: 768px) {
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .l3-1 {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          justify-self: right;
          white-space: nowrap;
          text-align: center;
          margin-top: 10px;
          display: flex;
          flex-flow: column;
          align-self: center;

          @media(min-width: 768px) {
            white-space: nowrap;
            text-align: right;
            margin-top: 0;
          }

          .l3-1-1 {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
