@use '../../../../../assets/stylesheets/artp_colors';

.myartprice-lots-tile-no-access {
  display: flex;
  flex-flow: column;
  font-size: 14px;
  margin: 20px 10px;
  position: relative;
  text-align: center;
  width: 30%;

  a {
    color: #000;
    text-decoration: none;
  }

  @media (max-width: 992px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  .button-follow {
    display: none;

    @media(max-width: 720px) {
      display: inline;
    }
  }

  &:hover {
    box-shadow: 2px 2px 5px artp_colors.$gray-eee;
    transition: 0.2s;

    .button-follow {
      display: inline;
      position: absolute;
      right: 5px;
      top: 5px;

      button {
        background: none;
        border: 0;
        outline: none;
      }
    }
  }

  .lot-title {
    font-style: italic;
    margin: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 210px;
  }

  .lot-img {
    align-items: center;
    display: flex;
    height: 225px;
    justify-content: center;

    .no-pict {
      align-items: center;
      align-self: center;
      border: solid 1px #f1f1f1;
      border-radius: 5px;
      box-shadow: 2px 2px 5px #eee;
      color: #b7b7b7;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      height: 200px;
      justify-content: space-evenly;
      text-align: center;
      transition: all linear 200ms;
      width: 160px;

      p {
        color: #b7b7b7;
        font-size: 16px;
        padding: 10px;
      }

      img {
        max-height: 65px;
        max-width: 220px;
      }
    }
  }

  .lot-artist {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lot-auctioneer {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .unfollowed {
    opacity: 0.4;
  }
}
