.reports-report-table-cell {
  text-align: left;

  &.right {
    text-align: right;
  }

  &.center {
    text-align: center;
  }

  &.lot,
  &.string,
  &.artist {
    white-space: normal;
  }
}
