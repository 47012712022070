.artp-Header-Menu {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  z-index: 3;

  .sln-folder-team {
    display: none
  }

  .chevron {
    margin-left: 2px;
    transition: transform 250ms ease-out;

    &.down {
      transform: rotate(0);
    }

    &.up {
      transform: rotate(180deg);
    }

    &.right {
      transform: rotate(270deg);
    }

    &.left {
      transform: rotate(90deg);
    }
  }

  &.menu-desktop {
    grid-area: menu-desktop;

    @media (max-width: 1350px) {
      grid-area: menu-desktop-small;
    }

    @media (max-width: 768px) {
      display: none;
      grid-area: none;
    }
  }

  &.menu-mobile {
    flex-direction: column;

    .flags,
    .artp-Header-Help {
      padding: 10px 20px;
    }

    .flags {
      display: flex;
      justify-content: flex-start;
      transition: transform 250ms ease-out;

      .flag-link {
        display: block;

        &:first-child {
          margin-right: 10px;
          padding-right: 5px;
        }

        &:not(:first-child) {
          margin: 0 10px;
          padding: 0 5px;
        }

        &:not(.selected) {
          cursor: pointer;
        }

        &.selected {
          cursor: not-allowed;
          opacity: 0.25;
        }
      }
    }

    .folder-mobile,
    .flags {
      border-bottom: 1px solid #e0e0e0;
    }

    &.selected {
      .flags,
      .folder-mobile,
      .artp-Header-Help {
        transform: translateX(-100%);
      }
    }
  }
}
