.x-landingpage-x-row8 {
  position: relative;
  margin-top: 60px;

  h3,
  h2 {
    color: #223257;
    text-align: center;
  }

  h2 {
    font: normal normal bold 42px/52px Open Sans;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 60px;
    font: normal normal 600 30px/52px Open Sans;
  }
  
  p {
    text-align: center;
    font: normal normal normal 18px/28px Open Sans;
    
    &:first-of-type {
      padding: 0 20%;
      margin-bottom: 60px;
    }
    
    &.conditions {
      margin-top: 160px;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0;
      color: #7C7C7C;
    }
  }
  
  
}
