.search-common-sort {
  .radios-list {
    display: flex;
    flex-flow: column;
    row-gap: 3px;
    padding: 5px 10px;

    label {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: unset !important;

      input {
        margin: 0 7px 0 0;
      }
    }
  }
}
