.auctioneers-future-sales-sale {
  display: flex;
  flex-flow: column;
  height: 275px;
  margin-bottom: 30px;

  @media(max-width: 768px) {
    height: auto;
    margin-bottom: 70px;
  }

  .sale {
    display: flex;
    flex-flow: row;
    height: 210px;

    @media(max-width: 768px) {
      display: block;
      height: auto;

      .auctioneers-common-cover {
        float: left;
      }
    }

    .infos {
      display: flex;
      flex-flow: column;
      font-size: 14px;
      height: 180px;
      padding-right: 15px;
      width: 60%;

      @media(max-width: 768px) {
        float: left;
        width: calc(100% - 215px);
      }

      .title {
        font-size: 14px;
        margin-bottom: 5px;
        margin-top: 0;
      }

      .date {
        margin-bottom: 5px;
      }

      .fill-flex {
        flex: 1;
      }
    }

    .stats-and-modify {
      display: flex;
      flex: 1;
      flex-flow: column;

      .notify {
        margin-bottom: 15px;
      }

      @media(max-width: 768px) {
        display: block;
        float: left;
        width: 100%;
      }
    }
  }

  .sale-progress {
    width: 100%;
  }
}
