.Search-HorizontalBar {
  .lots-horizontal-bar {
    .container {
      @media (max-width: 425px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      .filters-container {
        display: grid;
        grid-template-areas:
          'left center right'
          'mobile mobile mobile';
        grid-template-columns: auto 1fr auto;

        .grid-left {
          grid-area: left;
        }

        .grid-right {
          grid-area: right;
        }

        .grid-center {
          grid-area: center;

          @media (max-width: 991px) {
            grid-area: mobile;
            margin-top: 10px;

            &.account-lots-lists-add-to-list-button {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
