.auctioneers-sale-lots-top5 {
  margin-top: 30px;

  .top5-lots {
    display: flex;
    flex-flow: row;
    font-size: 16px;
    justify-content: space-between;
    padding-top: 30px;

    .spinner {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      width: 100%;
    }

    .top5-lot {
      min-width: 150px;
      width: 20%;

      .auctioneers-sale-common-lot {
        margin-bottom: 15px;
      }
    }
  }
}
