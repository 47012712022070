@use "sass:color";
@use '../../../../../../assets/stylesheets/constants' as stylesheets-constants;
@use '../../common/stylesheets/constants';

.subscriptions-purchase-list-theme {
  margin-bottom: 30px;
  padding: 0 15px;
  width: 100%;

  .theme {
    background-color: #fff;
    border: solid 1px #2c2c2c1a;
    color: #000;
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;

    &.lvl-#{stylesheets-constants.$theme-basics} {
      border: solid 1px constants.$color-basics;
    }

    &.lvl-#{stylesheets-constants.$theme-premium} {
      border: solid 1px constants.$color-premium;
    }

    &.lvl-#{stylesheets-constants.$theme-professional} {
      border: solid 1px constants.$color-professional;
    }

    &.lvl-#{stylesheets-constants.$theme-basics-store} {
      border: solid 1px constants.$color-basics-store;
    }

    &.lvl-#{stylesheets-constants.$theme-store} {
      border: solid 1px constants.$color-store;
    }

    &.active {
      box-shadow: 0 0 10px #ddd;
    }

    .block-1 {
      align-items: center;
      display: flex;
      flex-flow: column;
      opacity: 0.4;

      &.active {
        opacity: 1;
      }

      .lvl-#{stylesheets-constants.$theme-basics} {
        color: constants.$color-basics;
      }

      .lvl-#{stylesheets-constants.$theme-premium} {
        color: constants.$color-premium;
      }

      .lvl-#{stylesheets-constants.$theme-professional} {
        color: constants.$color-professional;
      }

      .lvl-#{stylesheets-constants.$theme-basics-store} {
        color: constants.$color-basics-store;
      }

      .lvl-#{stylesheets-constants.$theme-store} {
        color: constants.$color-store;
      }

      h2 {
        font-size: 26px;
        line-height: 31px;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
      }

      .arguments {
        max-width: 550px;

        ul {
          margin-left: 15px;
          padding-right: 15px;

          li {
            font-size: 16px;
            line-height: 31px;
            list-style-image: url('#{stylesheets-constants.$image-source}icon-check.svg');
            list-style-type: none;
            padding: 0 0 0 15px;

            @media(min-width: 425px) {
              margin-bottom: 5px;
            }

            &.strike {
              list-style-image: none;
              text-decoration: line-through;
            }

            &.lvl-#{stylesheets-constants.$theme-premium} {
              list-style-image: url('#{stylesheets-constants.$image-source}icon-check-advanced.svg');
            }

            &.lvl-#{stylesheets-constants.$theme-professional} {
              list-style-image: url('#{stylesheets-constants.$image-source}icon-check-professional.svg');
            }

            &.lvl-#{stylesheets-constants.$theme-basics-store} {
              list-style-image: url('#{stylesheets-constants.$image-source}icon-check-basics-store.svg');
            }

            &.lvl-#{stylesheets-constants.$theme-store} {
              list-style-image: url('#{stylesheets-constants.$image-source}icon-check.svg');
            }

            &.lvl-warning {
              list-style-image: url('#{stylesheets-constants.$image-source}icon-cross-red.svg');
            }

            &.module-store {
              font-weight: bold;
            }
          }
        }
      }

      .details {
        cursor: pointer;
        margin-bottom: 15px;
        margin-top: 30px;
        text-align: center;

        .badge {
          background-color: constants.$main-blue;
          border-radius: 30px;
          font-weight: 600;
          margin-right: 5px;
          margin-top: -3px;
          padding: 2px 6px 2px 9px;
          transition: background-color linear 200ms;

          .fa {
            font-size: 18px;
          }
        }

        .text {
          font-size: 16px;
          line-height: 31px;
          font-weight: 600;
        }

        &:hover {
          .badge {
            background-color: constants.$main-blue-hover;
          }

          .text {
            text-decoration: underline;
          }
        }
      }

      .filler {
        flex: 1;
      }
    }

    .block-2 {
      border-left: solid 1px #eee;
      display: flex;
      flex: 1;
      flex-flow: column;
      justify-content: space-evenly;
      margin-bottom: 20px;

      .physicproducts {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
      }
    }
  }

  .images-option {
    color: constants.$main-blue;
    cursor: pointer;
    font-size: 14px;
    line-height: 31px;

    &:hover {
      color: color.adjust(constants.$main-blue, $lightness: -20%);
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 30px;

    .theme {
      flex-flow: row;
      padding: 0;

      .block-1 {
        align-items: normal;
        display: flex;
        flex-flow: column;
        width: 310px;

        h2 {
          margin: 25px 0;
        }

        .arguments {
          ul {
            margin-left: 30px;
          }
        }

        .details {
          margin-bottom: 15px;
          margin-top: 30px;
        }
      }

      .block-2 {
        border-left: solid 1px #eee;
        display: flex;
        justify-content: space-evenly;
        margin-left: 10px;
        margin-top: 15px;
      }
    }
  }


  @media (min-width: 992px) {
    .theme {
      .block-1 {
        width: auto;
      }
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    width: 380px;

    .theme {
      flex-flow: column;
      height: 765px;
      padding: 30px 0 10px;

      .block-1 {
        flex: 1;
        margin-bottom: 15px;
        max-width: none;

        h2 {
          margin: 0 0 20px;
        }

        .details {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .block-2 {
        border: 0;
        flex: none;
        height: 260px;
        justify-content: normal;
        margin-left: 0;
        padding: 0 5px;
      }
    }

    &.count-4 {
      width: 290px;

      .theme {
        .block-1 {
          .arguments {
            width: 100%;

            ul {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &.count-4 {
    .theme {
      .block-1 {
        .arguments {
          ul {
            margin-left: 0;
            padding-left: 40px;

            li {
              padding: 0 5px;
            }
          }
        }
      }
    }
  }
}
