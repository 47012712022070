@use "sass:color";
@use '../../../../../assets/stylesheets/artp_colors';

$inactive-sb-color: #ccc;
$active-sb-color: color.adjust($inactive-sb-color, $lightness: -12%);

.flex-filler {
  display: inline-block;
  flex: 1 1 auto;
}

.Search-HorizontalBar {
  z-index: 45;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }

  .results-count {
    float: left;
    width: 100%;

    .col-xs-12 {
      color: artp_colors.$gray-dark;
      height: 30px;
      text-align: center;

      .count {
        margin-top: 5px;
      }
    }
  }

  .grey-link {
    color: artp_colors.$gray-dark;
  }

  .search-terms {
    padding: 15px 0;

    &.background {
      background-color: artp_colors.$gray-light;
    }
  }

  .container {
    .filters-container {
      align-items: center;
      display: flex;
      flex: 1;
      flex-direction: row;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;

      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
      
      .filler {
        flex: 1 1 auto;
      }
      
      .display-dropdown {
        flex-direction: column;
      }

      .common-dropdown {
        background: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 5px 10px;
        text-align: left;
        text-decoration: none;
        transition: background ease-out 100ms 0ms;

        &:hover {
          background-color: #eee;
        }

        .title {
          font-size: 14px;

          button {
            color: #000;
            outline: none;
            padding: 0;

            i {
              vertical-align: middle;
            }
          }
        }
      }

      .artp-input-group {
        align-items: center;
        display: flex;
        padding: 7px;

        .artp-input-label {
          align-self: center;
          color: #999;
          font-size: 14px;
          padding: 0 10px 0 0;
          text-align: right;
          width: 150px;
        }

        .search-bar-input {
          display: flex;
          flex-flow: row;

          .cell {
            padding-left: 3px;

            &.flex {
              flex: 1;
            }

            &.offset {
              margin-left: 35%;
            }

            &.first {
              padding-left: 0;
            }
          }
        }

        .max-height-38 {
          height: 38px;
        }
      }
    }
  }
}
