@use '../stylesheets/constants';

.subscriptions-list-theme-subscribe-button {
  display: flex;
  justify-content: center;

  button {
    margin-top: 20px;
    
    @include constants.subscription-button;

    &.disabled {
      cursor: not-allowed;

      &:hover {
        background-color: #fff;
      }
    }
  }
}
