.ModalTour {
  max-height: 90vh;

  .artp-modal-content {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    padding: 20px 10px !important;
    position: relative;

    @media (min-width: 370px) {
      min-width: 350px;
    }

    @media (min-width: 992px) {
      padding: 40px !important;
    }
  }

  .ModalTour-content {
    flex: 1;
  }

  .ModalTour-pagination {
    background-color: #c01b88;
    border-radius: 50%;
    color: #fff;
    font-size: 22px;
    left: 28px;
    padding: 10px;
    position: absolute;
    top: 28px;
  }

  .btn-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .close-definitively,
    .later {
      border: unset;
      background: none;
      color: #b1b1b1;
      cursor: pointer;
      font: inherit;
      outline: inherit;
      padding: 0;
      text-decoration: underline;
    }
  }
}
