.homepage-studies {
  background: #233A84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  gap: 30px;
  margin-top: 100px;
  color: #fff;

  .container {
    display: flex;
    flex-flow: column;

    .l0 {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      align-self: center;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .l1 {
      font-weight: 400;
      font-size: 32px;
      line-height: 39px;
      align-self: center;
      margin-bottom: 10px;
    }

    .l2 {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.02em;
      margin-bottom: 30px;
      align-self: center;

      @media(min-width: 768px) {
        min-width: 580px;
        max-width: 640px;
      }
    }

    .l3 {
      align-self: center;
    }
  }
}
