@use "sass:color";
.subscriptions-list-contact {
  text-align: center;
  margin: 30px 0;

  p {
    font-size: 18px;
  }
  
  button {
    background-color: #CAC6BA80;
    border: solid 1px #CAC6BA80;
    
    &:hover {
      background-color: color.adjust(#CAC6BA80, $lightness: -10%);
    }
  }
}
