.search-dimension {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-areas: 'cell1 cell2 cell3 cell4'
    'cell5 cell5 cell5 cell5';

  .cell-1 {
    grid-area: cell1;
  }

  .cell-2 {
    grid-area: cell2;
  }

  .cell-3 {
    grid-area: cell3;
  }

  .cell-4 {
    grid-area: cell4;
  }

  .cell-5 {
    grid-area: cell5;
    width: 30%;
  }

  // Hacking z-index to prevent it to be over the react date-picker
  .input-group > input {
    z-index: 1;
  }
}
