.account-signin-confirmation-marketplace {
  display: grid;
  margin-top: 30px;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: 1fr;

  @media(min-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }

  .sell, .collect {
    display: flex;
    flex-flow: column;
    height: 315px;
    color: #fff;
    padding: 30px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;

    @media(min-width: 768px) {
      height: 260px;
    }

    h2 {
      margin-bottom: 30px;
      font-size: 22px;
      line-height: 34px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}

