.artists-common-overlapping {
  margin: 30px auto;
  transition: opacity linear 0.25s;

  @media (min-width: 992px) {
    bottom: 0;
    left: 0;
    margin: 0;
    max-width: 300px;
    position: absolute;
    width: var(--block-width);
  }

  &.overlapped {
    opacity: 0;
  }

  .overlapping-content {
    .title {
      background-color: #d9d3c3;
      color: #000;
      display: inline;
      font-size: 19px;
      font-weight: 600;
      line-height: 30px;
      padding: 5px;
    }

    .content {
      margin: 20px 0 30px;

      p {
        font-size: 13px;
        line-height: 20px;

        &:first-of-type {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
