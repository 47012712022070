.myartprice-marketplace-search-bar {

  .marketplace-search-bar {
    flex-flow: column;
    display: flex;

    .filters-container {
      display: flex;
      column-gap: 15px;
      padding: 0 15px;
      align-items: center;
      width: 100%;

      .common-dropdown {
        border: solid 1px #ccc;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 0;
        max-height: 34px;

        .title {
          font-size: 14px;

          button {
            color: #000 !important;
          }
        }
      }

      .searchbar-count {
        width: auto;
        text-align: left;
        font-size: 16px;
        color: #666;
        padding-bottom: 0;
        min-height: auto;
      }
    }
  }
}
