@use '../../../../../assets/stylesheets/artp_colors';

.searchbar-count {
  color: artp_colors.$gray-darker;
  padding-bottom: 20px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  min-height: 50px;
}
