@use '../../../../../../assets/stylesheets/artp_colors';

.marketplace-store-header-store-informations {
  $opacity: 0.6;
  $height-txt: 415px;
  $height-tabs: 33px;

  font-size: 16px;
  position: relative;

  .tabs {
    display: flex;
    flex-flow: row;
    overflow-y: hidden;

    .tab {
      border-bottom: solid 1px artp_colors.$gray;
      color: #555;
      cursor: pointer;
      flex: 1;
      font-size: 14px;
      height: $height-tabs;
      padding: 5px 0;
      position: relative;
      text-align: center;
      text-transform: uppercase;

      &.active {
        background-color: rgba(255, 255, 255, $opacity);
        border: solid 1px artp_colors.$gray;
        border-bottom: 0;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        box-shadow: 2px 2px 5px #bbb;
      }
    }
  }

  .txt {
    background-color: rgba(255, 255, 255, $opacity);
    height: $height-txt;
    overflow-y: auto;
    padding: 15px 15px 5px;

    &.border {
      border: solid 1px #cccc;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top: 0;
      box-shadow: 2px 5px 5px #bbb;
    }

    &.txt-contact {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    @media(max-width: 767px) {
      height: 280px;
    }
  }
}
